@import './../old/variables';

// Colors
$colorBlack:         #000000;
$colorWhite:         #ffffff;
$colorGrey:          #F5F5F5;
$colorGreyDarken1:   #D2D2D2;
$colorGreyDarken2:   #706E6E;
$colorGreen:         #8AD923;
$colorBlue:          #5AB2F8;
$colorBlueDarken1:   #326690;
$colorRed:           #EF582A;
$colorRedDarken1:    #C94820;
$colorYellow:        #FFD429;
$colorYellowDarken1: #AA8F23;

$colorTemp: $colorGrey;
$colorTemp2: $colorGreyDarken1;

// Others
$animationFunction: cubic-bezier(.01, .71, .5, .99);
$animationFunctionEaseOut: cubic-bezier(.01, .71, .5, .99);
$animationDuration: 300ms;