#cookieConfigure{
    .confirmationMessage{
        color: $colorLogo;
        font-style: italic;
        display: none;

        &.show{
            display: block;
        }
    }
}