section.memberGreeting {
    margin-top: 175px;

    @media screen and (min-width:1024px) {
        margin-top: 115px;
    }

    a.readMore {
        margin-left: 25px;

        span,
        svg {
            vertical-align: middle;
        }
    }
}