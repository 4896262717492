section.smallBlock {
    margin-top: 70px;

    @media screen and (min-width:768px) {
        margin-top: 110px;
    }

    img {
        border-radius: 4px;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 24px;

        @media screen and (min-width: 768px) {
            font-size: 40px;
        }
    }

    p {
        font-size: 18px;
        margin-bottom: 35px;
    }

    a.button {
        padding: 10px 15px;

        @media screen and (min-width:768) {
            padding: 20px 35px;

        }

        span {
            font-size: 18px;
        }
    }
}