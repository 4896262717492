@use "sass:math";

@mixin active {
    &:hover {
        @content;
    }
}

@mixin focus {
    &:focus {
        @content;
    }
}

@mixin fullActive {

    &:hover,
    &:focus {
        @content;
    }
}

@mixin transition($property: all,
    $duration: $animationDuration,
    $timingFunction: $animationFunction) {
    @if (type-of($property)==list) {
        transition: all $duration $timingFunction;
        transition-property: append($property, (), 'comma');
    }

    @else {
        transition: $property $duration $timingFunction;
    }
}

@mixin animation($name,
    $duration: $animationDuration,
    $timingFunction: $animationFunction) {
    animation: {
        direction: normal;
        duration: $duration;
        fill-mode: forwards;
        name: $name;
        timing-function: $timingFunction;
    }
}

@mixin hardStopGradient($direction: to bottom, $colorList: ()) {
    $stopsList: ();

    @for $i from 1 through length($colorList) {
        @if ($i==1) {
            // First loop, include the 0 start with the first color
            $stopsList: append($stopsList, nth($colorList, 1) 0%, 'comma');
        }

        @if ($i !=length($colorList)) {
            // Append the first color, plus the next color right after
            // Only do this if this isn't the last loop
            $stopsList: append($stopsList, nth($colorList, $i) (math.div(100%, length($colorList)) * $i));
            $stopsList: append($stopsList, nth($colorList, ($i + 1)) (math.div(100%, length($colorList)) * $i));
        }

        @else {
            // Last loop, include the 100 end with the last color
            $stopsList: append($stopsList, nth($colorList, length($colorList)) 100%, 'comma');
        }
    }

    background: linear-gradient($direction, $stopsList);
}

@function strip-unit($value) {
    @return math.div($value, $value * 0 + 1);
}

@mixin fluid($property, $min-size, $max-size, $min-vw: 375px, $max-vw: 1440px) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-size);
    $u4: unit($max-size);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {
            #{$property}: $min-size;

            @media screen and (min-width: $min-vw) {
                #{$property}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                #{$property}: $max-size;
            }
        }
    }
}

@mixin scrollbars($size, $foreground, $background: mix($foreground, white, 50%)) {

    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground;
    }

    &::-webkit-scrollbar-track {
        background: $background;
    }

    // For Internet Explorer & Firefox
    & {
        scrollbar-face-color: $foreground;
        scrollbar-track-color: $background;
        scrollbar-color: $foreground $background;
        
        @if($size < 10px) {
            scrollbar-width: thin;
        }
    }
}