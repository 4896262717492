.volunteerVacanciesOverview {
    [data-filters] {
        label {
            font-size: 16px;
            line-height: 21px;
            display: block;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        input, select {
            width: 100%;
            height: 48px;

            font-size: 16px;
            border: 1px solid $colorInputBorder;
            border-radius: 3px;

            &::placeholder {
                color: $colorMetaLinks;
            }
        }

        .field, form {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            svg.icon_searchloop {
                position: absolute;
                width: 24px;
                height: 24px;
                left: 20px;

                fill: $colorInputBorder;
            }

            .clearButton {
                position: absolute;
                right: 10px;
                background: transparent;
                border: none;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                left: auto;
                width: 40px;
                height: 40px;
                padding: 0;

                display: none;

                svg {
                    right: 20px;
                    width: 15px;
                    fill: $colorInputBorder;
                }

                &.show {
                    display: inline-flex;
                    z-index: 2;
                }
            }

            input {
                padding-left: 60px;

                &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    display: none;
                }
            }
        }

        select {
            padding: 0 20px;
            color: $colorMetaLinks;
        }
    }

    .mapContainer {
        margin-top: 40px;
        height: 600px;
        position: relative;

        .map {
            position: absolute;
            height: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background: $colorFormGrey;
        }

        .mapPopup {
            padding: 5px;
            max-width: 300px;
            min-width: 250px;

            .content {
                display: flex;
                flex-direction: column;
                font-family: $mainFont;
            }

            .city {
                font-size: 12px;
                margin-bottom: 10px;
                color: $colorText;
            }

            .title {
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
                color: $colorLink;
            }

            .duration {
                font-size: 11px;
                color: $colorText;
                margin-top: 10px;
            }

            a {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $colorActionPurple;
                color: $colorWhite;
                padding: 10px;
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                margin-top: 10px;
                border-radius: 4px;
                text-decoration: none;
            }
        }
    }

    .listContainer {
        display: flex;
        flex-wrap: wrap;

        margin: 20px -10px -20px;
        
        .listItemWrapper {
            flex: 1 1 100%;
            max-width: 100%;

            &.paged {
                display: none;
            }

            @media (min-width: 700px) {
                flex: 1 1 50%;
                max-width: 50%;
            }
        }

        .listItem {
            margin: 20px 10px;

            display: flex;
        }

        .imageContainer {
            flex: 0 0 40%;
        }

        .image {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            background: $colorGrey;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .contentContainer {

        }

        .content {
            display: flex;
            flex-direction: column;
            color: $colorText;
            height: 100%;
            margin-left: 20px;
        }

        .city {
            display: block;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .title {
            font-size: 22px;
            line-height: 28px;
        }

        .duration {
            display: block;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        a.more {
            display: inline-flex;
            align-items: center;
            margin-top: auto;
            margin-bottom: 20px;

            color: $colorLink;
            text-decoration: none;

            svg {
                fill: $colorLink;
                margin-left: 20px;

                @include transition(transform);
            }

            @include fullActive {
                svg {
                    transform: translateX(5px);
                }
            }
        }
    }
}