.ui-datepicker {
    border: 1px solid $colorHeaderLine;
    background-color: white;
    
    div.ui-datepicker-header{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 0;
        margin: 0 20px;

        .ui-datepicker-prev, .ui-datepicker-next{
            width: 20px;
            height: 20px;
            cursor: pointer;

            .ui-icon{
                margin: 0;
            }
        }

        .ui-datepicker-title{
            order: 2;
        }

        .ui-datepicker-next{
            order: 3;
        }
    }
}

