.pagerComponent {
    .actionsContainer {
        align-items: center;
        justify-content: center;

        margin: -5px -10px;

        > * {
            margin: 5px 10px;
        }
    }

    .prev, .next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $colorBlue;
        @include transition(border-color);
        background-color: transparent;
        cursor: pointer;

        @include fullActive {
            border-color: $colorBlueDarken1;
        }

        &[disabled] {
            opacity: .5;
            user-select: none;
            pointer-events: none;
        }
    }
}