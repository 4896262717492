@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes upDown {
	0% {
		transform: translateY(-15px);
	}

	50% {
		transform: translateY(15px);
	}

	100% {
		transform: translateY(-15px);
	}
}

@keyframes leftRight {
	0% {
		transform: translateX(-15px);
	}

	50% {
		transform: translateX(15px);
	}

	100% {
		transform: translateX(-15px);
	}
}

@keyframes appearFromBottom {
	0% {
		opacity: 0;
		transform: translateY(20%);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes appearFromTop {
	0% {
		opacity: 0;
		transform: translateY(-20%);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}