// Import old CSS first
@import './old/base';
@import './old/lity.min';
/**
* Do not touch the above lines or the contents of the CSS. 
* These are antique artifacts we don't wanna work in anymore
*/

// @import 'reset';
// @import 'vendors';
@import 'settings';
@import 'components';

:root {
    --fullHeight: 100vh;
}


.extraMarginBottom {
    margin-bottom: 70px;

    @media screen and (min-width:768px) {
        margin-bottom: 110px;
    }
}

section.content ul.labels {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0 -5px 40px;
    flex-wrap: wrap;

    li {
        background-color: $colorGrey;
        padding: 10px;
        margin: 5px;
        font-size: 14px;
    }
}