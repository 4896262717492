﻿/* Responsive Breakpoints */
/* Border-Radius */
/* Colors */
/* Zelf doen Rainbow Colors */
/* Cookie colors */
/* Fonts */
/* Z-Indexes */
@font-face {
  font-family: "Avenir";
  font-weight: 400;
  src: url("/fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
  src: url("/fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"), url("/fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"), url("/fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"), url("/fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype"), url("/fonts/3604edbd-784e-4ca7-b978-18836469c62d.svg#3604edbd-784e-4ca7-b978-18836469c62d") format("svg"); }

@font-face {
  font-family: "Avenir";
  font-weight: 700;
  src: url("/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
  src: url("/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"), url("/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"), url("/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"), url("/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"), url("/fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg"); }

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: url("/fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
  src: url("/fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"), url("/fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"), url("/fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"), url("/fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype"), url("/fonts/93603a74-2be0-436c-83e1-68f9ef616eaf.svg#93603a74-2be0-436c-83e1-68f9ef616eaf") format("svg"); }

/* Transition Mixin */
/* Border-Radius Mixin */
/* Box-Shadow Mixin */
/* Text-Shadow Mixin */
/* Opacity Mixin */
/* Gradient Top Bottom Mixin */
/* Gradient Left Right Mixin */
/* Transform Mixin */
/* Rotate Mixin */
/* Scale Mixin */
/* Translate Mixin */
/* TranslateY Mixin */
/* TranslateX Mixin */
/* Transform Origin Mixin */
/* Animate Mixin */
/* Placeholder Mixin (Placeholder styling doesnt work in every browser) */
/* Flexbox Mixin */
/* Flexbox Mixin */
/* Appearance Mixin */
input::-moz-focus-inner {
  border: 0; }

object, embed, a, a:hover, a:active, a:focus {
  outline: 0; }

/* Reset styles */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-weight: bold;
  font-style: italic; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc; }

input,
select {
  vertical-align: middle; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

button {
  outline: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.browserUpgrade {
  background: #ff6a00;
  font-size: 1.2em;
  border-bottom: 1px solid #000000;
  color: #fff;
  position: fixed;
  z-index: 99999999;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 50px 20px; }
  .browserUpgrade p {
    max-width: 800px;
    margin: 0 auto; }
  .browserUpgrade .upgradeButton {
    color: #ff6a00;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    margin-top: 20px; }
    .browserUpgrade .upgradeButton:hover {
      text-decoration: underline; }
  .browserUpgrade a:hover {
    text-decoration: none; }

img {
  max-width: 100%;
  vertical-align: top; }

.clear:before, div.metaMenu div.actions .cartContent .cartContentList > li:before, div.subCategory .subCategory-item-order:before, .pageSectionCategory-items:before, .pageSectionCategory-items .inner:before, .pageSectionCategory-bottom:before, .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow:before, .cartList .cartListHeader:before, .cartList .cartListHeaderInner:before, .cartList .cartListItem:before, .cartList .cartListItem .cartListItemInner:before, .cartList .cartListItemProductInner:before, div.history .historyItem .historyItemContent ul li:before, .clear:after, div.metaMenu div.actions .cartContent .cartContentList > li:after, div.subCategory .subCategory-item-order:after, .pageSectionCategory-items:after, .pageSectionCategory-items .inner:after, .pageSectionCategory-bottom:after, .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow:after, .cartList .cartListHeader:after, .cartList .cartListHeaderInner:after, .cartList .cartListItem:after, .cartList .cartListItem .cartListItemInner:after, .cartList .cartListItemProductInner:after, div.history .historyItem .historyItemContent ul li:after {
  content: '';
  display: table; }

.clear:after, div.metaMenu div.actions .cartContent .cartContentList > li:after, div.subCategory .subCategory-item-order:after, .pageSectionCategory-items:after, .pageSectionCategory-items .inner:after, .pageSectionCategory-bottom:after, .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow:after, .cartList .cartListHeader:after, .cartList .cartListHeaderInner:after, .cartList .cartListItem:after, .cartList .cartListItem .cartListItemInner:after, .cartList .cartListItemProductInner:after, div.history .historyItem .historyItemContent ul li:after {
  clear: both; }

input:-webkit-autofill {
  background: transparent; }

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  min-height: 380px; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }
  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer; }
  .flickity-page-dots .dot.is-selected {
    opacity: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

/* Grid system 
========================== */
.container {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1080px; }
  @media screen and (min-width: 568px) {
    .container {
      padding-left: 10px;
      padding-right: 10px; } }
  .container.fullWidth {
    max-width: 100%; }
  .container.noGutterInside .row {
    margin-left: 0;
    margin-right: 0; }
  .container.noGutterInside .column {
    padding-left: 0;
    padding-right: 0; }
  .container.noGutterOutside {
    padding-left: 0;
    padding-right: 0; }
  .container.noGutterAll {
    padding-left: 0;
    padding-right: 0; }
    .container.noGutterAll .row {
      margin-left: 0;
      margin-right: 0; }
    .container.noGutterAll .column {
      padding-left: 0;
      padding-right: 0; }

.row {
  margin-left: -20px;
  margin-right: -20px; }
  .row::after {
    content: '';
    clear: both;
    display: table; }
  @media screen and (min-width: 568px) {
    .row {
      margin-left: -10px;
      margin-right: -10px; } }
  .row.centered {
    text-align: center; }
    .row.centered > .column {
      text-align: left; }
  .row.right {
    text-align: right; }
    .row.right > .column {
      text-align: left; }
  .row.noGutterInside .column {
    padding-left: 0;
    padding-right: 0; }

.column {
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  margin-right: -4px;
  vertical-align: top; }
  @media screen and (min-width: 568px) {
    .column {
      padding-left: 10px;
      padding-right: 10px; } }
  .column.right {
    text-align: right; }
  .column.mo-top {
    float: none; }
    @media screen and (min-width: 568px) {
      .column.mo-top {
        float: right; } }
  .column.mo-bottom {
    float: none; }
    @media screen and (min-width: 568px) {
      .column.mo-bottom {
        float: left; } }
  .column.noGutter {
    padding: 0; }

.col-md-1, .col-ta-1, .col-de-1, .col-la-1 {
  width: 5.55556%; }

.col-md-2, .col-ta-2, .col-de-2, .col-la-2 {
  width: 11.11111%; }

.col-md-3, .col-ta-3, .col-de-3, .col-la-3 {
  width: 16.66667%; }

.col-md-4, .col-ta-4, .col-de-4, .col-la-4 {
  width: 22.22222%; }

.col-md-5, .col-ta-5, .col-de-5, .col-la-5 {
  width: 27.77778%; }

.col-md-6, .col-ta-6, .col-de-6, .col-la-6 {
  width: 33.33333%; }

.col-md-7, .col-ta-7, .col-de-7, .col-la-7 {
  width: 38.88889%; }

.col-md-8, .col-ta-8, .col-de-8, .col-la-8 {
  width: 44.44444%; }

.col-md-9, .col-ta-9, .col-de-9, .col-la-9 {
  width: 50%; }

.col-md-10, .col-ta-10, .col-de-10, .col-la-10 {
  width: 55.55556%; }

.col-md-11, .col-ta-11, .col-de-11, .col-la-11 {
  width: 61.11111%; }

.col-md-12, .col-ta-12, .col-de-12, .col-la-12 {
  width: 66.66667%; }

.col-md-13, .col-ta-13, .col-de-13, .col-la-13 {
  width: 72.22222%; }

.col-md-14, .col-ta-14, .col-de-14, .col-la-14 {
  width: 77.77778%; }

.col-md-15, .col-ta-15, .col-de-15, .col-la-15 {
  width: 83.33333%; }

.col-md-16, .col-ta-16, .col-de-16, .col-la-16 {
  width: 88.88889%; }

.col-md-17, .col-ta-17, .col-de-17, .col-la-17 {
  width: 94.44444%; }

.col-md-18, .col-ta-18, .col-de-18, .col-la-18 {
  width: 100%; }

.col-md-4th, .col-ta-4th, .col-de-4th, .col-la-4th {
  width: 25%; }

/* Umbraco grid-specific class (col-md-*) */
.col-md-hide {
  display: none; }

@media screen and (max-width: 767px) {
  .col-md-1 {
    width: 100%;
    display: inline-block; }
  .col-md-2 {
    width: 100%;
    display: inline-block; }
  .col-md-3 {
    width: 100%;
    display: inline-block; }
  .col-md-4 {
    width: 100%;
    display: inline-block; }
  .col-md-5 {
    width: 100%;
    display: inline-block; }
  .col-md-6 {
    width: 100%;
    display: inline-block; }
  .col-md-7 {
    width: 100%;
    display: inline-block; }
  .col-md-8 {
    width: 100%;
    display: inline-block; }
  .col-md-9 {
    width: 100%;
    display: inline-block; }
  .col-md-10 {
    width: 100%;
    display: inline-block; }
  .col-md-11 {
    width: 100%;
    display: inline-block; }
  .col-md-12 {
    width: 100%;
    display: inline-block; }
  .col-md-13 {
    width: 100%;
    display: inline-block; }
  .col-md-14 {
    width: 100%;
    display: inline-block; }
  .col-md-15 {
    width: 100%;
    display: inline-block; }
  .col-md-16 {
    width: 100%;
    display: inline-block; }
  .col-md-17 {
    width: 100%;
    display: inline-block; }
  .col-md-18 {
    width: 100%;
    display: inline-block; } }

.col-md-12 {
  width: 100%; }

.col-md-6 {
  width: 50%; }

.col-md-4 {
  width: 33.333%; }

.grid-section .column {
  /*padding: $gutterWidth;*/ }

/* End Umbraco grid-specific class */
.col-mo-1 {
  width: 5.55556%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-2 {
  width: 11.11111%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-3 {
  width: 16.66667%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-4 {
  width: 22.22222%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-5 {
  width: 27.77778%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-6 {
  width: 33.33333%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-7 {
  width: 38.88889%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-8 {
  width: 44.44444%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-9 {
  width: 50%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-10 {
  width: 55.55556%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-11 {
  width: 61.11111%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-12 {
  width: 66.66667%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-13 {
  width: 72.22222%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-14 {
  width: 77.77778%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-15 {
  width: 83.33333%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-16 {
  width: 88.88889%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-17 {
  width: 94.44444%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-18 {
  width: 100%;
  display: inline-block; }

.col-mo-hide {
  display: none; }

.col-mo-4th {
  width: 25%; }

@media screen and (min-width: 568px) {
  .col-ta-1 {
    width: 5.55556%;
    display: inline-block; }
  .col-ta-2 {
    width: 11.11111%;
    display: inline-block; }
  .col-ta-3 {
    width: 16.66667%;
    display: inline-block; }
  .col-ta-4 {
    width: 22.22222%;
    display: inline-block; }
  .col-ta-5 {
    width: 27.77778%;
    display: inline-block; }
  .col-ta-6 {
    width: 33.33333%;
    display: inline-block; }
  .col-ta-7 {
    width: 38.88889%;
    display: inline-block; }
  .col-ta-8 {
    width: 44.44444%;
    display: inline-block; }
  .col-ta-9 {
    width: 50%;
    display: inline-block; }
  .col-ta-10 {
    width: 55.55556%;
    display: inline-block; }
  .col-ta-11 {
    width: 61.11111%;
    display: inline-block; }
  .col-ta-12 {
    width: 66.66667%;
    display: inline-block; }
  .col-ta-13 {
    width: 72.22222%;
    display: inline-block; }
  .col-ta-14 {
    width: 77.77778%;
    display: inline-block; }
  .col-ta-15 {
    width: 83.33333%;
    display: inline-block; }
  .col-ta-16 {
    width: 88.88889%;
    display: inline-block; }
  .col-ta-17 {
    width: 94.44444%;
    display: inline-block; }
  .col-ta-18 {
    width: 100%;
    display: inline-block; }
  .col-ta-hide {
    display: none; }
  .col-ta-4th {
    width: 25%; } }

@media screen and (min-width: 768px) {
  .col-de-1 {
    width: 5.55556%;
    display: inline-block; }
  .col-de-2 {
    width: 11.11111%;
    display: inline-block; }
  .col-de-3 {
    width: 16.66667%;
    display: inline-block; }
  .col-de-4 {
    width: 22.22222%;
    display: inline-block; }
  .col-de-5 {
    width: 27.77778%;
    display: inline-block; }
  .col-de-6 {
    width: 33.33333%;
    display: inline-block; }
  .col-de-7 {
    width: 38.88889%;
    display: inline-block; }
  .col-de-8 {
    width: 44.44444%;
    display: inline-block; }
  .col-de-9 {
    width: 50%;
    display: inline-block; }
  .col-de-10 {
    width: 55.55556%;
    display: inline-block; }
  .col-de-11 {
    width: 61.11111%;
    display: inline-block; }
  .col-de-12 {
    width: 66.66667%;
    display: inline-block; }
  .col-de-13 {
    width: 72.22222%;
    display: inline-block; }
  .col-de-14 {
    width: 77.77778%;
    display: inline-block; }
  .col-de-15 {
    width: 83.33333%;
    display: inline-block; }
  .col-de-16 {
    width: 88.88889%;
    display: inline-block; }
  .col-de-17 {
    width: 94.44444%;
    display: inline-block; }
  .col-de-18 {
    width: 100%;
    display: inline-block; }
  .col-de-hide {
    display: none; }
  .col-de-4th {
    width: 25%; } }

@media screen and (min-width: 1024px) {
  .col-la-1 {
    width: 5.55556%;
    display: inline-block; }
  .col-la-2 {
    width: 11.11111%;
    display: inline-block; }
  .col-la-3 {
    width: 16.66667%;
    display: inline-block; }
  .col-la-4 {
    width: 22.22222%;
    display: inline-block; }
  .col-la-5 {
    width: 27.77778%;
    display: inline-block; }
  .col-la-6 {
    width: 33.33333%;
    display: inline-block; }
  .col-la-7 {
    width: 38.88889%;
    display: inline-block; }
  .col-la-8 {
    width: 44.44444%;
    display: inline-block; }
  .col-la-9 {
    width: 50%;
    display: inline-block; }
  .col-la-10 {
    width: 55.55556%;
    display: inline-block; }
  .col-la-11 {
    width: 61.11111%;
    display: inline-block; }
  .col-la-12 {
    width: 66.66667%;
    display: inline-block; }
  .col-la-13 {
    width: 72.22222%;
    display: inline-block; }
  .col-la-14 {
    width: 77.77778%;
    display: inline-block; }
  .col-la-15 {
    width: 83.33333%;
    display: inline-block; }
  .col-la-16 {
    width: 88.88889%;
    display: inline-block; }
  .col-la-17 {
    width: 94.44444%;
    display: inline-block; }
  .col-la-18 {
    width: 100%;
    display: inline-block; }
  .col-la-hide {
    display: none; }
  .col-la-4th {
    width: 25%; } }

/* Offset left */
.col-mo-offset-left-0 {
  margin-left: 0%; }

.col-mo-offset-left-1 {
  margin-left: 5.55556%; }

.col-mo-offset-left-2 {
  margin-left: 11.11111%; }

.col-mo-offset-left-3 {
  margin-left: 16.66667%; }

.col-mo-offset-left-4 {
  margin-left: 22.22222%; }

.col-mo-offset-left-5 {
  margin-left: 27.77778%; }

.col-mo-offset-left-6 {
  margin-left: 33.33333%; }

.col-mo-offset-left-7 {
  margin-left: 38.88889%; }

.col-mo-offset-left-8 {
  margin-left: 44.44444%; }

.col-mo-offset-left-9 {
  margin-left: 50%; }

.col-mo-offset-left-10 {
  margin-left: 55.55556%; }

.col-mo-offset-left-11 {
  margin-left: 61.11111%; }

.col-mo-offset-left-12 {
  margin-left: 66.66667%; }

.col-mo-offset-left-13 {
  margin-left: 72.22222%; }

.col-mo-offset-left-14 {
  margin-left: 77.77778%; }

.col-mo-offset-left-15 {
  margin-left: 83.33333%; }

.col-mo-offset-left-16 {
  margin-left: 88.88889%; }

.col-mo-offset-left-17 {
  margin-left: 94.44444%; }

.col-mo-offset-left-18 {
  margin-left: 100%; }

@media screen and (min-width: 568px) {
  .col-ta-offset-left-0 {
    margin-left: 0%; }
  .col-ta-offset-left-1 {
    margin-left: 5.55556%; }
  .col-ta-offset-left-2 {
    margin-left: 11.11111%; }
  .col-ta-offset-left-3 {
    margin-left: 16.66667%; }
  .col-ta-offset-left-4 {
    margin-left: 22.22222%; }
  .col-ta-offset-left-5 {
    margin-left: 27.77778%; }
  .col-ta-offset-left-6 {
    margin-left: 33.33333%; }
  .col-ta-offset-left-7 {
    margin-left: 38.88889%; }
  .col-ta-offset-left-8 {
    margin-left: 44.44444%; }
  .col-ta-offset-left-9 {
    margin-left: 50%; }
  .col-ta-offset-left-10 {
    margin-left: 55.55556%; }
  .col-ta-offset-left-11 {
    margin-left: 61.11111%; }
  .col-ta-offset-left-12 {
    margin-left: 66.66667%; }
  .col-ta-offset-left-13 {
    margin-left: 72.22222%; }
  .col-ta-offset-left-14 {
    margin-left: 77.77778%; }
  .col-ta-offset-left-15 {
    margin-left: 83.33333%; }
  .col-ta-offset-left-16 {
    margin-left: 88.88889%; }
  .col-ta-offset-left-17 {
    margin-left: 94.44444%; }
  .col-ta-offset-left-18 {
    margin-left: 100%; } }

@media screen and (min-width: 768px) {
  .col-de-offset-left-0 {
    margin-left: 0%; }
  .col-de-offset-left-1 {
    margin-left: 5.55556%; }
  .col-de-offset-left-2 {
    margin-left: 11.11111%; }
  .col-de-offset-left-3 {
    margin-left: 16.66667%; }
  .col-de-offset-left-4 {
    margin-left: 22.22222%; }
  .col-de-offset-left-5 {
    margin-left: 27.77778%; }
  .col-de-offset-left-6 {
    margin-left: 33.33333%; }
  .col-de-offset-left-7 {
    margin-left: 38.88889%; }
  .col-de-offset-left-8 {
    margin-left: 44.44444%; }
  .col-de-offset-left-9 {
    margin-left: 50%; }
  .col-de-offset-left-10 {
    margin-left: 55.55556%; }
  .col-de-offset-left-11 {
    margin-left: 61.11111%; }
  .col-de-offset-left-12 {
    margin-left: 66.66667%; }
  .col-de-offset-left-13 {
    margin-left: 72.22222%; }
  .col-de-offset-left-14 {
    margin-left: 77.77778%; }
  .col-de-offset-left-15 {
    margin-left: 83.33333%; }
  .col-de-offset-left-16 {
    margin-left: 88.88889%; }
  .col-de-offset-left-17 {
    margin-left: 94.44444%; }
  .col-de-offset-left-18 {
    margin-left: 100%; } }

@media screen and (min-width: 1024px) {
  .col-la-offset-left-0 {
    margin-left: 0%; }
  .col-la-offset-left-1 {
    margin-left: 5.55556%; }
  .col-la-offset-left-2 {
    margin-left: 11.11111%; }
  .col-la-offset-left-3 {
    margin-left: 16.66667%; }
  .col-la-offset-left-4 {
    margin-left: 22.22222%; }
  .col-la-offset-left-5 {
    margin-left: 27.77778%; }
  .col-la-offset-left-6 {
    margin-left: 33.33333%; }
  .col-la-offset-left-7 {
    margin-left: 38.88889%; }
  .col-la-offset-left-8 {
    margin-left: 44.44444%; }
  .col-la-offset-left-9 {
    margin-left: 50%; }
  .col-la-offset-left-10 {
    margin-left: 55.55556%; }
  .col-la-offset-left-11 {
    margin-left: 61.11111%; }
  .col-la-offset-left-12 {
    margin-left: 66.66667%; }
  .col-la-offset-left-13 {
    margin-left: 72.22222%; }
  .col-la-offset-left-14 {
    margin-left: 77.77778%; }
  .col-la-offset-left-15 {
    margin-left: 83.33333%; }
  .col-la-offset-left-16 {
    margin-left: 88.88889%; }
  .col-la-offset-left-17 {
    margin-left: 94.44444%; }
  .col-la-offset-left-18 {
    margin-left: 100%; } }

/* Offset right */
.col-mo-offset-right-1 {
  margin-right: 5.55556%; }

.col-mo-offset-right-2 {
  margin-right: 11.11111%; }

.col-mo-offset-right-3 {
  margin-right: 16.66667%; }

.col-mo-offset-right-4 {
  margin-right: 22.22222%; }

.col-mo-offset-right-5 {
  margin-right: 27.77778%; }

.col-mo-offset-right-6 {
  margin-right: 33.33333%; }

.col-mo-offset-right-7 {
  margin-right: 38.88889%; }

.col-mo-offset-right-8 {
  margin-right: 44.44444%; }

.col-mo-offset-right-9 {
  margin-right: 50%; }

.col-mo-offset-right-10 {
  margin-right: 55.55556%; }

.col-mo-offset-right-11 {
  margin-right: 61.11111%; }

.col-mo-offset-right-12 {
  margin-right: 66.66667%; }

.col-mo-offset-right-13 {
  margin-right: 72.22222%; }

.col-mo-offset-right-14 {
  margin-right: 77.77778%; }

.col-mo-offset-right-15 {
  margin-right: 83.33333%; }

.col-mo-offset-right-16 {
  margin-right: 88.88889%; }

.col-mo-offset-right-17 {
  margin-right: 94.44444%; }

.col-mo-offset-right-18 {
  margin-right: 100%; }

@media screen and (min-width: 568px) {
  .col-ta-offset-right-1 {
    margin-right: 5.55556%; }
  .col-ta-offset-right-2 {
    margin-right: 11.11111%; }
  .col-ta-offset-right-3 {
    margin-right: 16.66667%; }
  .col-ta-offset-right-4 {
    margin-right: 22.22222%; }
  .col-ta-offset-right-5 {
    margin-right: 27.77778%; }
  .col-ta-offset-right-6 {
    margin-right: 33.33333%; }
  .col-ta-offset-right-7 {
    margin-right: 38.88889%; }
  .col-ta-offset-right-8 {
    margin-right: 44.44444%; }
  .col-ta-offset-right-9 {
    margin-right: 50%; }
  .col-ta-offset-right-10 {
    margin-right: 55.55556%; }
  .col-ta-offset-right-11 {
    margin-right: 61.11111%; }
  .col-ta-offset-right-12 {
    margin-right: 66.66667%; }
  .col-ta-offset-right-13 {
    margin-right: 72.22222%; }
  .col-ta-offset-right-14 {
    margin-right: 77.77778%; }
  .col-ta-offset-right-15 {
    margin-right: 83.33333%; }
  .col-ta-offset-right-16 {
    margin-right: 88.88889%; }
  .col-ta-offset-right-17 {
    margin-right: 94.44444%; }
  .col-ta-offset-right-18 {
    margin-right: 100%; } }

@media screen and (min-width: 768px) {
  .col-de-offset-right-1 {
    margin-right: 5.55556%; }
  .col-de-offset-right-2 {
    margin-right: 11.11111%; }
  .col-de-offset-right-3 {
    margin-right: 16.66667%; }
  .col-de-offset-right-4 {
    margin-right: 22.22222%; }
  .col-de-offset-right-5 {
    margin-right: 27.77778%; }
  .col-de-offset-right-6 {
    margin-right: 33.33333%; }
  .col-de-offset-right-7 {
    margin-right: 38.88889%; }
  .col-de-offset-right-8 {
    margin-right: 44.44444%; }
  .col-de-offset-right-9 {
    margin-right: 50%; }
  .col-de-offset-right-10 {
    margin-right: 55.55556%; }
  .col-de-offset-right-11 {
    margin-right: 61.11111%; }
  .col-de-offset-right-12 {
    margin-right: 66.66667%; }
  .col-de-offset-right-13 {
    margin-right: 72.22222%; }
  .col-de-offset-right-14 {
    margin-right: 77.77778%; }
  .col-de-offset-right-15 {
    margin-right: 83.33333%; }
  .col-de-offset-right-16 {
    margin-right: 88.88889%; }
  .col-de-offset-right-17 {
    margin-right: 94.44444%; }
  .col-de-offset-right-18 {
    margin-right: 100%; } }

@media screen and (min-width: 1024px) {
  .col-la-offset-right-1 {
    margin-right: 5.55556%; }
  .col-la-offset-right-2 {
    margin-right: 11.11111%; }
  .col-la-offset-right-3 {
    margin-right: 16.66667%; }
  .col-la-offset-right-4 {
    margin-right: 22.22222%; }
  .col-la-offset-right-5 {
    margin-right: 27.77778%; }
  .col-la-offset-right-6 {
    margin-right: 33.33333%; }
  .col-la-offset-right-7 {
    margin-right: 38.88889%; }
  .col-la-offset-right-8 {
    margin-right: 44.44444%; }
  .col-la-offset-right-9 {
    margin-right: 50%; }
  .col-la-offset-right-10 {
    margin-right: 55.55556%; }
  .col-la-offset-right-11 {
    margin-right: 61.11111%; }
  .col-la-offset-right-12 {
    margin-right: 66.66667%; }
  .col-la-offset-right-13 {
    margin-right: 72.22222%; }
  .col-la-offset-right-14 {
    margin-right: 77.77778%; }
  .col-la-offset-right-15 {
    margin-right: 83.33333%; }
  .col-la-offset-right-16 {
    margin-right: 88.88889%; }
  .col-la-offset-right-17 {
    margin-right: 94.44444%; }
  .col-la-offset-right-18 {
    margin-right: 100%; } }

@-webkit-keyframes scaleUp {
  to {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    filter: alpha(opacity=0); } }

@-moz-keyframes scaleUp {
  to {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    filter: alpha(opacity=0); } }

@-ms-keyframes scaleUp {
  to {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    filter: alpha(opacity=0); } }

@-o-keyframes scaleUp {
  to {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    filter: alpha(opacity=0); } }

@keyframes scaleUp {
  to {
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
    filter: alpha(opacity=0); } }

@-webkit-keyframes scaleDownUp {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes scaleDownUp {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-ms-keyframes scaleDownUp {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes scaleDownUp {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes scaleDownUp {
  from {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  to {
    opacity: 1;
    filter: alpha(opacity=100);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); } }

#cookieMessage {
  background: rgba(74, 74, 74, 0.95);
  bottom: 0;
  color: #ffffff;
  left: 0;
  padding: 20px 0 21px;
  position: fixed;
  display: none;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  width: 100%;
  z-index: 9999; }

#cookieMessage.open {
  display: block;
  -moz-animation: slideUp 0.8s ease-in-out 1s forwards;
  -o-animation: slideUp 0.8s ease-in-out 1s forwards;
  -webkit-animation: slideUp 0.8s ease-in-out 1s forwards;
  animation: slideUp 0.8s ease-in-out 1s forwards; }

.skill-bar .w70 {
  width: 70%; }

@-moz-keyframes slideUp {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes slideUp {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideUp {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@media only screen and (max-width: 676px) {
  #cookieMessage {
    padding: 20px 0 10px; } }

html body #cookieMessage div.container {
  background-color: transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 940px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  position: relative;
  width: 100%; }

#cookieMessage span.title {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 8px; }

#cookieMessage p {
  color: #ffffff;
  font-size: 14px !important;
  line-height: 18px;
  margin-bottom: 0; }

#cookieMessage p a {
  color: #ffffff;
  text-decoration: underline; }

#cookieMessage p.mobile {
  font-size: 14px !important;
  display: none; }

#cookieMessage p a:hover {
  text-decoration: none; }

#cookieMessage button {
  display: inline-block;
  width: 100%; }

#cookieMessage .text {
  float: left;
  width: 76.7%; }

#cookieMessage .buttons {
  float: left;
  margin-left: 2%;
  text-align: right;
  width: 21.3%; }

@media only screen and (max-width: 676px) {
  #cookieMessage .text {
    float: none;
    margin-bottom: 5px;
    text-align: center;
    width: 100%; }
  #cookieMessage .buttons {
    float: none;
    margin-left: 0;
    text-align: center;
    width: 100%; }
  #cookieMessage span.title {
    display: none; }
  #cookieMessage p {
    display: none; }
  #cookieMessage p.mobile {
    display: block;
    font-size: 14px !important; } }

#cookieMessage button,
.videoCookieMessage button {
  /*background: #3388C2;*/
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  margin: 5px 0;
  max-width: 161px;
  overflow: visible;
  padding: 5px 0 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 100%; }

@media only screen and (max-width: 676px) {
  #cookieMessage button {
    margin: 10px; } }

#cookieMessage button::-moz-focus-inner,
.videoCookieMessage button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*#cookieMessage button:hover,
.videoCookieMessage button:hover {
    background: #5CA0CE;
}*/
.videoCookieMessage {
  background: #F5F5F5;
  height: 100%;
  text-align: center; }

#video1 .videoCookieMessage {
  max-width: 900px; }

#video1 .videoCookieMessage .contentHolder .inner {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 175px 50px; }

@media only screen and (max-height: 685px) {
  #video1 .videoCookieMessage .contentHolder .inner {
    padding: 50px; } }

#video1 .videoCookieMessage .contentHolder .inner svg {
  height: 120px;
  margin-bottom: 23px;
  width: 120px; }

.videoCookieMessage {
  border: 1px solid #CECECE;
  box-sizing: content-box;
  display: table;
  height: 100%;
  margin: -1px;
  width: 100%; }

.videoCookieMessage .contentHolder {
  display: table-cell;
  vertical-align: middle; }

.videoCookieMessage .contentHolder .inner {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  width: 100%; }

.videoCookieMessage svg {
  height: 60px;
  margin-bottom: 13px;
  vertical-align: top;
  width: 60px; }

.videoCookieMessage span.title {
  color: #4A4A4A;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 0 20px; }

.videoCookieMessage p {
  color: #4A4A4A;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 14px;
  padding: 0 20px; }

.videoCookieMessage p a {
  border-bottom: 0;
  color: #3388C2;
  padding-bottom: 0;
  text-decoration: underline; }

.videoCookieMessage p a:hover,
.videoCookieMessage p a:active,
.videoCookieMessage p a:focus {
  border-bottom: 0;
  text-decoration: none; }

.media.youtube.right .videoCookieMessage svg {
  display: none; }

@media only screen and (max-width: 676px) {
  .videoCookieMessage {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: 0; }
  .videoCookieMessage svg {
    display: none; }
  #video1 .videoCookieMessage .contentHolder .inner {
    padding: 10px 0; }
  #video1 .videoCookieMessage .contentHolder .inner svg {
    display: none; } }

#cookieConfigure .checkboxButton {
  margin-bottom: 15px; }
  #cookieConfigure .checkboxButton label {
    display: block;
    margin-bottom: 10px; }
    #cookieConfigure .checkboxButton label span {
      display: inline-block;
      vertical-align: middle;
      max-width: calc(100% - 50px); }

/**
 *
 * sb-mdl-signup
 * @author	: Nils Hendriks
 * @url		:    nirusu.me
 * @date	: 02-05-2018
 *
 * This is styling for the MailChimp sign up form
 */
.sb-mdl-signup {
  background-color: #f5f5f5;
  max-width: 700px;
  margin: 0 auto 45px auto;
  padding: 38px 60px 40px 60px; }
  footer .sb-mdl-signup {
    background-color: transparent;
    padding: 0; }
    footer .sb-mdl-signup .sb-signup__title {
      color: #fff;
      font-weight: 400;
      margin-bottom: 10px; }
    footer .sb-mdl-signup .sb-signup__label {
      display: none; }
    footer .sb-mdl-signup .sb-signup__input {
      border-color: #fff;
      background-color: transparent; }
      footer .sb-mdl-signup .sb-signup__input::placeholder {
        color: #fff; }

.mc-field-row {
  display: flex;
  margin: -10px;
  width: 100%; }
  .mc-field-row .mc-field-group {
    flex: 1;
    padding: 10px; }

.sb-signup__title {
  font-size: 18px;
  font-family: "Avenir", sans-serif;
  color: #5b5b5b;
  font-weight: 900;
  line-height: 1.333; }
  footer .sb-signup__title {
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px; }

.sb-signup__label {
  font-size: 18px;
  font-family: "Avenir", sans-serif;
  color: #5b5b5b;
  font-weight: 400;
  line-height: 1.333; }

.sb-signup__input {
  border: 2px solid #CBCBCB;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 35px;
  font-family: "Avenir", sans-serif;
  color: #5b5b5b; }

.sb-mdl-signup .sb-signup__input--email {
  display: block;
  width: 100%; }

/**
 *
 * sb-mdl-buttons
 * @author	: Nils Hendriks
 * @url		: nirusu.me
 * @date	: 02-05-2018
 *
 * Styling for buttons
 */
.sb-mdl-button, .sb-mdl-button--floatright {
  border-radius: 5px;
  border: 2px solid #CBCBCB;
  background-color: #630460;
  background-image: url("/img/arrow-formsubmit.png");
  background-repeat: no-repeat;
  background-position: 88% 53%;
  background-size: 18px 13px;
  color: #fff;
  font-family: "Avenir", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  padding: 14px 50px 14px 15px;
  text-decoration: none;
  margin-top: 20px;
  transition: all 1s; }
  .sb-mdl-button:hover, .sb-mdl-button--floatright:hover, .sb-mdl-button:focus, .sb-mdl-button--floatright:focus {
    background-position: 94% 53%; }
  footer .sb-mdl-button, footer .sb-mdl-button--floatright {
    background-color: transparent;
    border: 2px solid #fff; }

.sb-mdl-button--floatright {
  float: right; }

.sb-mdl-link {
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  font-family: "Avenir", sans-serif;
  line-height: 1.6;
  text-decoration: none;
  background-image: url("/img/arrow-formsubmit.png");
  background-repeat: no-repeat;
  background-position: 0 53%;
  background-size: 18px 13px;
  padding: 0 0 0 40px;
  transition: all 1s; }
  .sb-mdl-link:hover, .sb-mdl-link:focus {
    background-position: 12% 53%; }

html, body {
  overflow-x: hidden; }

body {
  width: 100%;
  height: 100%;
  color: #5b5b5b;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  text-rendering: optimizelegibility;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; }

h1 {
  font-size: 1.7em; }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: 2.5em; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 3.75em; } }

h2 {
  font-size: 1.35em;
  font-weight: 400;
  margin-bottom: 15px; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 1.5em;
      margin-bottom: 17px; } }
  @media screen and (min-width: 1024px) {
    h2 {
      font-size: 1.875em;
      margin-bottom: 20px; } }

h3 {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 8px; }
  @media screen and (min-width: 768px) {
    h3 {
      font-size: 1.1em;
      margin-bottom: 12px;
      margin-top: 10px; } }
  @media screen and (min-width: 1024px) {
    h3 {
      font-size: 1.2em;
      margin-bottom: 14px;
      margin-top: 12px; } }

h4 {
  font-size: 1em; }

h5 {
  font-size: 0.83em; }

h6 {
  font-size: 0.67em; }

.intro {
  font-size: 1.2em; }

strong {
  font-weight: 900; }

p {
  font-size: 1em;
  margin-bottom: 20px;
  line-height: 1.5em; }
  p.intro {
    font-size: 1em;
    line-height: 1.35em;
    font-weight: 900;
    margin-bottom: 18px; }
    @media screen and (min-width: 768px) {
      p.intro {
        font-size: 1.25em;
        line-height: 1.6em;
        margin-bottom: 38px; } }

span.intro {
  font-size: 1em;
  line-height: 1.35em;
  font-weight: 900;
  margin-bottom: 18px; }
  @media screen and (min-width: 768px) {
    span.intro {
      font-size: 1.25em;
      line-height: 1.6em;
      margin-bottom: 38px; } }

blockquote {
  font-weight: 700;
  font-size: 1.2em;
  margin: 17px 0 29px; }
  @media screen and (min-width: 768px) {
    blockquote {
      margin: 23px 0 37px;
      font-size: 1.3em; } }
  @media screen and (min-width: 1024px) {
    blockquote {
      margin: 37px 0 47px;
      font-size: 1.4em; } }

a.arrowButton {
  background: #630460;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  padding: 6px 10px 7px 9px; }
  @media screen and (min-width: 768px) {
    a.arrowButton {
      padding: 11px 10px 10px 9px; } }
  @media screen and (min-width: 1024px) {
    a.arrowButton {
      padding: 17px 20px 16px 19px; } }
  a.arrowButton span {
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    margin-right: 16px;
    vertical-align: middle; }
    @media screen and (min-width: 768px) {
      a.arrowButton span {
        font-size: 1.25em; } }
    @media screen and (min-width: 1024px) {
      a.arrowButton span {
        font-size: 1.375em; } }
  a.arrowButton svg {
    display: inline-block;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    fill: #ffffff;
    margin-top: 4px;
    vertical-align: top;
    width: 18px; }
  a.arrowButton:hover svg {
    -moz-transform: translateX(6px);
    -o-transform: translateX(6px);
    -ms-transform: translateX(6px);
    -webkit-transform: translateX(6px);
    transform: translateX(6px); }

a {
  color: #5b5b5b;
  text-decoration: underline; }
  a:hover {
    text-decoration: none; }
  a.button {
    background: #630460;
    border-radius: 4px;
    color: #ffffff;
    padding: 10px;
    text-decoration: none;
    display: inline-block;
    padding: 6px 10px 7px 9px;
    font-size: 1em; }
    @media screen and (min-width: 768px) {
      a.button {
        padding: 11px 15px 10px 14px;
        font-size: 1.15em; } }
    @media screen and (min-width: 1024px) {
      a.button {
        padding: 17px 20px 16px 19px;
        font-size: 1.25em; } }
    a.button.bottomSpacing {
      margin-bottom: 77px; }
    a.button svg {
      display: inline-block;
      fill: #ffffff;
      margin-left: 0;
      width: 18px;
      vertical-align: middle;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms; }
    a.button span {
      display: inline-block;
      font-size: 1em;
      font-weight: 700;
      margin-right: 14px;
      vertical-align: middle; }
      @media screen and (min-width: 768px) {
        a.button span {
          font-size: 1.15em; } }
      @media screen and (min-width: 1024px) {
        a.button span {
          font-size: 1.25em; } }
    a.button:hover svg {
      -moz-transform: translateX(6px);
      -o-transform: translateX(6px);
      -ms-transform: translateX(6px);
      -webkit-transform: translateX(6px);
      transform: translateX(6px); }

button.arrow {
  background: #630460;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  padding: 6px 10px 7px 9px; }
  @media screen and (min-width: 768px) {
    button.arrow {
      padding: 11px 10px 10px 9px; } }
  @media screen and (min-width: 1024px) {
    button.arrow {
      padding: 17px 20px 16px 19px; } }
  button.arrow.cancel span {
    margin-right: 0; }
  button.arrow span {
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    margin-right: 16px;
    vertical-align: middle; }
    @media screen and (min-width: 768px) {
      button.arrow span {
        font-size: 1.25em; } }
    @media screen and (min-width: 1024px) {
      button.arrow span {
        font-size: 1.375em; } }
  button.arrow svg {
    display: inline-block;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    fill: #ffffff;
    margin-top: 4px;
    vertical-align: top;
    width: 18px; }
  button.arrow:hover svg {
    -moz-transform: translateX(6px);
    -o-transform: translateX(6px);
    -ms-transform: translateX(6px);
    -webkit-transform: translateX(6px);
    transform: translateX(6px); }

button.button {
  background: #630460;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  padding: 6px 10px 7px 9px; }
  @media screen and (min-width: 768px) {
    button.button {
      padding: 11px 10px 10px 9px; } }
  @media screen and (min-width: 1024px) {
    button.button {
      padding: 17px 20px 16px 19px; } }
  button.button span {
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    margin-right: 16px;
    vertical-align: middle; }
    @media screen and (min-width: 768px) {
      button.button span {
        font-size: 1.25em; } }
    @media screen and (min-width: 1024px) {
      button.button span {
        font-size: 1.375em; } }
  button.button.configure span {
    margin-right: 0px; }

a.back {
  padding: 9px 20px 14px 20px; }
  @media screen and (min-width: 768px) {
    a.back {
      padding: 12px 20px 15px 20px; } }
  @media screen and (min-width: 1024px) {
    a.back {
      padding: 16px 20px 19px 20px; } }
  a.back svg {
    display: inline-block;
    fill: #ffffff;
    width: 13px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    vertical-align: top;
    margin-top: 7px; }
    @media screen and (min-width: 768px) {
      a.back svg {
        margin-right: 4px;
        margin-top: 4px;
        width: 15px; } }
    @media screen and (min-width: 1024px) {
      a.back svg {
        margin-top: 7px;
        width: 18px; } }
  a.back span {
    color: #ffffff;
    font-size: 1em;
    font-weight: 700;
    display: none; }
    @media screen and (min-width: 768px) {
      a.back span {
        display: inline-block; } }
    @media screen and (min-width: 1024px) {
      a.back span {
        font-size: 1.25em; } }
  a.back:hover svg {
    -moz-transform: translateX(-6px);
    -o-transform: translateX(-6px);
    -ms-transform: translateX(-6px);
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }

div.contactInfoBlock {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  margin-bottom: 36px;
  padding: 14px 20px 2px; }
  @media screen and (min-width: 768px) {
    div.contactInfoBlock {
      padding: 30px 5.55556% 5px; } }
  @media screen and (min-width: 1024px) {
    div.contactInfoBlock {
      padding: 45px 5.55556% 10px; } }
  div.contactInfoBlock p {
    margin: 0 0 22px; }
    @media screen and (min-width: 768px) {
      div.contactInfoBlock p {
        margin: 0 0 19px; } }
    @media screen and (min-width: 1024px) {
      div.contactInfoBlock p {
        margin: 0 20px 38px; } }
    div.contactInfoBlock p a {
      color: #4a8dc0;
      font-weight: 900; }
  div.contactInfoBlock strong {
    display: block; }

header {
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }
  @media screen and (min-width: 1024px) {
    header {
      padding-top: 48px;
      position: relative; } }
  header div.container {
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 1024px) {
      header div.container {
        padding-left: 10px;
        padding-right: 10px; } }
  header a.logo {
    position: absolute;
    display: inline-block;
    top: 15px;
    left: 30px; }
    @media screen and (min-width: 1024px) {
      header a.logo {
        top: 24px;
        left: 33px; } }
    header a.logo svg {
      fill: #004f34;
      width: 100px;
      vertical-align: top; }
  header div.openMenu {
    display: block; }
    @media screen and (min-width: 1024px) {
      header div.openMenu {
        display: none; } }
    header div.openMenu button {
      background: transparent;
      border: none;
      cursor: pointer;
      float: right;
      font-size: 1em;
      margin: 11px 10px 12px 0;
      padding: 5px 7px;
      text-align: center;
      max-width: 60px; }
      header div.openMenu button span.icon {
        display: inline-block;
        margin: 10px 0;
        height: 6px;
        position: relative;
        width: 35px; }
        header div.openMenu button span.icon > span {
          background: #005034;
          border-radius: 2px;
          display: block;
          margin: 4px 0;
          height: 6px;
          position: absolute;
          width: 100%;
          -webkit-transition: all 300ms;
          -moz-transition: all 300ms;
          -ms-transition: all 300ms;
          -o-transition: all 300ms;
          transition: all 300ms; }
        header div.openMenu button span.icon span.top {
          -moz-transform: translateY(-10px);
          -o-transform: translateY(-10px);
          -ms-transform: translateY(-10px);
          -webkit-transform: translateY(-10px);
          transform: translateY(-10px); }
        header div.openMenu button span.icon .middle {
          margin-left: 0;
          width: 100%; }
        header div.openMenu button span.icon .bottom {
          -moz-transform: translateY(10px);
          -o-transform: translateY(10px);
          -ms-transform: translateY(10px);
          -webkit-transform: translateY(10px);
          transform: translateY(10px); }
      header div.openMenu button span.text {
        color: #005034;
        display: inline-block;
        font-weight: 900;
        padding: 12px 0 0;
        text-align: center;
        width: 100%;
        -webkit-transition: opacity 300ms;
        -moz-transition: opacity 300ms;
        -ms-transition: opacity 300ms;
        -o-transition: opacity 300ms;
        transition: opacity 300ms; }
      header div.openMenu button span.openText {
        opacity: 1;
        filter: alpha(opacity=100);
        position: relative; }
      header div.openMenu button span.closeText {
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute; }
  header div.logoBulbHolder {
    position: absolute;
    width: 250px;
    bottom: auto;
    top: 87px;
    left: -53px;
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
    @media screen and (min-width: 1024px) {
      header div.logoBulbHolder {
        bottom: -60px;
        left: -88px;
        width: 307px;
        top: auto; } }
    header div.logoBulbHolder:before {
      background: #ffffff;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 13px;
      height: 1px;
      width: 282px; }
    header div.logoBulbHolder svg.logoBulb {
      fill: #ffffff;
      vertical-align: top;
      width: 250px; }
      @media screen and (min-width: 1024px) {
        header div.logoBulbHolder svg.logoBulb {
          width: 307px; } }
      @media screen and (min-width: 1024px) {
        header div.logoBulbHolder svg.logoBulb path {
          stroke: #ebebeb;
          stroke-width: 1px;
          stroke-dasharray: 0;
          stroke-linejoin: miter; } }
  header div.navigationHolder {
    display: block;
    text-align: right; }
    @media screen and (max-width: 1023px) {
      header div.navigationHolder {
        display: none;
        z-index: 9998; } }
  @media screen and (max-width: 1023px) {
    header div.topHolder {
      background: #ffffff;
      z-index: 9999; } }
  @media screen and (max-width: 1023px) {
    header div.topHolder:after {
      content: '';
      clear: both;
      display: table; } }
  @media screen and (min-width: 1024px) {
    header div.navigation {
      display: inline-block; } }
  header div.navigation nav {
    padding: 30px 0 5px; }
    @media screen and (min-width: 1024px) {
      header div.navigation nav {
        padding: 30px 0 33px; } }
    header div.navigation nav ul li {
      margin: 0 20px 19px 0; }
      @media screen and (min-width: 1024px) {
        header div.navigation nav ul li {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 23px; } }
      @media screen and (min-width: 1024px) {
        header div.navigation nav ul li:last-child {
          margin-right: 0; } }
      header div.navigation nav ul li a {
        color: #5b5b5b;
        font-size: 1em;
        font-weight: 900;
        letter-spacing: 1px;
        padding: 8px 0 11px;
        position: relative;
        text-decoration: none; }
        header div.navigation nav ul li a span {
          position: relative; }
        header div.navigation nav ul li a:before {
          background: #ebebeb;
          bottom: 0;
          content: '';
          display: block;
          left: -8px;
          position: absolute;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          width: 0;
          -webkit-transition: opacity 300ms, width 300ms;
          -moz-transition: opacity 300ms, width 300ms;
          -ms-transition: opacity 300ms, width 300ms;
          -o-transition: opacity 300ms, width 300ms;
          transition: opacity 300ms, width 300ms; }
        header div.navigation nav ul li a:hover:before, header div.navigation nav ul li a.active:before {
          width: calc(100% + 16px);
          opacity: 1;
          filter: alpha(opacity=100); }
  header div.rainbowHolder {
    bottom: -5px;
    position: absolute;
    pointer-events: none;
    width: calc(100% - 170px);
    right: 0;
    left: auto;
    -webkit-transition: width 300ms;
    -moz-transition: width 300ms;
    -ms-transition: width 300ms;
    -o-transition: width 300ms;
    transition: width 300ms; }
    @media screen and (min-width: 1024px) {
      header div.rainbowHolder {
        left: 0;
        right: auto;
        width: 100%; } }
    header div.rainbowHolder div.rainbow {
      height: 8px; }
      header div.rainbowHolder div.rainbow > div {
        height: 8px; }

div.metaMenu {
  background: #f8f8f8;
  position: relative;
  width: 100%;
  transition: ease transform .5s; }
  div.metaMenu .navHolder {
    display: flex;
    flex-direction: column-reverse; }
  @media screen and (min-width: 1024px) {
    div.metaMenu .navHolder {
      flex-direction: row;
      justify-content: flex-end; } }
  div.metaMenu nav.mainMenuClone {
    display: flex;
    padding-left: 15px;
    padding-right: 15px; }
    div.metaMenu nav.mainMenuClone ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap; }
      div.metaMenu nav.mainMenuClone ul li:last-of-type {
        margin-right: 15px; }
  div.metaMenu.metaMenuClone {
    transform: translateY(-100%) translateY(-20px);
    z-index: 99999;
    background: #ffffff;
    border-bottom: 1px solid #ebebeb; }
    @media screen and (max-width: 1023px) {
      div.metaMenu.metaMenuClone {
        display: none; } }
    div.metaMenu.metaMenuClone .actions {
      /*font-size: .9em;*/ }
      div.metaMenu.metaMenuClone .actions .login {
        margin-left: 15px; }
      div.metaMenu.metaMenuClone .actions .cart {
        margin-bottom: 12px; }
    div.metaMenu.metaMenuClone a.logo {
      display: flex;
      align-items: center;
      margin-right: auto;
      width: 43px;
      padding-top: 2px; }
      div.metaMenu.metaMenuClone a.logo svg path {
        fill: #004f34; }
  div.metaMenu.sticky {
    position: fixed;
    transform: translateY(0);
    transition-delay: 0.5s; }
  @media screen and (min-width: 1024px) {
    div.metaMenu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999; } }
  div.metaMenu .container {
    background: #f8f8f8; }
    @media screen and (min-width: 1024px) {
      div.metaMenu .container {
        background: transparent;
        margin-left: auto;
        margin-right: auto; } }
  div.metaMenu nav {
    padding: 13px 0 15px; }
    @media screen and (min-width: 1024px) {
      div.metaMenu nav {
        border-right: 1px solid #e1e0e0; } }
    div.metaMenu nav ul {
      padding-top: 19px; }
      @media screen and (min-width: 1024px) {
        div.metaMenu nav ul {
          padding: 0; } }
      div.metaMenu nav ul li {
        padding: 0 20px 19px; }
        @media screen and (min-width: 1024px) {
          div.metaMenu nav ul li {
            display: inline-block;
            padding: 0;
            margin-right: 15px; } }
        @media screen and (min-width: 1024px) {
          div.metaMenu nav ul li:last-child {
            margin-right: 30px; } }
        div.metaMenu nav ul li a {
          color: #989898;
          font-size: 1em;
          font-weight: 900;
          letter-spacing: 1px;
          text-decoration: none;
          -webkit-transition: color 300ms;
          -moz-transition: color 300ms;
          -ms-transition: color 300ms;
          -o-transition: color 300ms;
          transition: color 300ms; }
          @media screen and (min-width: 1024px) {
            div.metaMenu nav ul li a {
              font-size: 0.875em; } }
          div.metaMenu nav ul li a:hover {
            color: #5b5b5b; }
  div.metaMenu div.actions {
    position: relative;
    background: #ffffff;
    padding-bottom: 30px;
    padding-right: 20px; }
    @media screen and (min-width: 1024px) {
      div.metaMenu div.actions {
        background: transparent;
        padding-bottom: 0;
        padding-right: 0; } }
    div.metaMenu div.actions div.cart > span {
      display: block; }
      div.metaMenu div.actions div.cart > span:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url("../img/icon_cart.svg");
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: top; }
    div.metaMenu div.actions div.cart.show:after {
      background: rgba(0, 0, 0, 0.36);
      cursor: default;
      content: '';
      display: none;
      position: fixed;
      height: calc(100vh - 48px);
      bottom: 0;
      left: 0;
      right: 0;
      top: 48px;
      pointer-events: none; }
      @media screen and (min-width: 768px) {
        div.metaMenu div.actions div.cart.show:after {
          display: block; } }
    div.metaMenu div.actions div.cart.show .cartContent {
      display: block; }
    div.metaMenu div.actions .cartContent {
      position: absolute;
      top: 100%;
      right: -10px;
      z-index: 10;
      display: none;
      padding: 10px 20px;
      margin-top: 14px;
      width: 300px;
      background: #fff; }
      div.metaMenu div.actions .cartContent:after {
        background: transparent;
        width: 10px;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid #ffffff;
        content: '';
        padding: 0;
        position: absolute;
        top: -10px;
        right: 25px;
        margin-left: -10px;
        display: none; }
      div.metaMenu div.actions .cartContent .inner.hidden {
        display: none; }
      div.metaMenu div.actions .cartContent .cartContentList {
        margin-bottom: 15px;
        list-style: none; }
        div.metaMenu div.actions .cartContent .cartContentList > li {
          margin-bottom: 5px; }
      div.metaMenu div.actions .cartContent .cartContentArticle,
      div.metaMenu div.actions .cartContent .cartContentQty {
        display: block;
        float: left; }
      div.metaMenu div.actions .cartContent .cartContentArticle {
        margin-right: 15px; }
      div.metaMenu div.actions .cartContent .cartContentQty {
        float: right; }
      div.metaMenu div.actions .cartContent .cartContentButton {
        padding: 10px 20px;
        width: 100%;
        text-align: center; }
    div.metaMenu div.actions div.login,
    div.metaMenu div.actions div.cart {
      cursor: pointer;
      margin: 12px 0 0 30px; }
      @media screen and (min-width: 1024px) {
        div.metaMenu div.actions div.login,
        div.metaMenu div.actions div.cart {
          display: inline-block;
          float: left; } }
      div.metaMenu div.actions div.login > span,
      div.metaMenu div.actions div.cart > span {
        color: #630460;
        letter-spacing: 1px;
        line-height: 1.6;
        font-size: 1em;
        font-weight: 900; }
        @media screen and (min-width: 1024px) {
          div.metaMenu div.actions div.login > span,
          div.metaMenu div.actions div.cart > span {
            font-size: 0.875em; } }
      div.metaMenu div.actions div.login svg,
      div.metaMenu div.actions div.cart svg {
        fill: #630460;
        margin-right: 10px;
        width: 14px;
        vertical-align: top; }
        div.metaMenu div.actions div.login svg.cart,
        div.metaMenu div.actions div.cart svg.cart {
          width: 20px;
          height: 20px; }
      div.metaMenu div.actions div.login a,
      div.metaMenu div.actions div.cart a {
        text-decoration: none; }
        div.metaMenu div.actions div.login a > span,
        div.metaMenu div.actions div.cart a > span {
          color: #630460;
          letter-spacing: 1px;
          font-size: 1em;
          font-weight: 900; }
          @media screen and (min-width: 1024px) {
            div.metaMenu div.actions div.login a > span,
            div.metaMenu div.actions div.cart a > span {
              font-size: 0.875em; } }
      div.metaMenu div.actions div.login.show div.loggedInFo,
      div.metaMenu div.actions div.cart.show div.loggedInFo {
        pointer-events: all;
        display: block; }
        div.metaMenu div.actions div.login.show div.loggedInFo:before,
        div.metaMenu div.actions div.cart.show div.loggedInFo:before {
          pointer-events: all; }
        div.metaMenu div.actions div.login.show div.loggedInFo div.inner,
        div.metaMenu div.actions div.cart.show div.loggedInFo div.inner {
          pointer-events: all; }
      div.metaMenu div.actions div.login div.loggedInFo,
      div.metaMenu div.actions div.cart div.loggedInFo {
        cursor: default;
        text-align: right;
        pointer-events: all;
        display: block; }
        @media screen and (min-width: 768px) {
          div.metaMenu div.actions div.login div.loggedInFo,
          div.metaMenu div.actions div.cart div.loggedInFo {
            text-align: left;
            pointer-events: none;
            display: none; } }
        div.metaMenu div.actions div.login div.loggedInFo:before,
        div.metaMenu div.actions div.cart div.loggedInFo:before {
          background: rgba(0, 0, 0, 0.36);
          cursor: default;
          content: '';
          display: none;
          position: fixed;
          height: calc(100vh - 48px);
          bottom: 0;
          left: 0;
          right: 0;
          top: 48px;
          pointer-events: none; }
          @media screen and (min-width: 768px) {
            div.metaMenu div.actions div.login div.loggedInFo:before,
            div.metaMenu div.actions div.cart div.loggedInFo:before {
              display: block; } }
        div.metaMenu div.actions div.login div.loggedInFo div.inner,
        div.metaMenu div.actions div.cart div.loggedInFo div.inner {
          background: #ffffff;
          position: relative;
          padding: 14px 0 0;
          pointer-events: none; }
          @media screen and (min-width: 768px) {
            div.metaMenu div.actions div.login div.loggedInFo div.inner,
            div.metaMenu div.actions div.cart div.loggedInFo div.inner {
              position: absolute;
              max-width: 380px;
              padding: 34px 50px 22px;
              top: 67px;
              right: 0; } }
          div.metaMenu div.actions div.login div.loggedInFo div.inner:after,
          div.metaMenu div.actions div.cart div.loggedInFo div.inner:after {
            display: none;
            background: transparent;
            width: 10px;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 10px solid #ffffff;
            content: '';
            padding: 0;
            position: absolute;
            top: -10px;
            right: 25px;
            margin-left: -10px; }
            @media screen and (min-width: 768px) {
              div.metaMenu div.actions div.login div.loggedInFo div.inner:after,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner:after {
                display: block; } }
          div.metaMenu div.actions div.login div.loggedInFo div.inner.canRent:after,
          div.metaMenu div.actions div.cart div.loggedInFo div.inner.canRent:after {
            right: 165px; }
          div.metaMenu div.actions div.login div.loggedInFo div.inner span.name,
          div.metaMenu div.actions div.cart div.loggedInFo div.inner span.name {
            color: #5b5b5b;
            display: none;
            font-size: 1.875em;
            margin-bottom: 20px; }
            @media screen and (min-width: 768px) {
              div.metaMenu div.actions div.login div.loggedInFo div.inner span.name,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner span.name {
                display: block; } }
          div.metaMenu div.actions div.login div.loggedInFo div.inner ul,
          div.metaMenu div.actions div.cart div.loggedInFo div.inner ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            @media screen and (min-width: 768px) {
              div.metaMenu div.actions div.login div.loggedInFo div.inner ul li,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li {
                border-top: 1px solid #e1e0e0; } }
            div.metaMenu div.actions div.login div.loggedInFo div.inner ul li:first-child,
            div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li:first-child {
              border-top: none; }
            div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a,
            div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a {
              display: inline-block;
              padding: 6px 0 7px;
              text-decoration: none; }
              @media screen and (min-width: 768px) {
                div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a,
                div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a {
                  padding: 26px 0 27px; } }
              div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a span,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a span {
                color: #4a8dc0;
                display: inline-block;
                font-size: 1em;
                font-weight: 700;
                vertical-align: top; }
              div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a svg,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a svg {
                display: none;
                -webkit-transition: all 300ms;
                -moz-transition: all 300ms;
                -ms-transition: all 300ms;
                -o-transition: all 300ms;
                transition: all 300ms;
                fill: #4a8dc0;
                margin-right: 7px;
                margin-top: 4px;
                vertical-align: top;
                width: 18px; }
                @media screen and (min-width: 768px) {
                  div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a svg,
                  div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a svg {
                    display: inline-block; } }
              div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a:hover svg,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a:hover svg {
                -moz-transform: translateX(6px);
                -o-transform: translateX(6px);
                -ms-transform: translateX(6px);
                -webkit-transform: translateX(6px);
                transform: translateX(6px); }
              div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a.logout span,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a.logout span {
                color: #630460; }
              div.metaMenu div.actions div.login div.loggedInFo div.inner ul li a.logout svg,
              div.metaMenu div.actions div.cart div.loggedInFo div.inner ul li a.logout svg {
                fill: #630460; }
    div.metaMenu div.actions div.myList {
      display: inline-block;
      cursor: pointer;
      margin: 19px 0 0 24px; }
      @media screen and (min-width: 1024px) {
        div.metaMenu div.actions div.myList {
          margin: 12px 0 0 24px;
          display: inline-block; } }
      div.metaMenu div.actions div.myList > span {
        color: #630460;
        letter-spacing: 1px;
        font-size: 1em;
        font-weight: 900; }
        @media screen and (min-width: 1024px) {
          div.metaMenu div.actions div.myList > span {
            font-size: 0.875em; } }
      div.metaMenu div.actions div.myList svg {
        fill: #630460;
        margin-right: 8px;
        width: 19px;
        vertical-align: middle; }
  div.metaMenu.metaMenuClone .actions div.cart.show:after {
    height: calc(100vh + 24px);
    top: 100%; }

.showMenu {
  overflow-y: hidden;
  height: 100vh; }
  .showMenu header div.openMenu button span.icon span.top {
    -moz-transform: rotate(45deg) translateY(0);
    -o-transform: rotate(45deg) translateY(0);
    -ms-transform: rotate(45deg) translateY(0);
    -webkit-transform: rotate(45deg) translateY(0);
    transform: rotate(45deg) translateY(0); }
  .showMenu header div.openMenu button span.icon span.middle {
    margin-left: 50%;
    width: 0; }
  .showMenu header div.openMenu button span.icon span.bottom {
    -moz-transform: rotate(-45deg) translateY(0);
    -o-transform: rotate(-45deg) translateY(0);
    -ms-transform: rotate(-45deg) translateY(0);
    -webkit-transform: rotate(-45deg) translateY(0);
    transform: rotate(-45deg) translateY(0); }
  .showMenu header div.openMenu span.openText {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute; }
  .showMenu header div.openMenu span.closeText {
    opacity: 1;
    filter: alpha(opacity=100);
    position: relative; }
  .showMenu header div.rainbowHolder {
    width: 100%; }
  .showMenu header div.navigationHolder {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 103px); }

.slider {
  margin: 27px 0 39px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  position: relative;
  padding: 0 15px; }
  @media screen and (min-width: 768px) {
    .slider {
      margin: 53px 0 67px; } }
  @media screen and (min-width: 1024px) {
    .slider {
      margin: 73px 0 97px; } }
  .slider div.photoSlider {
    margin: 0 5.55556%; }
  .slider div.sliderNavigation {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 0;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%; }
    .slider div.sliderNavigation button {
      background: transparent;
      border: none;
      cursor: pointer;
      left: 0;
      padding: 0;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      position: absolute;
      pointer-events: all;
      opacity: 1;
      filter: alpha(opacity=100);
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms; }
      .slider div.sliderNavigation button svg {
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms;
        vertical-align: middle;
        width: 20px; }
        @media screen and (min-width: 768px) {
          .slider div.sliderNavigation button svg {
            width: 30px; } }
      .slider div.sliderNavigation button.next {
        left: auto;
        right: 0; }
      .slider div.sliderNavigation button:hover svg {
        -moz-transform: translateX(-6px);
        -o-transform: translateX(-6px);
        -ms-transform: translateX(-6px);
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px); }
      .slider div.sliderNavigation button:hover.next svg {
        -moz-transform: translateX(6px);
        -o-transform: translateX(6px);
        -ms-transform: translateX(6px);
        -webkit-transform: translateX(6px);
        transform: translateX(6px); }
      .slider div.sliderNavigation button:disabled {
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: default; }
  .slider div.readMoreBlocks span.image {
    position: relative;
    padding-bottom: 71.5%; }
    .slider div.readMoreBlocks span.image img {
      position: absolute; }

section.title {
  margin-top: 99px;
  position: relative; }
  @media screen and (min-width: 1024px) {
    section.title {
      margin-top: 0; } }
  section.title.title--static div.backgroundImage {
    position: relative;
    min-height: 288px; }
    section.title.title--static div.backgroundImage img {
      width: 100%;
      height: 100%;
      max-height: 420px;
      object-fit: cover; }
  section.title.title--static div.content {
    min-height: 220px; }
  section.title.title--static.title--static-share .content .container,
  section.title.title--static.title--static-share .content .pageActions {
    min-height: 220px; }
  section.title.title--static.title--static-share div.backgroundImage {
    position: absolute; }
  section.title.title--static.title--static-share .shareHolder {
    top: 50%;
    transform: translateY(-50%); }
  section.title div.backgroundImage {
    position: absolute;
    left: 0;
    min-height: 366px;
    height: 100%;
    width: 100%; }
  section.title div.content {
    min-height: 366px;
    position: relative;
    width: 100%; }
    section.title div.content h1 {
      color: #ffffff;
      margin: 236px 0 29px;
      text-align: center;
      text-shadow: 0px 5px 30px rgba(30, 30, 30, 0.43); }
      @media screen and (min-width: 768px) {
        section.title div.content h1 {
          margin: 236px 0 59px; } }
  section.title div.pageActions a.back {
    position: absolute;
    margin: 50px 0 0; }
    @media screen and (min-width: 1024px) {
      section.title div.pageActions a.back {
        margin: 95px 0 0; } }
  section.title div.pageActions div.shareHolder {
    position: absolute;
    right: 20px;
    top: 32px; }
    @media screen and (min-width: 568px) {
      section.title div.pageActions div.shareHolder {
        right: 10px; } }
    section.title div.pageActions div.shareHolder div.shareOverlay {
      background: #ffffff;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.35);
      pointer-events: none;
      position: absolute;
      z-index: 999;
      margin-left: -90px;
      left: 50%;
      top: 80px;
      width: 180px;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      opacity: 0;
      filter: alpha(opacity=0); }
      @media screen and (min-width: 768px) {
        section.title div.pageActions div.shareHolder div.shareOverlay {
          width: 240px;
          margin-left: -120px; } }
      section.title div.pageActions div.shareHolder div.shareOverlay:after {
        background: transparent;
        width: 10px;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid #ffffff;
        content: '';
        padding: 0;
        position: absolute;
        top: -10px;
        left: 50%;
        margin-left: -10px; }
      section.title div.pageActions div.shareHolder div.shareOverlay div.dcShare {
        padding: 21px 20px 6px; }
        @media screen and (min-width: 768px) {
          section.title div.pageActions div.shareHolder div.shareOverlay div.dcShare {
            padding: 51px 50px 26px; } }
        section.title div.pageActions div.shareHolder div.shareOverlay div.dcShare > a {
          display: block;
          margin-left: 0;
          margin-bottom: 21px;
          max-width: 100%; }
          section.title div.pageActions div.shareHolder div.shareOverlay div.dcShare > a svg {
            fill: #4a8dc0;
            display: inline-block;
            margin: 0 19px 0 0;
            width: 27px; }
          section.title div.pageActions div.shareHolder div.shareOverlay div.dcShare > a span {
            color: #4a8dc0;
            display: inline-block;
            font-size: 1em;
            margin-top: 4px;
            vertical-align: top; }
  section.title div.pageActions div.share {
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin-right: 6px;
    position: relative;
    -webkit-transition: background 300ms;
    -moz-transition: background 300ms;
    -ms-transition: background 300ms;
    -o-transition: background 300ms;
    transition: background 300ms; }
    section.title div.pageActions div.share.show {
      background: #630460; }
      section.title div.pageActions div.share.show > span > svg {
        fill: #ffffff; }
      section.title div.pageActions div.share.show > span > span {
        color: #ffffff; }
      section.title div.pageActions div.share.show div.shareOverlay {
        opacity: 1;
        filter: alpha(opacity=100);
        pointer-events: all; }
    section.title div.pageActions div.share > span {
      display: inline-block;
      padding: 9px 19px 14px 19px; }
      @media screen and (min-width: 768px) {
        section.title div.pageActions div.share > span {
          padding: 11px 19px 14px 19px; } }
      @media screen and (min-width: 1024px) {
        section.title div.pageActions div.share > span {
          padding: 14px 19px 17px 19px; } }
    section.title div.pageActions div.share svg {
      display: inline-block;
      fill: #630460;
      width: 24px;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      vertical-align: top;
      margin-top: 7px; }
      @media screen and (min-width: 768px) {
        section.title div.pageActions div.share svg {
          margin-right: 4px;
          margin-top: 4px;
          width: 24px; } }
      @media screen and (min-width: 1024px) {
        section.title div.pageActions div.share svg {
          margin-top: 7px;
          width: 24px; } }
    section.title div.pageActions div.share > span > span {
      color: #630460;
      font-size: 1em;
      font-weight: 700;
      display: none;
      -webkit-transition: color 300ms;
      -moz-transition: color 300ms;
      -ms-transition: color 300ms;
      -o-transition: color 300ms;
      transition: color 300ms; }
      @media screen and (min-width: 768px) {
        section.title div.pageActions div.share > span > span {
          display: inline-block; } }
      @media screen and (min-width: 1024px) {
        section.title div.pageActions div.share > span > span {
          font-size: 1.25em; } }
    section.title div.pageActions div.share:hover {
      background: #630460; }
      section.title div.pageActions div.share:hover svg {
        fill: #ffffff; }
      section.title div.pageActions div.share:hover span {
        color: #ffffff; }
  section.title div.pageActions div.like {
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    padding: 9px 19px 14px 19px;
    -webkit-transition: background 300ms;
    -moz-transition: background 300ms;
    -ms-transition: background 300ms;
    -o-transition: background 300ms;
    transition: background 300ms; }
    @media screen and (min-width: 768px) {
      section.title div.pageActions div.like {
        padding: 12px 19px 15px 19px; } }
    @media screen and (min-width: 1024px) {
      section.title div.pageActions div.like {
        padding: 14px 20px 17px 20px; } }
    section.title div.pageActions div.like svg {
      fill: #630460;
      width: 27px;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      vertical-align: top;
      margin-top: 5px; }
      @media screen and (min-width: 768px) {
        section.title div.pageActions div.like svg {
          margin-right: 2px;
          margin-top: 0px;
          width: 27px; } }
      @media screen and (min-width: 1024px) {
        section.title div.pageActions div.like svg {
          margin-top: 3px;
          width: 27px; } }
      section.title div.pageActions div.like svg.unliked {
        display: inline-block; }
      section.title div.pageActions div.like svg.liked {
        display: none; }
    section.title div.pageActions div.like.saved svg.unliked {
      display: none; }
    section.title div.pageActions div.like.saved svg.liked {
      display: inline-block; }
    section.title div.pageActions div.like span {
      color: #630460;
      font-size: 1em;
      font-weight: 700;
      display: none;
      -webkit-transition: color 300ms;
      -moz-transition: color 300ms;
      -ms-transition: color 300ms;
      -o-transition: color 300ms;
      transition: color 300ms; }
      @media screen and (min-width: 768px) {
        section.title div.pageActions div.like span {
          display: inline-block; } }
      @media screen and (min-width: 1024px) {
        section.title div.pageActions div.like span {
          font-size: 1.25em; } }
    section.title div.pageActions div.like:hover {
      background: #630460; }
      section.title div.pageActions div.like:hover svg {
        fill: #ffffff; }
      section.title div.pageActions div.like:hover span {
        color: #ffffff; }
    section.title div.pageActions div.like.liked svg.unliked {
      display: none; }
    section.title div.pageActions div.like.liked svg.liked {
      display: inline-block; }
  section.title.search div.content h1 {
    font-size: 1.7em;
    margin: 248px 0 19px; }
    @media screen and (min-width: 768px) {
      section.title.search div.content h1 {
        font-size: 2.5em; } }
    @media screen and (min-width: 1024px) {
      section.title.search div.content h1 {
        font-size: 3.125em; } }
  section.title.search div.backgroundImage {
    position: absolute;
    left: 0;
    min-height: 446px; }
  section.title.search div.searchField {
    position: relative; }
    section.title.search div.searchField .column {
      position: relative; }
    section.title.search div.searchField svg.icon_searchloop {
      pointer-events: none;
      position: absolute;
      fill: #cbcbcb;
      top: 17px;
      left: 23px;
      width: 34px; }
    section.title.search div.searchField input {
      background: #ffffff;
      border: 1px solid #cbcbcb;
      border-radius: 4px;
      color: #414141;
      font-size: 1em;
      font-family: "Avenir", sans-serif;
      line-height: 1.3em;
      padding: 23px 77px 24px 73px;
      width: 100%; }
      section.title.search div.searchField input:focus {
        outline: 0; }
      section.title.search div.searchField input::placeholder {
        color: #848484; }
      section.title.search div.searchField input::-webkit-search-decoration, section.title.search div.searchField input::-webkit-search-cancel-button, section.title.search div.searchField input::-webkit-search-results-button, section.title.search div.searchField input::-webkit-search-results-decoration {
        display: none; }
    section.title.search div.searchField div.field {
      display: inline-block;
      position: relative;
      width: 100%; }
    section.title.search div.searchField button.search {
      background: #630460;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      color: #ffffff;
      display: inline-block;
      margin-left: 10px;
      padding: 10px;
      text-decoration: none;
      display: inline-block;
      padding: 6px 10px 7px 9px;
      width: 100%; }
      @media screen and (min-width: 768px) {
        section.title.search div.searchField button.search {
          padding: 11px 10px 10px 9px; } }
      @media screen and (min-width: 1024px) {
        section.title.search div.searchField button.search {
          padding: 22px 10px 21px 9px; } }
      section.title.search div.searchField button.search span {
        display: inline-block;
        font-size: 1em;
        font-weight: 900;
        vertical-align: middle; }
        @media screen and (min-width: 768px) {
          section.title.search div.searchField button.search span {
            font-size: 1.25em; } }
        @media screen and (min-width: 1024px) {
          section.title.search div.searchField button.search span {
            font-size: 1.375em; } }
  section.title.search div.suggest {
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    opacity: 0;
    filter: alpha(opacity=0);
    pointer-events: none; }
    section.title.search div.suggest.active {
      opacity: 1;
      filter: alpha(opacity=100);
      pointer-events: all; }
    section.title.search div.suggest ul {
      border: 1px solid #ebebeb;
      list-style: none;
      margin: 0;
      padding: 0; }
      section.title.search div.suggest ul li {
        background: #ffffff;
        border-top: 1px solid #ebebeb;
        position: relative;
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms; }
        section.title.search div.suggest ul li:first-child {
          border: 0; }
        section.title.search div.suggest ul li a {
          text-decoration: none; }
        section.title.search div.suggest ul li span.label {
          display: block;
          padding: 26px 73px 24px;
          -webkit-transition: all 300ms;
          -moz-transition: all 300ms;
          -ms-transition: all 300ms;
          -o-transition: all 300ms;
          transition: all 300ms;
          text-align: left; }
          section.title.search div.suggest ul li span.label > span {
            font-weight: 900; }
        section.title.search div.suggest ul li span.type {
          color: #ebebeb;
          display: inline-block;
          position: absolute;
          right: 29px;
          top: 27px;
          text-align: right; }
        section.title.search div.suggest ul li:hover, section.title.search div.suggest ul li.selected {
          background: #630460; }
          section.title.search div.suggest ul li:hover span.label, section.title.search div.suggest ul li.selected span.label {
            color: #ffffff; }
  section.title.verticalCentered {
    min-height: 446px;
    display: flex;
    align-items: center; }
    section.title.verticalCentered div.content {
      min-height: 0px; }
      section.title.verticalCentered div.content h1 {
        margin: 0;
        line-height: 1em; }

section.content {
  background: #ffffff;
  padding: 0 0 30px;
  /*.column > .renderSlimmage,
	p > img {
		margin: 27px 0 39px;

		@include min-screen($tabletBreakpoint) {
			margin: 53px 0 67px;
		}

		@include min-screen($desktopBreakpoint) {
			margin: 73px 0 97px;
		}

	}*/ }
  section.content .flickity-viewport {
    min-height: 325px; }
  section.content .agendaInformation {
    display: flex;
    flex-direction: column;
    align-items: baseline; }
  section.content span.date, section.content span.location {
    font-weight: 700;
    background-color: #f5f5f5;
    display: inline-block;
    padding: 7px 10px;
    margin-bottom: 15px; }
  section.content img {
    max-width: 100%;
    vertical-align: top; }
  section.content div.topRoundedColumn {
    text-align: center; }
    section.content div.topRoundedColumn > div.column {
      background: #ffffff;
      border-radius: 4px 4px 0 0;
      padding-top: 50px;
      margin-top: -20px;
      text-align: left; }
      @media screen and (min-width: 768px) {
        section.content div.topRoundedColumn > div.column {
          margin-top: -30px; } }
  section.content ul, section.content ol {
    padding: 3px 0 0 30px;
    margin-bottom: 22px; }
    section.content ul li, section.content ol li {
      margin-bottom: 4px; }
  section.content .grid-section .column.slimImage > .renderSlimmage {
    margin: 0; }
    @media screen and (min-width: 768px) {
      section.content .grid-section .column.slimImage > .renderSlimmage {
        margin: 0; } }
    @media screen and (min-width: 1024px) {
      section.content .grid-section .column.slimImage > .renderSlimmage {
        margin: 0; } }
  section.content .renderSlimmage .caption {
    font-size: 14px;
    color: #aaaaaa;
    font-style: italic; }
  section.content .video {
    margin: 27px 0 39px; }
    @media screen and (min-width: 768px) {
      section.content .video {
        margin: 53px 0 67px; } }
    @media screen and (min-width: 1024px) {
      section.content .video {
        margin: 73px 0 97px; } }

section.homepageTitle {
  margin-top: 98px;
  position: relative; }
  section.homepageTitle img {
    width: 100%; }
  @media screen and (min-width: 1024px) {
    section.homepageTitle {
      margin-top: 0; } }
  section.homepageTitle div.carousel {
    margin: 0 auto;
    position: relative;
    margin-bottom: 40px;
    max-width: 100%;
    overflow: hidden; }
    section.homepageTitle div.carousel ul {
      width: 100%;
      max-width: 100%;
      padding: 0;
      padding-bottom: 290px;
      position: relative;
      list-style: none;
      margin: 0 auto; }
      @media screen and (min-width: 768px) {
        section.homepageTitle div.carousel ul {
          padding-bottom: 375px; } }
      @media screen and (min-width: 1024px) {
        section.homepageTitle div.carousel ul {
          padding-bottom: 535px; } }
      section.homepageTitle div.carousel ul li {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
        filter: alpha(opacity=0);
        overflow: hidden;
        z-index: 99; }
        section.homepageTitle div.carousel ul li.navInNext {
          -webkit-animation: scaleDownUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          -moz-animation: scaleDownUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          -ms-animation: scaleDownUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          -o-animation: scaleDownUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          animation: scaleDownUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1); }
        section.homepageTitle div.carousel ul li.navOutNext {
          -webkit-animation: scaleUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          -moz-animation: scaleUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          -ms-animation: scaleUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          -o-animation: scaleUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1);
          animation: scaleUp 1.5s forwards cubic-bezier(0.7, 0, 0.3, 1); }
        section.homepageTitle div.carousel ul li.current {
          opacity: 1;
          filter: alpha(opacity=100);
          pointer-events: auto;
          z-index: 999; }
  section.homepageTitle div.content {
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%; }
    section.homepageTitle div.content h1 {
      font-size: 1.7em;
      margin-top: 56px;
      margin-bottom: 4px;
      line-height: 1.15em;
      text-shadow: 0px 5px 30px rgba(30, 30, 30, 0.43); }
      @media screen and (min-width: 768px) {
        section.homepageTitle div.content h1 {
          font-size: 2.5em;
          margin-top: 86px; } }
      @media screen and (min-width: 1024px) {
        section.homepageTitle div.content h1 {
          font-size: 3.125em;
          margin-top: 156px; } }
    section.homepageTitle div.content h2 {
      font-size: 1.275em;
      font-style: italic;
      margin-bottom: 12px;
      text-shadow: 0px 5px 30px rgba(30, 30, 30, 0.43); }
      @media screen and (min-width: 768px) {
        section.homepageTitle div.content h2 {
          font-size: 1.4em;
          margin-bottom: 22px; } }
      @media screen and (min-width: 1024px) {
        section.homepageTitle div.content h2 {
          font-size: 1.875em;
          margin-bottom: 30px; } }

section.extraIntroduction {
  margin: 75px 0 30px; }
  section.extraIntroduction.noMargin {
    margin: 0; }
  section.extraIntroduction.extraMarginTop {
    margin-top: 130px; }
  section.extraIntroduction div.column {
    /*text-align:center;*/ }

section.volunteerVacancies {
  margin: 12px 0 17px; }
  @media screen and (min-width: 568px) {
    section.volunteerVacancies {
      margin: 35px 0 20px; } }
  @media screen and (min-width: 768px) {
    section.volunteerVacancies {
      margin: 102px 0 25px; } }
  section.volunteerVacancies .first h2 {
    margin-top: 8px;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      section.volunteerVacancies .first h2 {
        margin-top: 12px;
        margin-bottom: 15px; } }
    @media screen and (min-width: 1024px) {
      section.volunteerVacancies .first h2 {
        margin-top: 18px;
        margin-bottom: 20px;
        max-width: 220px; } }
  section.volunteerVacancies .first p {
    font-size: 1.125em;
    line-height: 1.7em;
    margin-bottom: 25px; }
    @media screen and (min-width: 1024px) {
      section.volunteerVacancies .first p {
        max-width: 250px; } }
  section.volunteerVacancies .slider {
    margin: 35px 0 0 -4px;
    padding: 0 40px; }
    @media screen and (min-width: 768px) {
      section.volunteerVacancies .slider {
        padding: 0 40px; } }
    @media screen and (min-width: 1024px) {
      section.volunteerVacancies .slider {
        margin-top: 0; } }
    @media screen and (min-width: 1260px) {
      section.volunteerVacancies .slider {
        padding: 0 15px; } }
    section.volunteerVacancies .slider div.sliderNavigation button {
      left: 15px; }
      @media screen and (min-width: 768px) {
        section.volunteerVacancies .slider div.sliderNavigation button {
          left: 0; } }
      @media screen and (min-width: 1260px) {
        section.volunteerVacancies .slider div.sliderNavigation button {
          left: -50px; } }
      section.volunteerVacancies .slider div.sliderNavigation button.next {
        left: auto;
        right: 15px; }
        @media screen and (min-width: 768px) {
          section.volunteerVacancies .slider div.sliderNavigation button.next {
            right: 0; } }
        @media screen and (min-width: 1260px) {
          section.volunteerVacancies .slider div.sliderNavigation button.next {
            right: -50px; } }
    section.volunteerVacancies .slider .column {
      margin-right: 0; }

section.homepageCourses {
  margin-top: 17px; }
  @media screen and (min-width: 768px) {
    section.homepageCourses {
      margin-top: 30px; } }
  @media screen and (min-width: 1024px) {
    section.homepageCourses {
      margin-top: 46px; } }
  section.homepageCourses .readMoreBlocks {
    margin-top: 0; }
    @media screen and (min-width: 768px) {
      section.homepageCourses .readMoreBlocks {
        margin-top: 0; } }
    @media screen and (min-width: 1024px) {
      section.homepageCourses .readMoreBlocks {
        margin-top: 0; } }
  section.homepageCourses a.showAll {
    display: inline-block;
    margin-top: 10px; }
    @media screen and (min-width: 768px) {
      section.homepageCourses a.showAll {
        margin-top: 14px; } }
    @media screen and (min-width: 1024px) {
      section.homepageCourses a.showAll {
        margin-top: 18px; } }
    section.homepageCourses a.showAll span {
      margin-left: 7px;
      margin-right: 0; }
  section.homepageCourses h2 {
    margin-bottom: 47px; }
  section.homepageCourses .column span.image img {
    border-radius: 4px 4px 0 0; }
  section.homepageCourses .column span.image svg {
    vertical-align: top;
    width: 100%; }

section.homepageTools {
  margin-top: 17px; }
  @media screen and (min-width: 768px) {
    section.homepageTools {
      margin-top: 30px;
      margin-bottom: 30px; } }
  @media screen and (min-width: 1024px) {
    section.homepageTools {
      margin-top: 46px;
      margin-bottom: 40px; } }
  section.homepageTools .readMoreBlocks {
    margin-top: 0; }
    @media screen and (min-width: 768px) {
      section.homepageTools .readMoreBlocks {
        margin-top: 0; } }
    @media screen and (min-width: 1024px) {
      section.homepageTools .readMoreBlocks {
        margin-top: 0; } }
  section.homepageTools a.showAll {
    display: inline-block;
    margin-top: 10px; }
    @media screen and (min-width: 768px) {
      section.homepageTools a.showAll {
        margin-top: 14px; } }
    @media screen and (min-width: 1024px) {
      section.homepageTools a.showAll {
        margin-top: 18px; } }
    section.homepageTools a.showAll span {
      margin-left: 7px;
      margin-right: 0; }
  section.homepageTools h2 {
    margin-bottom: 47px; }
  section.homepageTools .column span.image img {
    border-radius: 4px 4px 0 0; }
  section.homepageTools .column span.image svg {
    vertical-align: top;
    width: 100%; }

section.promotionBlock {
  min-height: 366px;
  margin-top: 15px;
  position: relative; }
  section.promotionBlock img {
    width: 100%; }
  @media screen and (min-width: 768px) {
    section.promotionBlock {
      margin-top: 31px; } }
  @media screen and (min-width: 1024px) {
    section.promotionBlock {
      margin-top: 51px; } }
  section.promotionBlock div.backgroundImage {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
  section.promotionBlock div.content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    section.promotionBlock div.content h2 {
      color: #ffffff;
      font-size: 1.35em;
      font-weight: 700;
      text-shadow: 0px 5px 29.75px rgba(30, 30, 30, 0.51);
      margin-bottom: 15px; }
      @media screen and (min-width: 768px) {
        section.promotionBlock div.content h2 {
          font-size: 1.5em;
          margin-bottom: 21px; } }
      @media screen and (min-width: 1024px) {
        section.promotionBlock div.content h2 {
          font-size: 2.188em;
          margin-bottom: 31px; } }

section.themeBlock {
  margin: 50px 0 32px; }
  @media screen and (min-width: 768px) {
    section.themeBlock {
      margin: 100px 0 82px; } }
  section.themeBlock div.row {
    text-align: center;
    position: relative; }
    @media screen and (min-width: 568px) {
      section.themeBlock div.row {
        text-align: right; } }
  section.themeBlock div.greyBorder {
    position: relative; }
    @media screen and (min-width: 568px) {
      section.themeBlock div.greyBorder {
        border-top: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        border-radius: 0 4px 4px 0;
        min-height: 343px;
        margin-right: 10px;
        padding: 0 37px 0 47px; } }
    @media screen and (min-width: 1260px) {
      section.themeBlock div.greyBorder {
        min-height: 343px;
        padding: 0 15px 0 37px; } }
    section.themeBlock div.greyBorder h2 {
      font-weight: 700;
      margin: 23px 0 14px; }
      @media screen and (min-width: 568px) {
        section.themeBlock div.greyBorder h2 {
          margin: 43px 0 14px; } }
  section.themeBlock .image {
    min-height: 230px;
    position: relative; }
    @media screen and (min-width: 568px) {
      section.themeBlock .image {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0; } }
    section.themeBlock .image img {
      border-radius: 4px; }
  section.themeBlock ul {
    list-style: none;
    margin: 0 0 35px;
    padding: 0; }
    section.themeBlock ul li {
      display: block; }
      section.themeBlock ul li a {
        color: #4a8dc0;
        font-size: 1.15em;
        line-height: 1.35em;
        text-decoration: none; }
        @media screen and (min-width: 768px) {
          section.themeBlock ul li a {
            font-size: 1.25em;
            line-height: 1.5em; } }
        section.themeBlock ul li a svg {
          display: inline-block;
          fill: #4a8dc0;
          margin-right: 16px;
          margin-left: 0;
          width: 18px;
          vertical-align: middle;
          -webkit-transition: transform 300ms, fill 300ms;
          -moz-transition: transform 300ms, fill 300ms;
          -ms-transition: transform 300ms, fill 300ms;
          -o-transition: transform 300ms, fill 300ms;
          transition: transform 300ms, fill 300ms; }
        section.themeBlock ul li a span {
          display: inline-block;
          max-width: calc(100% - 40px);
          vertical-align: middle; }
        section.themeBlock ul li a:hover svg {
          -moz-transform: translateX(6px);
          -o-transform: translateX(6px);
          -ms-transform: translateX(6px);
          -webkit-transform: translateX(6px);
          transform: translateX(6px); }

section.stepsBlock {
  background: #79b8b5;
  margin-top: 99px;
  position: relative; }
  @media screen and (min-width: 1024px) {
    section.stepsBlock {
      margin-top: 0; } }
  section.stepsBlock div.stepActions {
    padding: 18px 0 10px;
    text-align: right; }
    @media screen and (min-width: 768px) {
      section.stepsBlock div.stepActions {
        padding: 38px 0 30px; } }
    section.stepsBlock div.stepActions div.column > div {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      display: inline-block;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms; }
      section.stepsBlock div.stepActions div.column > div span.icon {
        background: #4b928e;
        border-radius: 50%;
        display: inline-block;
        height: 38px;
        margin: 0 6px 0 0;
        text-align: center;
        vertical-align: top;
        width: 38px; }
        @media screen and (min-width: 768px) {
          section.stepsBlock div.stepActions div.column > div span.icon {
            height: 48px;
            width: 48px; } }
        section.stepsBlock div.stepActions div.column > div span.icon svg {
          display: inline-block;
          margin: 13px 0 0;
          fill: #ffffff;
          width: 14px; }
          @media screen and (min-width: 768px) {
            section.stepsBlock div.stepActions div.column > div span.icon svg {
              margin: 18px 0 0; } }
      section.stepsBlock div.stepActions div.column > div.openBlock {
        position: absolute;
        opacity: 1;
        filter: alpha(opacity=100);
        width: calc(100% - 30px);
        right: 15px; }
        @media screen and (min-width: 768px) {
          section.stepsBlock div.stepActions div.column > div.openBlock {
            opacity: 0;
            filter: alpha(opacity=0); } }
      section.stepsBlock div.stepActions div.column > div.closeBlock {
        opacity: 0;
        filter: alpha(opacity=0); }
        @media screen and (min-width: 768px) {
          section.stepsBlock div.stepActions div.column > div.closeBlock {
            opacity: 1;
            filter: alpha(opacity=100); } }
        section.stepsBlock div.stepActions div.column > div.closeBlock span.icon svg {
          margin: 11px 0 0;
          width: 16px; }
          @media screen and (min-width: 768px) {
            section.stepsBlock div.stepActions div.column > div.closeBlock span.icon svg {
              margin: 16px 0 0; } }
      section.stepsBlock div.stepActions div.column > div span.label {
        color: #ffffff;
        font-size: 1em;
        font-weight: 900;
        padding: 9px 0 0 0;
        display: inline-block;
        vertical-align: top; }
        @media screen and (min-width: 768px) {
          section.stepsBlock div.stepActions div.column > div span.label {
            font-size: 1.25em;
            padding: 11px 0 0 0; } }
  section.stepsBlock div.stepsContent {
    padding: 4px 0 38px;
    display: none; }
    @media screen and (min-width: 768px) {
      section.stepsBlock div.stepsContent {
        display: block;
        padding: 24px 0 58px; } }
    section.stepsBlock div.stepsContent span.title {
      color: #ffffff;
      display: block;
      font-weight: 900;
      font-size: 1.3em;
      margin: 0 0 23px; }
      @media screen and (min-width: 768px) {
        section.stepsBlock div.stepsContent span.title {
          font-size: 1.75em;
          margin: 0 0 31px; } }
      @media screen and (min-width: 1024px) {
        section.stepsBlock div.stepsContent span.title {
          font-size: 2.5em;
          margin: 0 0 51px; } }
    section.stepsBlock div.stepsContent .column {
      position: relative; }
      section.stepsBlock div.stepsContent .column span.stepTitle {
        color: #ffffff;
        font-weight: 900;
        font-size: 1.1em;
        margin-bottom: 2px;
        display: block;
        vertical-align: middle; }
        @media screen and (min-width: 768px) {
          section.stepsBlock div.stepsContent .column span.stepTitle {
            font-size: 1.2em;
            margin-left: 85px; } }
        @media screen and (min-width: 1024px) {
          section.stepsBlock div.stepsContent .column span.stepTitle {
            font-size: 1.375em; } }
        section.stepsBlock div.stepsContent .column span.stepTitle svg {
          fill: #ffffff;
          vertical-align: middle; }
          @media screen and (min-width: 768px) {
            section.stepsBlock div.stepsContent .column span.stepTitle svg {
              position: absolute;
              left: 0;
              top: 4px;
              vertical-align: top; } }
          section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_search-steps {
            margin-left: 4px;
            margin-right: 10px;
            width: 46px; }
            @media screen and (min-width: 768px) {
              section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_search-steps {
                margin: 0;
                width: 56px;
                left: 11px; } }
          section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_location {
            width: 28px;
            margin-left: 10px;
            margin-right: 22px; }
            @media screen and (min-width: 768px) {
              section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_location {
                margin: 0;
                width: 38px;
                left: 24px; } }
          section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_contact {
            width: 51px;
            margin-right: 9px; }
            @media screen and (min-width: 768px) {
              section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_contact {
                margin: 0;
                width: 61px;
                left: 15px; } }
          section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_plane {
            width: 51px;
            margin-right: 9px; }
            @media screen and (min-width: 768px) {
              section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_plane {
                margin: 0;
                width: 61px;
                left: 15px; } }
          section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_lock {
            width: 51px;
            margin-right: 9px; }
            @media screen and (min-width: 768px) {
              section.stepsBlock div.stepsContent .column span.stepTitle svg.icon_lock {
                margin: -10px 0;
                width: 61px;
                left: 15px; } }
      section.stepsBlock div.stepsContent .column p {
        color: #ffffff;
        font-size: 0.875em;
        line-height: 1.75em; }
        @media screen and (min-width: 768px) {
          section.stepsBlock div.stepsContent .column p {
            margin-left: 85px; } }
  section.stepsBlock.active div.stepActions div.column > div.openBlock {
    opacity: 0;
    filter: alpha(opacity=0); }
    @media screen and (min-width: 768px) {
      section.stepsBlock.active div.stepActions div.column > div.openBlock {
        opacity: 1;
        filter: alpha(opacity=100); } }
  section.stepsBlock.active div.stepActions div.column > div.closeBlock {
    opacity: 1;
    filter: alpha(opacity=100); }
    @media screen and (min-width: 768px) {
      section.stepsBlock.active div.stepActions div.column > div.closeBlock {
        opacity: 0;
        filter: alpha(opacity=0); } }

section.volunteerSearch {
  padding: 35px 0 0; }
  @media screen and (min-width: 768px) {
    section.volunteerSearch {
      padding: 55px 0 0; } }
  @media screen and (min-width: 1024px) {
    section.volunteerSearch {
      padding: 60px 0 0; } }
  section.volunteerSearch.volunteerSearch--tools div.searchField div.field {
    width: 100% !important; }
  section.volunteerSearch.volunteerSearch--tools div.searchField input {
    border-radius: 4px; }
  section.volunteerSearch span.dropDown {
    display: none; }
  section.volunteerSearch div.searchField div.field {
    width: 100%; }
  section.volunteerSearch h1 {
    font-weight: 400;
    font-size: 1.35em;
    margin-bottom: 19px; }
    @media screen and (min-width: 768px) {
      section.volunteerSearch h1 {
        font-size: 1.7em;
        margin-bottom: 29px; } }
    @media screen and (min-width: 1024px) {
      section.volunteerSearch h1 {
        font-size: 2.5em;
        margin-bottom: 39px; } }
  section.volunteerSearch .column {
    position: relative; }

div.searchField {
  position: relative; }
  div.searchField svg.icon_searchloop {
    pointer-events: none;
    position: absolute;
    fill: #ebebeb;
    top: 15px;
    left: 13px;
    width: 20px; }
    @media screen and (min-width: 768px) {
      div.searchField svg.icon_searchloop {
        top: 17px;
        left: 23px;
        width: 34px; } }
  div.searchField input {
    background: #ffffff;
    border: 1px solid #cbcbcb;
    border-radius: 4px 4px 0 0;
    color: #414141;
    font-size: 1em;
    font-family: "Avenir", sans-serif;
    line-height: 1.3em;
    padding: 13px 47px 14px 43px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      div.searchField input {
        border-radius: 4px 0 0 4px;
        padding: 23px 77px 24px 73px; } }
    div.searchField input:focus {
      outline: 0; }
    div.searchField input::placeholder {
      color: #bfbfbf; }
    div.searchField input::-webkit-search-decoration, div.searchField input::-webkit-search-cancel-button, div.searchField input::-webkit-search-results-button, div.searchField input::-webkit-search-results-decoration {
      display: none; }
  div.searchField button.reset {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 16px;
    right: 15px; }
    @media screen and (min-width: 768px) {
      div.searchField button.reset {
        width: 19px;
        height: 19px;
        top: 25px;
        right: 29px; } }
    div.searchField button.reset svg {
      fill: #cbcbcb;
      position: absolute;
      top: 0;
      left: 0;
      width: 15px; }
      @media screen and (min-width: 768px) {
        div.searchField button.reset svg {
          width: 19px; } }
    div.searchField button.reset + input {
      display: none; }
  div.searchField button.search {
    background: #630460;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
    display: inline-block;
    margin-top: 15px;
    padding: 10px;
    text-decoration: none;
    display: inline-block;
    padding: 6px 10px 7px 9px;
    width: auto; }
    @media screen and (min-width: 768px) {
      div.searchField button.search {
        margin-top: 0;
        margin-left: 10px;
        padding: 11px 10px 10px 9px;
        width: calc(100% - 10px); } }
    @media screen and (min-width: 1024px) {
      div.searchField button.search {
        padding: 22px 10px 21px 9px; } }
    div.searchField button.search span {
      display: inline-block;
      font-size: 1em;
      font-weight: 900;
      vertical-align: middle; }
      @media screen and (min-width: 768px) {
        div.searchField button.search span {
          font-size: 1.25em; } }
      @media screen and (min-width: 1024px) {
        div.searchField button.search span {
          font-size: 1.375em; } }
  div.searchField div.field {
    display: inline-block;
    position: relative;
    width: 100%; }
    @media screen and (min-width: 768px) {
      div.searchField div.field {
        margin-right: -4px;
        width: calc(100% - 208px); } }
    @media screen and (min-width: 768px) {
      div.searchField div.field.fullField {
        width: calc(100% + 10px); }
        div.searchField div.field.fullField input {
          border-radius: 4px; } }
  @media screen and (min-width: 768px) {
    div.searchField .searchCheckbox {
      color: #848484;
      margin-top: 1em;
      position: absolute;
      right: 0; } }
  @media screen and (max-width: 767px) {
    div.searchField .searchCheckbox {
      color: #848484;
      margin-top: 1em;
      font-size: 14px; } }
  div.searchField .searchCheckbox input {
    width: auto;
    margin: 0;
    margin-bottom: 0.05em; }

section.rentalOverviewInstructionsDocuments h2 {
  font-size: 2.5em;
  font-weight: 400; }

section.rentalOverviewInstructionsDocuments h3 {
  margin-top: 60px;
  font-size: 24px;
  font-weight: 400; }

section.rentalOverviewInstructionsDocuments h4 {
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: 400; }

section.rentalOverviewInstructionsDocuments .textRow {
  margin-bottom: 40px; }

section.rentalOverviewInstructionsDocuments .listRow {
  margin-bottom: 40px; }

section.rentalOverviewInstructionsDocuments li {
  list-style: none;
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 24px; }
  section.rentalOverviewInstructionsDocuments li:last-child {
    margin-bottom: 0; }
  section.rentalOverviewInstructionsDocuments li a {
    color: #4a8dc0;
    text-decoration: none; }
    section.rentalOverviewInstructionsDocuments li a:hover, section.rentalOverviewInstructionsDocuments li a:hover {
      text-decoration: underline; }

section.rentalOverviewInstructionsDocuments .subjectBadge {
  width: 90px;
  height: 90px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #72c192;
  border-radius: 45px; }

section.rentalOverviewInstructionsDocuments .subjectImage {
  width: 114px;
  max-width: 100%;
  border-radius: 57px;
  overflow: hidden; }
  section.rentalOverviewInstructionsDocuments .subjectImage img {
    max-width: 100%; }

section.rentalOverviewInstructionsDocuments.documentsOverview h3 {
  margin-top: 0; }

section.rentalOverviewInstructionsDocuments.documentsOverview .listRow {
  margin-bottom: 70px; }
  @media screen and (min-width: 768px) {
    section.rentalOverviewInstructionsDocuments.documentsOverview .listRow .column:last-child {
      padding-left: 20px; } }

section.rentalOverviewInstructionsDocuments.documentsOverview p {
  font-size: 18px;
  line-height: 27px; }

section.rentalOverviewInstructionsDocuments.documentsOverview a {
  margin-top: 16px;
  font-size: 18px;
  line-height: 24px;
  color: #4a8dc0;
  text-decoration: none; }
  section.rentalOverviewInstructionsDocuments.documentsOverview a:focus, section.rentalOverviewInstructionsDocuments.documentsOverview a:hover {
    text-decoration: underline; }

section.documentsFooter {
  margin-bottom: 100px; }
  section.documentsFooter li {
    list-style: none;
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 24px; }
    section.documentsFooter li:last-child {
      margin-bottom: 0; }
  section.documentsFooter a {
    color: #4a8dc0;
    text-decoration: none; }
    section.documentsFooter a:focus, section.documentsFooter a:hover {
      text-decoration: underline; }

section.subjectListHero {
  margin-bottom: 76px; }
  section.subjectListHero .subjectImage {
    border-radius: 50%;
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      section.subjectListHero .subjectImage {
        margin-bottom: 0; } }
  section.subjectListHero h1 {
    font-weight: 400;
    font-size: 40px;
    line-height: 53px; }
  section.subjectListHero p {
    margin-top: 30px; }

section.subjectList {
  margin-bottom: 100px; }
  section.subjectList ~ section.subjectList {
    margin-bottom: 60px; }
  section.subjectList h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px; }
  section.subjectList li {
    list-style: none;
    padding-top: 18px;
    padding-bottom: 18px;
    background: #f5f5f5;
    border-radius: 6px;
    margin-bottom: 10px; }
    section.subjectList li:last-child {
      margin-bottom: 0; }
  section.subjectList a.name {
    color: #5b5b5b;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      section.subjectList a.name {
        padding-left: 20px;
        padding-right: 0;
        margin-bottom: 0; } }
    @media screen and (min-width: 1024px) {
      section.subjectList a.name {
        padding-left: 30px; } }
  section.subjectList a {
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    color: #4a8dc0;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      section.subjectList a {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0; } }
    @media screen and (min-width: 1024px) {
      section.subjectList a {
        /*padding-left: 30px;*/ } }
    section.subjectList a:focus, section.subjectList a:hover {
      text-decoration: underline; }

.documentBlock {
  padding: 30px;
  margin: 36px 0;
  background: #f5f5f5;
  border-radius: 6px; }
  .documentBlock .content {
    display: flex;
    align-items: center;
    margin: -10px;
    flex-wrap: wrap; }
    .documentBlock .content > * {
      margin: 10px; }
  .documentBlock .badge {
    flex: 0 0 auto; }
    .documentBlock .badge .fileBadge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 13.5px;
      width: 65px;
      height: 65px;
      border-radius: 32.5px;
      background: #72c192; }
      .documentBlock .badge .fileBadge img {
        max-height: 100%; }
  .documentBlock .text {
    max-width: 100%;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column; }
  .documentBlock .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500; }
    .documentBlock .title + span {
      margin-top: 10px; }
  .documentBlock .name {
    font-size: 14px;
    line-height: 19px;
    color: #949494;
    word-break: break-word; }
  .documentBlock .link a.button {
    background: #4a8dc0; }
    .documentBlock .link a.button svg.linkArrowDown {
      transform: scale(0.8); }
    .documentBlock .link a.button:hover svg.linkArrowDown {
      transform: translateY(3px) scale(0.8); }
    .documentBlock .link a.button:hover svg.iconPlay {
      transform: none; }

section.documentContent {
  padding-bottom: 90px; }
  section.documentContent a.category {
    display: inline-block;
    margin-bottom: 6px;
    color: #949494;
    font-size: 18px;
    line-height: 24px; }
  section.documentContent h1 {
    font-size: 28px;
    line-height: 33px;
    font-weight: 500;
    margin-bottom: 30px; }
    @media screen and (min-width: 768px) {
      section.documentContent h1 {
        font-size: 32px;
        line-height: 43px; } }
    @media screen and (min-width: 1024px) {
      section.documentContent h1 {
        font-size: 40px;
        line-height: 53px; } }
    section.documentContent h1 + p {
      font-size: 20px;
      line-height: 30px; }
  section.documentContent h2 {
    margin-bottom: 12px; }

div.suggest {
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none; }
  @media screen and (min-width: 768px) {
    div.suggest {
      top: 66px; } }
  div.suggest.active {
    opacity: 1;
    filter: alpha(opacity=100);
    pointer-events: all; }
  div.suggest ul {
    border: 1px solid #ebebeb;
    list-style: none;
    margin: 0;
    padding: 0; }
    div.suggest ul li {
      background: #ffffff;
      border-top: 1px solid #ebebeb;
      position: relative;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms; }
      div.suggest ul li:first-child {
        border: 0; }
      div.suggest ul li span.label {
        display: block;
        padding: 16px 73px 14px 15px;
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms; }
        @media screen and (min-width: 768px) {
          div.suggest ul li span.label {
            padding: 26px 73px 24px; } }
        div.suggest ul li span.label > span {
          font-weight: 900; }
      div.suggest ul li span.icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%); }
        @media screen and (max-width: 767px) {
          div.suggest ul li span.icon {
            left: auto;
            right: 10px;
            transform: translateY(-50%) scale(0.8); } }
        div.suggest ul li span.icon svg path {
          fill: #cbcbcb;
          -webkit-transition: all 300ms;
          -moz-transition: all 300ms;
          -ms-transition: all 300ms;
          -o-transition: all 300ms;
          transition: all 300ms; }
      div.suggest ul li span.type {
        color: #ebebeb;
        display: inline-block;
        position: absolute;
        right: 13px;
        top: 16px;
        text-align: right; }
        @media screen and (min-width: 768px) {
          div.suggest ul li span.type {
            right: 29px;
            top: 27px; } }
      div.suggest ul li:hover, div.suggest ul li.selected {
        background: #630460; }
        div.suggest ul li:hover span.label, div.suggest ul li.selected span.label {
          color: #ffffff; }
        div.suggest ul li:hover span.icon svg path, div.suggest ul li.selected span.icon svg path {
          fill: #ffffff; }

div.tabsNavigation, .productTabs div.productTabs-menu {
  display: none;
  margin-top: 68px;
  margin-bottom: 43px; }
  @media screen and (min-width: 768px) {
    div.tabsNavigation, .productTabs div.productTabs-menu {
      display: block; } }

section.mapView {
  display: none; }
  @media screen and (min-width: 768px) {
    section.mapView {
      display: block;
      visibility: hidden;
      position: absolute;
      pointer-events: none; } }
  section.mapView.show {
    display: none; }
    @media screen and (min-width: 768px) {
      section.mapView.show {
        display: block;
        visibility: visible;
        position: relative;
        pointer-events: all; } }
  section.mapView #map {
    height: 451px; }
    section.mapView #map div {
      font-family: "Avenir", sans-serif !important; }
  section.mapView div.infoBox {
    background: #ffffff;
    border-radius: 4px;
    min-height: 165px;
    width: 512px; }
    section.mapView div.infoBox:after {
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 10px solid #ffffff;
      content: '';
      display: block;
      position: absolute;
      bottom: -10px;
      left: 80px; }
    section.mapView div.infoBox > img {
      position: absolute !important;
      margin: 0 !important;
      right: 10px;
      top: 10px;
      height: 15px;
      width: 15px; }
    section.mapView div.infoBox div.infoContent {
      font-family: "Avenir", sans-serif;
      font-size: 16px; }
      section.mapView div.infoBox div.infoContent a {
        text-decoration: none; }
      section.mapView div.infoBox div.infoContent .available {
        display: block;
        font-weight: 400;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 28%;
        color: #ffffff;
        border-radius: 4px;
        padding: 2px 7px;
        background: #630460; }
        section.mapView div.infoBox div.infoContent .available a {
          font-weight: 400;
          color: #ffffff; }
      section.mapView div.infoBox div.infoContent div.image {
        padding: 2px;
        vertical-align: top;
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 10px; }
        @media screen and (min-width: 568px) {
          section.mapView div.infoBox div.infoContent div.image {
            display: inline-block; } }
        section.mapView div.infoBox div.infoContent div.image img {
          vertical-align: top; }
      section.mapView div.infoBox div.infoContent div.content {
        padding: 26px 35px 15px 26px;
        max-width: 313px;
        vertical-align: top; }
        @media screen and (min-width: 568px) {
          section.mapView div.infoBox div.infoContent div.content {
            display: inline-block; } }
        section.mapView div.infoBox div.infoContent div.content span.title {
          display: block;
          font-weight: 900;
          font-size: 1.125em;
          line-height: 24px;
          margin-bottom: 5px;
          padding-right: 10px; }
        section.mapView div.infoBox div.infoContent div.content span.summary {
          display: none;
          font-size: 0.875em;
          margin-bottom: 23px; }
        section.mapView div.infoBox div.infoContent div.content a.readMore {
          display: flex;
          align-items: center; }
          section.mapView div.infoBox div.infoContent div.content a.readMore span {
            color: #4a8dc0;
            display: inline-block;
            margin-right: 7px;
            font-weight: 700;
            width: auto;
            vertical-align: top; }
          section.mapView div.infoBox div.infoContent div.content a.readMore svg {
            display: inline-block;
            -webkit-transition: all 300ms;
            -moz-transition: all 300ms;
            -ms-transition: all 300ms;
            -o-transition: all 300ms;
            transition: all 300ms;
            fill: #4a8dc0;
            margin-top: 2px;
            vertical-align: top;
            width: 18px; }
          section.mapView div.infoBox div.infoContent div.content a.readMore:hover svg {
            -moz-transform: translateX(6px);
            -o-transform: translateX(6px);
            -ms-transform: translateX(6px);
            -webkit-transform: translateX(6px);
            transform: translateX(6px); }
      section.mapView div.infoBox div.infoContent span.type {
        display: none; }
  section.mapView .bottomBorder {
    border-bottom: 1.5px solid #e1e0e0; }
  section.mapView .legend {
    font: "Avenir", sans-serif;
    padding: 22px 0;
    color: #989898; }
    section.mapView .legend span.type {
      display: inline-flex;
      align-items: center; }
      section.mapView .legend span.type:before {
        content: '';
        display: inline-block;
        height: 18px;
        width: 18px;
        background: grey;
        border-radius: 9px;
        margin-right: 7px;
        position: relative;
        top: 3px; }
      section.mapView .legend span.type.groen:before {
        background: #72c192; }
      section.mapView .legend span.type.erfgoed:before {
        background: #72c192; }
      section.mapView .legend span.type.multiple:before {
        background: #4a8dc0; }

section.listView {
  padding: 0 0 20px;
  opacity: 1;
  zoom: 1;
  height: auto; }
  @media screen and (min-width: 768px) {
    section.listView {
      opacity: 0;
      zoom: 0;
      height: 0;
      overflow: hidden; } }
  @media screen and (min-width: 768px) {
    section.listView.show {
      opacity: 1;
      zoom: 1;
      height: auto;
      overflow: auto; } }
  section.listView div.resultCount {
    font-size: 1em;
    margin: 17px 0 12px; }
    @media screen and (min-width: 768px) {
      section.listView div.resultCount {
        font-size: 1.25em;
        margin: 0 0 17px; } }
  section.listView div.infoContent {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb; }
    section.listView div.infoContent a {
      text-decoration: none; }
    section.listView div.infoContent:first-child {
      border-top: 1px solid #ebebeb; }
    section.listView div.infoContent.paged {
      display: none; }
    @media screen and (min-width: 768px) {
      section.listView div.infoContent {
        flex-direction: row;
        align-items: stretch; } }
    section.listView div.infoContent div.image {
      vertical-align: top; }
      @media screen and (min-width: 768px) {
        section.listView div.infoContent div.image {
          display: inline-block; } }
      section.listView div.infoContent div.image a {
        display: block; }
      section.listView div.infoContent div.image img {
        /*border-radius: $borderRadius $borderRadius 0 0;*/
        vertical-align: top;
        width: 100%; }
        @media screen and (min-width: 768px) {
          section.listView div.infoContent div.image img {
            /*border-radius: $borderRadius 0 0 $borderRadius;*/
            width: auto; } }
    section.listView div.infoContent div.content {
      padding: 16px 25px 15px 16px;
      /*max-width: 313px;*/
      vertical-align: top;
      flex: 1;
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 768px) {
        section.listView div.infoContent div.content {
          padding: 15px 0 15px 26px;
          /*display: inline-block;*/ } }
      section.listView div.infoContent div.content span.title {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        font-weight: 900;
        font-size: 1.125em;
        line-height: 24px;
        margin-bottom: 5px; }
        @media screen and (min-width: 768px) {
          section.listView div.infoContent div.content span.title {
            padding-right: 10px;
            flex-direction: row; } }
      section.listView div.infoContent div.content .available {
        font-weight: 400;
        margin-top: 13px;
        color: #ffffff;
        border-radius: 4px;
        padding: 2px 7px;
        background: #630460;
        position: relative;
        top: -37%;
        right: 0; }
        section.listView div.infoContent div.content .available a {
          font-weight: 400;
          color: #ffffff; }
        @media screen and (min-width: 768px) {
          section.listView div.infoContent div.content .available {
            margin-left: auto;
            margin-top: 0;
            margin-bottom: -24px; } }
      section.listView div.infoContent div.content span.locationInfo {
        display: block;
        display: flex;
        align-items: center;
        color: #949494; }
        section.listView div.infoContent div.content span.locationInfo span {
          display: inline-block; }
        section.listView div.infoContent div.content span.locationInfo span.location {
          margin-right: 19px; }
        section.listView div.infoContent div.content span.locationInfo span.type {
          display: inline-flex;
          align-items: center; }
          section.listView div.infoContent div.content span.locationInfo span.type:before {
            content: '';
            display: inline-block;
            height: 16px;
            width: 16px;
            background: grey;
            border-radius: 8px;
            margin-right: 7px;
            position: relative; }
          section.listView div.infoContent div.content span.locationInfo span.type.groen:before {
            background: #72c192; }
          section.listView div.infoContent div.content span.locationInfo span.type.erfgoed:before {
            background: #72c192; }
          section.listView div.infoContent div.content span.locationInfo span.type.erfgoed_groen:before {
            background: #72c192; }
      section.listView div.infoContent div.content span.summary {
        display: block;
        font-size: 0.875em;
        margin-top: 13px;
        margin-bottom: 13px; }
        @media screen and (min-width: 768px) {
          section.listView div.infoContent div.content span.summary {
            margin-top: 12px;
            margin-bottom: 12px; } }
      section.listView div.infoContent div.content a.readMore {
        margin-top: auto; }
        section.listView div.infoContent div.content a.readMore span {
          color: #4a8dc0;
          display: inline-block;
          margin-right: 7px;
          font-weight: 700;
          vertical-align: top; }
        section.listView div.infoContent div.content a.readMore svg {
          display: inline-block;
          -webkit-transition: all 300ms;
          -moz-transition: all 300ms;
          -ms-transition: all 300ms;
          -o-transition: all 300ms;
          transition: all 300ms;
          fill: #4a8dc0;
          margin-top: 4px;
          vertical-align: top;
          width: 18px; }
        section.listView div.infoContent div.content a.readMore:hover svg {
          -moz-transform: translateX(6px);
          -o-transform: translateX(6px);
          -ms-transform: translateX(6px);
          -webkit-transform: translateX(6px);
          transform: translateX(6px); }
  section.listView.listView--tools {
    margin-top: 60px;
    overflow: visible !important;
    margin-bottom: 60px; }
    section.listView.listView--tools h2 {
      font-size: 1.35em;
      font-weight: 400;
      margin-bottom: 39px; }
      @media screen and (min-width: 768px) {
        section.listView.listView--tools h2 {
          font-size: 1.7em; } }
      @media screen and (min-width: 1024px) {
        section.listView.listView--tools h2 {
          font-size: 2.5em; } }
    section.listView.listView--tools .toolsOverviewItem {
      display: block;
      margin-bottom: 30px;
      text-decoration: none;
      font-weight: 900; }
      section.listView.listView--tools .toolsOverviewItem:hover {
        text-decoration: underline; }
      section.listView.listView--tools .toolsOverviewItem .toolsOverviewItem-image {
        position: relative;
        display: block;
        padding-bottom: 67%;
        margin-bottom: 10px;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        overflow: hidden; }
        section.listView.listView--tools .toolsOverviewItem .toolsOverviewItem-image img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto; }
      section.listView.listView--tools .toolsOverviewItem .toolsOverviewItem-text {
        font-size: 18px; }
  section.listView .legend {
    font: "Avenir", sans-serif;
    margin: 0 0 17px;
    color: #989898;
    position: absolute;
    right: 0;
    text-align: right; }
    @media screen and (min-width: 768px) {
      section.listView .legend {
        display: none; } }
    section.listView .legend .legendText {
      font-weight: 600; }
    section.listView .legend span.colors {
      margin-left: 9px; }
    section.listView .legend span.type {
      margin: 0 10px; }
    section.listView .legend:before {
      content: '';
      display: inline-block;
      height: 18px;
      width: 18px;
      background: grey;
      border-radius: 9px;
      margin-right: 7px;
      position: relative;
      top: 3px; }
    section.listView .legend.groen:before {
      background: #72c192; }
    section.listView .legend.erfgoed:before {
      background: #72c192; }
  section.listView .pager {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    margin-top: 25px;
    /*color: $colorPurple1;*/ }
    @media screen and (min-width: 768px) {
      section.listView .pager {
        margin-top: 50px; } }
    section.listView .pager > button.next {
      display: none;
      background: transparent;
      color: #5b5b5b;
      border: 1px solid #630460;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 16px;
      font-weight: 300; }
      @media screen and (min-width: 768px) {
        section.listView .pager > button.next {
          display: inline-block; } }
    section.listView .pager button {
      font-family: 'Avenir', sans-serif;
      cursor: pointer; }
    section.listView .pager .controlBlock {
      display: flex;
      align-items: center; }
      @media screen and (min-width: 768px) {
        section.listView .pager .controlBlock {
          position: absolute;
          right: 0; } }
    section.listView .pager .controls {
      display: inline-flex;
      align-items: center; }
      section.listView .pager .controls .previous, section.listView .pager .controls .next, section.listView .pager .controls .current {
        height: 48px;
        width: 48px; }
      section.listView .pager .controls .previous, section.listView .pager .controls .next {
        background: transparent;
        border: none;
        -webkit-transition: opacity 400ms;
        -moz-transition: opacity 400ms;
        -ms-transition: opacity 400ms;
        -o-transition: opacity 400ms;
        transition: opacity 400ms; }
        section.listView .pager .controls .previous[disabled], section.listView .pager .controls .next[disabled] {
          opacity: .4;
          pointer-events: none; }
      section.listView .pager .controls .current {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        /*background: $colorWhite;*/
        border-radius: 4px;
        border: 1px solid #ebebeb; }

section.feedback div.container {
  border-bottom: 2px solid #ebebeb;
  border-top: 2px solid #ebebeb; }

section.feedback div.row {
  padding: 56px 0 28px; }

section.feedback div.text {
  padding-right: 0; }
  @media screen and (min-width: 768px) {
    section.feedback div.text {
      padding-right: 50px; } }
  section.feedback div.text h2 {
    margin-bottom: 8px; }

section.feedback span.thumb {
  border: 2px solid #630460;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 22px 15px 0 0;
  padding: 16px 17px;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms; }
  section.feedback span.thumb svg {
    fill: #630460;
    height: 43px;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    vertical-align: top;
    width: 41px; }
  section.feedback span.thumb.down {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  section.feedback span.thumb:hover, section.feedback span.thumb.voted {
    background: #630460; }
    section.feedback span.thumb:hover svg, section.feedback span.thumb.voted svg {
      fill: #ffffff; }

section.feedback div.form {
  display: none;
  padding: 13px 0 15px; }
  section.feedback div.form textarea {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    color: #414141;
    font-size: 1em;
    font-family: "Avenir", sans-serif;
    line-height: 1.3em;
    height: 84px;
    padding: 16px 18px 15px;
    width: 100%;
    border: 2px solid #ebebeb;
    resize: none;
    vertical-align: top; }
    @media screen and (min-width: 768px) {
      section.feedback div.form textarea {
        height: 144px; } }
    section.feedback div.form textarea:focus {
      outline: none; }
  section.feedback div.form p {
    margin-bottom: 13px; }

span.dropDown {
  border: 1px solid #ebebeb;
  border-radius: 0 0 4px 4px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%; }
  @media screen and (min-width: 768px) {
    span.dropDown {
      border-radius: 0 4px 4px 0;
      width: 207px; } }
  span.dropDown select {
    background-color: #ffffff;
    color: #bfbfbf;
    border-radius: 0 0 4px 4px;
    font-size: 1em;
    font-family: "Avenir", sans-serif;
    padding: 14px 45px 13px 13px;
    border: 0;
    margin: 0;
    text-indent: 0.01px;
    text-overflow: '';
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%; }
    @media screen and (min-width: 768px) {
      span.dropDown select {
        border-radius: 0 4px 4px 0;
        padding: 24px 45px 23px 20px; } }
    span.dropDown select:focus {
      outline: 0; }
    span.dropDown select::-ms-expand {
      display: none; }
  span.dropDown svg {
    pointer-events: none;
    position: absolute;
    top: 21px;
    right: 14px;
    fill: #ebebeb;
    width: 8px; }
    @media screen and (min-width: 768px) {
      span.dropDown svg {
        top: 31px;
        right: 19px; } }

.tabsNavigation ul, .productTabs .productTabs-menu ul {
  border-bottom: 2px solid #f2f2f2;
  list-style: none;
  margin: 0;
  padding: 0; }
  .tabsNavigation ul li, .productTabs .productTabs-menu ul li {
    cursor: pointer;
    display: inline-block;
    padding: 13px 24px;
    position: relative; }
    .tabsNavigation ul li svg, .productTabs .productTabs-menu ul li svg {
      display: inline-block;
      fill: #5b5b5b;
      margin-right: 6px;
      width: 19px;
      vertical-align: middle; }
    .tabsNavigation ul li span, .productTabs .productTabs-menu ul li span {
      vertical-align: middle; }
    .tabsNavigation ul li a, .productTabs .productTabs-menu ul li a {
      display: block;
      text-decoration: none; }
    .tabsNavigation ul li.active:after, .productTabs .productTabs-menu ul li.active:after {
      background: #72c192;
      content: '';
      display: block;
      left: 2px;
      bottom: -1px;
      position: absolute;
      height: 3px;
      width: calc( 100% - 4px); }

.tabsNavigation.links ul li, .productTabs .links.productTabs-menu ul li {
  padding: 0; }
  .tabsNavigation.links ul li a, .productTabs .links.productTabs-menu ul li a {
    padding: 13px 24px; }

.readMoreBlocks {
  margin-top: 22px; }
  @media screen and (min-width: 768px) {
    .readMoreBlocks {
      margin-top: 42px; } }
  @media screen and (min-width: 1024px) {
    .readMoreBlocks {
      margin-top: 82px; } }
  .readMoreBlocks.volunteerSlider {
    margin-top: 0; }
    @media screen and (min-width: 768px) {
      .readMoreBlocks.volunteerSlider {
        margin-top: 0; } }
    @media screen and (min-width: 1024px) {
      .readMoreBlocks.volunteerSlider {
        margin-top: 0; } }
  .readMoreBlocks.volunteers {
    position: relative;
    margin: 17px 0 13px; }
    .readMoreBlocks.volunteers #vrijwilligers {
      position: absolute;
      top: -250px; }
    @media screen and (min-width: 768px) {
      .readMoreBlocks.volunteers {
        margin: 27px 0 23px; } }
    @media screen and (min-width: 1024px) {
      .readMoreBlocks.volunteers {
        margin: 57px 0 33px; } }
  .readMoreBlocks h2 {
    margin-bottom: 22px; }
    @media screen and (min-width: 768px) {
      .readMoreBlocks h2 {
        margin-bottom: 35px; } }
    @media screen and (min-width: 1024px) {
      .readMoreBlocks h2 {
        margin-bottom: 49px; } }
  .readMoreBlocks a {
    margin-bottom: 46px;
    text-decoration: none; }
    .readMoreBlocks a span.image {
      display: block;
      margin-bottom: 27px; }
      .readMoreBlocks a span.image img {
        border-radius: 4px;
        vertical-align: top; }
    .readMoreBlocks a span.title {
      display: block;
      font-size: 1.125em;
      font-weight: 900;
      margin-bottom: 3px;
      padding: 0 5.55556%; }
      @media screen and (min-width: 1024px) {
        .readMoreBlocks a span.title {
          padding: 0; } }
    .readMoreBlocks a span.summary {
      display: block;
      font-size: 0.875em;
      line-height: 1.75em;
      margin-bottom: 17px;
      padding: 0 5.55556%; }
      @media screen and (min-width: 1024px) {
        .readMoreBlocks a span.summary {
          padding: 0; } }
    .readMoreBlocks a span.readMore {
      margin: 0 5.55556%; }
      @media screen and (min-width: 1024px) {
        .readMoreBlocks a span.readMore {
          margin: 0; } }
      .readMoreBlocks a span.readMore span {
        color: #4a8dc0;
        display: inline-block;
        margin-right: 7px;
        font-weight: 700;
        vertical-align: top; }
      .readMoreBlocks a span.readMore svg {
        display: inline-block;
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms;
        fill: #4a8dc0;
        margin-top: 4px;
        vertical-align: top;
        width: 18px; }
        .readMoreBlocks a span.readMore svg ~ span {
          margin-right: 0;
          margin-left: 7px; }
    .readMoreBlocks a:hover span.readMore svg {
      -moz-transform: translateX(6px);
      -o-transform: translateX(6px);
      -ms-transform: translateX(6px);
      -webkit-transform: translateX(6px);
      transform: translateX(6px); }

.readMore {
  text-decoration: none; }
  .readMore span {
    color: #4a8dc0;
    display: inline-block;
    margin-right: 7px;
    font-weight: 700;
    vertical-align: top; }
  .readMore svg {
    display: inline-block;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    fill: #4a8dc0;
    margin-top: 4px;
    vertical-align: top;
    width: 18px; }
  .readMore:hover svg {
    -moz-transform: translateX(6px);
    -o-transform: translateX(6px);
    -ms-transform: translateX(6px);
    -webkit-transform: translateX(6px);
    transform: translateX(6px); }

.highlighted {
  padding: 23px 0 0; }
  @media screen and (min-width: 768px) {
    .highlighted {
      padding: 53px 0 0; } }
  @media screen and (min-width: 1024px) {
    .highlighted {
      padding: 93px 0 0; } }
  .highlighted h2 {
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .highlighted h2 {
        margin-bottom: 30px; } }
    @media screen and (min-width: 1024px) {
      .highlighted h2 {
        margin-bottom: 40px; } }
  .highlighted .column {
    margin-bottom: 18px; }
    @media screen and (min-width: 1024px) {
      .highlighted .column {
        margin-bottom: 0; } }
    .highlighted .column a {
      text-decoration: none; }
      .highlighted .column a span.image {
        display: inline-block;
        max-width: 133px;
        width: 100%;
        vertical-align: top; }
        .highlighted .column a span.image img {
          border-radius: 4px; }
      .highlighted .column a span.text {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 138px);
        padding: 7px 0 0 16px; }
        .highlighted .column a span.text span.title {
          display: block;
          font-size: 1.125em;
          font-weight: 900;
          line-height: 24px;
          margin-bottom: 10px; }
      .highlighted .column a:hover span.readMore svg {
        -moz-transform: translateX(6px);
        -o-transform: translateX(6px);
        -ms-transform: translateX(6px);
        -webkit-transform: translateX(6px);
        transform: translateX(6px); }

div.subjects div.categories a {
  margin-bottom: 25px;
  margin-top: 0;
  text-decoration: none; }
  @media screen and (min-width: 768px) {
    div.subjects div.categories a {
      margin-top: 0; } }
  @media screen and (min-width: 1024px) {
    div.subjects div.categories a {
      margin-top: 0; } }

.categories {
  padding: 11px 0 7px; }
  .categories a {
    margin-top: 18px;
    text-decoration: none; }
    @media screen and (min-width: 768px) {
      .categories a {
        margin-top: 36px; } }
    @media screen and (min-width: 1024px) {
      .categories a {
        margin-top: 48px; } }
    .categories a span.image {
      display: block;
      margin-bottom: 13px; }
      .categories a span.image img {
        border-radius: 4px; }
    .categories a span.title {
      font-size: 1.125em;
      font-weight: 900; }
    .categories a.hide {
      display: none; }

a.button.ctaVolunteers {
  background: transparent;
  width: 100%;
  text-align: center;
  border: 1px solid #CBCBCB;
  color: #630460; }
  a.button.ctaVolunteers p {
    margin: 0; }
    a.button.ctaVolunteers p svg {
      transform: translateX(10px) scale(0.7); }

section.accountOverview {
  margin-top: 21px;
  margin-bottom: 80px; }
  @media screen and (min-width: 768px) {
    section.accountOverview {
      margin-top: 36px; } }
  @media screen and (min-width: 1024px) {
    section.accountOverview {
      margin-top: 78px; } }
  section.accountOverview div.accountDetails {
    padding: 30px 0 0; }
    section.accountOverview div.accountDetails div.editUserInfo {
      display: none; }
    section.accountOverview div.accountDetails.edit div.userInfo {
      display: none; }
    section.accountOverview div.accountDetails.edit div.editUserInfo {
      display: block; }
  section.accountOverview h2 {
    margin-bottom: 24px; }
  section.accountOverview div.successMessage {
    background: #72c192;
    border-radius: 4px;
    display: none;
    padding: 15px 19px 18px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 27px; }
  section.accountOverview div.userInfo ul {
    list-style: none;
    margin-bottom: 35px; }
    section.accountOverview div.userInfo ul li {
      margin: 5px 0 15px; }
      section.accountOverview div.userInfo ul li span.label {
        display: block;
        font-weight: 700;
        margin-bottom: 7px; }
  section.accountOverview div.editUserInfo div.formRow {
    max-width: 531px; }

section.tabbedContent {
  margin-top: 21px;
  margin-bottom: 80px; }
  @media screen and (min-width: 768px) {
    section.tabbedContent {
      margin-top: 36px; } }
  @media screen and (min-width: 1024px) {
    section.tabbedContent {
      margin-top: 78px; } }
  section.tabbedContent h2 {
    margin-bottom: 24px; }
  section.tabbedContent .tabsNavigation, section.tabbedContent .productTabs .productTabs-menu, .productTabs section.tabbedContent .productTabs-menu {
    margin-bottom: 50px; }
  section.tabbedContent .noResults {
    display: none; }

div.pageTools {
  border-top: 4px dotted #282828;
  margin: 27px 0 0;
  padding: 15px 0 0; }
  div.pageTools div.pageShare div.dcShare {
    display: inline-block;
    margin-left: 13px; }

div.rainbow {
  height: 4px;
  width: 100%; }
  div.rainbow > div {
    background: #ecbf2f;
    width: calc(99.99% / 7);
    float: left;
    height: 4px; }
    div.rainbow > div:nth-child(2) {
      background: #ecd42f; }
    div.rainbow > div:nth-child(3) {
      background: #d4ec2f; }
    div.rainbow > div:nth-child(4) {
      background: #b5d926; }
    div.rainbow > div:nth-child(5) {
      background: #a4c172; }
    div.rainbow > div:nth-child(6) {
      background: #a7ce94; }
    div.rainbow > div:nth-child(7) {
      background: #72c192; }

section.doormat {
  padding-bottom: 23px; }
  @media screen and (min-width: 768px) {
    section.doormat {
      padding-bottom: 53px; } }
  section.doormat div.rainbow {
    margin-bottom: 37px; }
    @media screen and (min-width: 768px) {
      section.doormat div.rainbow {
        margin-bottom: 77px; } }
  section.doormat span.title {
    display: block;
    font-size: 1.875em;
    line-height: 0.98em;
    margin-bottom: 19px; }
  section.doormat p {
    font-size: 0.875em;
    line-height: 1.75em; }
  section.doormat div.logos {
    padding-top: 12px; }
    section.doormat div.logos img {
      display: inline-block;
      margin: 0 20px 0 0;
      vertical-align: top; }
      section.doormat div.logos img:last-child {
        margin-right: 0; }
      section.doormat div.logos img.groeneMotor {
        width: 201px; }
      section.doormat div.logos img.erfgoedhuis {
        width: 268px; }
    section.doormat div.logos a {
      display: inline-block;
      border: none;
      margin: 0 20px 0 0;
      text-decoration: none; }
      section.doormat div.logos a:last-child,
      section.doormat div.logos a img {
        margin-right: 0; }

div.overlay {
  background: rgba(0, 0, 0, 0.36);
  left: 0;
  top: 0;
  padding: 0 15px;
  position: fixed;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 99999;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -ms-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms; }
  div.overlay div.notLoggedIn {
    display: inline-block;
    background: #ffffff;
    max-width: 481px;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    width: calc(100% - 20px); }
    @media screen and (min-width: 568px) {
      div.overlay div.notLoggedIn {
        width: 100%; } }
    div.overlay div.notLoggedIn div.notice {
      padding: 26px; }
      @media screen and (min-width: 768px) {
        div.overlay div.notLoggedIn div.notice {
          padding: 46px; } }
      div.overlay div.notLoggedIn div.notice p {
        font-size: 1em; }
        @media screen and (min-width: 768px) {
          div.overlay div.notLoggedIn div.notice p {
            font-size: 1.375em;
            line-height: 1.05em; } }
      div.overlay div.notLoggedIn div.notice a.login {
        background: #630460;
        border-radius: 4px;
        display: inline-block;
        margin-top: 6px;
        padding: 12px 16px 10px 15px;
        font-size: 1em;
        text-decoration: none;
        vertical-align: middle; }
        @media screen and (min-width: 768px) {
          div.overlay div.notLoggedIn div.notice a.login {
            padding: 16px 20px 14px 19px;
            font-size: 1.25em; } }
        div.overlay div.notLoggedIn div.notice a.login span {
          color: #ffffff;
          display: inline-block;
          margin-right: 7px;
          font-weight: 700;
          vertical-align: top; }
        div.overlay div.notLoggedIn div.notice a.login svg {
          display: inline-block;
          -webkit-transition: all 300ms;
          -moz-transition: all 300ms;
          -ms-transition: all 300ms;
          -o-transition: all 300ms;
          transition: all 300ms;
          fill: #ffffff;
          margin-top: 7px;
          vertical-align: top;
          width: 14px;
          -webkit-backface-visibility: hidden; }
          @media screen and (min-width: 768px) {
            div.overlay div.notLoggedIn div.notice a.login svg {
              width: 18px; } }
        div.overlay div.notLoggedIn div.notice a.login:hover svg {
          -moz-transform: translateX(6px);
          -o-transform: translateX(6px);
          -ms-transform: translateX(6px);
          -webkit-transform: translateX(6px);
          transform: translateX(6px); }
      div.overlay div.notLoggedIn div.notice div.close {
        color: #630460;
        cursor: pointer;
        display: inline-block;
        padding: 9px 18px 7px;
        vertical-align: middle;
        font-size: 1em; }
        @media screen and (min-width: 768px) {
          div.overlay div.notLoggedIn div.notice div.close {
            padding: 16px 20px 14px 19px;
            font-size: 1.25em; } }

body.showNotice div.overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  pointer-events: all; }

body.loginPage header {
  border-bottom: none;
  padding: 10px 0;
  position: relative; }
  @media screen and (min-width: 768px) {
    body.loginPage header {
      padding: 0 0 100px; } }
  body.loginPage header a.logo {
    position: relative;
    top: 0;
    vertical-align: top;
    left: 15px; }
    @media screen and (min-width: 768px) {
      body.loginPage header a.logo {
        left: 0;
        top: 24px; } }
  body.loginPage header a.back {
    display: inline-block;
    margin: 27px 0 0 59px;
    text-decoration: none; }
    body.loginPage header a.back span {
      color: #630460;
      display: inline-block; }
    body.loginPage header a.back svg {
      fill: #630460; }

section.category {
  padding: 32px 0 19px; }
  @media screen and (min-width: 768px) {
    section.category {
      padding: 52px 0 29px; } }
  @media screen and (min-width: 1024px) {
    section.category {
      padding: 5px 0 49px; } }
  section.category.category--tools h2 {
    padding-bottom: 40px;
    border-bottom: 1px solid #ebebeb; }
  section.category.category--toolChoose {
    padding-top: 0; }
  section.category h2 {
    font-size: 1.5em;
    margin-bottom: 17px; }
    @media screen and (min-width: 768px) {
      section.category h2 {
        font-size: 1.875em;
        margin-bottom: 25px; } }
    @media screen and (min-width: 1024px) {
      section.category h2 {
        font-size: 2.5em;
        margin-bottom: 39px; } }
  section.category div.readMoreBlocks {
    margin-top: 0; }
    @media screen and (min-width: 768px) {
      section.category div.readMoreBlocks {
        margin-top: 0; } }
    @media screen and (min-width: 1024px) {
      section.category div.readMoreBlocks {
        margin-top: 0; } }

div.subCategory {
  margin-bottom: 40px; }
  div.subCategory h3 {
    margin-bottom: 30px;
    font-weight: 900;
    font-size: 30px; }
  div.subCategory .subCategory-item {
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    border-radius: 4px; }
    @media screen and (min-width: 768px) {
      div.subCategory .subCategory-item {
        padding: 20px; } }
    @media screen and (min-width: 1024px) {
      div.subCategory .subCategory-item {
        padding: 30px; } }
    div.subCategory .subCategory-item .subCategory-item-order .addToCart {
      width: 100%;
      align-items: flex-end; }
      div.subCategory .subCategory-item .subCategory-item-order .addToCart a.button:hover svg {
        transform: none; }
  div.subCategory .hidden {
    display: none; }
  div.subCategory .show {
    display: block !important; }
  div.subCategory .button {
    -webkit-transition: background-color 300ms;
    -moz-transition: background-color 300ms;
    -ms-transition: background-color 300ms;
    -o-transition: background-color 300ms;
    transition: background-color 300ms;
    background-color: #ecbf2f; }
    div.subCategory .button:hover {
      background-color: #bd9411; }
  div.subCategory .image,
  div.subCategory .title {
    display: block; }
  div.subCategory .image {
    max-height: 150px;
    margin-bottom: 10px;
    text-align: center; }
    div.subCategory .image img {
      max-height: 150px; }
  div.subCategory .title {
    font-size: 16px;
    font-weight: 900;
    min-height: 38px; }
  div.subCategory .subCategory-item-link {
    text-decoration: none; }
    div.subCategory .subCategory-item-link .stock {
      color: #aaaaaa; }
  div.subCategory .subCategory-item-link:hover + .subCategory-item-overlay,
  div.subCategory .subCategory-item-overlay:hover {
    display: block; }
  div.subCategory .subCategory-item-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 33%;
    left: 0;
    display: none;
    background-color: rgba(255, 255, 255, 0.75); }
    div.subCategory .subCategory-item-overlay .button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 17px 20px 16px 19px;
      border-radius: 3px;
      color: #ffffff; }
  div.subCategory .subCategory-item-order {
    margin-top: 60px; }
    div.subCategory .subCategory-item-order .hidden {
      float: left;
      width: calc(100% - 30px); }
      @media screen and (min-width: 768px) {
        div.subCategory .subCategory-item-order .hidden {
          width: calc(100% - 40px); } }
      @media screen and (min-width: 1024px) {
        div.subCategory .subCategory-item-order .hidden {
          width: calc(100% - 50px); } }

div.addToCart {
    bottom: 0;
    z-index: 1;
    left: 0;
    display: flex;
    position: relative;
    padding: 0;
    background: transparent;
    flex-direction: column;
    width: 160px;
}
  /*input {
		display: table;
		width: 49px;
		height: 25px;
		margin: 0 auto;
		border-radius: 4px;
		border: 1px solid #ecbf2f;
		text-align: center;
		-moz-appearance: textfield;

		&.invalid {
			border: 1px solid #ff0000;
		}

		@include min-screen($tabletBreakpoint) {
			height: 33px;
		}

		@include min-screen($desktopBreakpoint) {
			height: 43px;
		}
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}

	.button {
		float: right;
		line-height: .8;
		text-align: center;

		&.addToCart-minus {
			float: left;
		}


		&.button-addtocart {
			float: left;
			width: 100%;
			margin-top: 15px;
		}

		&.addToCart-cart {
			padding: 6px 10px 7px 9px;

			@include min-screen($tabletBreakpoint) {
				padding: 10px 12px 10px 9px;
			}

			svg {
				height: 24px !important;
				width: 26px !important;
			}
		}
	}*/
  @media screen and (min-width: 768px) {
    div.addToCart {
      position: relative;
      padding: 0;
      background: transparent;
      flex-direction: column;
      width: 160px; } }
  div.addToCart .inner {
    display: flex; }
    @media screen and (min-width: 768px) {
      div.addToCart .inner {
        margin-bottom: 10px; } }
    div.addToCart .inner .addToCart-seperator {
      display: flex;
      flex: 2 2 auto; }
      div.addToCart .inner .addToCart-seperator > * {
        flex: 1 1 auto;
        height: 46px; }
      div.addToCart .inner .addToCart-seperator a.addToCart-minus {
        margin-right: 8px; }
      div.addToCart .inner .addToCart-seperator input {
        width: 46px;
        text-align: center;
        border: 1px solid #ecd42f;
        border-radius: 5px; }
      div.addToCart .inner .addToCart-seperator input[type=number]::-webkit-inner-spin-button,
      div.addToCart .inner .addToCart-seperator input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; }
    div.addToCart .inner a.addToCart-plus {
      margin-left: 8px;
      margin-right: 8px; }
      @media screen and (min-width: 1024px) {
        div.addToCart .inner a.addToCart-plus {
          margin-right: 0; } }
    div.addToCart .inner > a {
      flex: 1 1 auto;
      height: 46px; }
    div.addToCart .inner a {
      padding: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      font-size: 1.5em; }
  div.addToCart > button {
    height: 46px;
    flex: 1 1 auto;
    padding: 5px; }
    div.addToCart > button span {
      margin-right: 0; }
  div.addToCart .stock, div.addToCart .field-validation-error {
    margin-left: 10px;
    display: inline-flex;
    align-items: center; }
    @media screen and (min-width: 768px) {
      div.addToCart .stock, div.addToCart .field-validation-error {
        margin-left: 0;
        margin-top: 10px; } }

section.introTwoColumns {
  padding: 120px 0 24px; }
  @media screen and (min-width: 768px) {
    section.introTwoColumns {
      padding: 120px 0 37px; } }
  @media screen and (min-width: 1024px) {
    section.introTwoColumns {
      padding: 174px 0 67px; } }
  section.introTwoColumns h2 {
    font-size: 1.306em;
    margin-bottom: 14px; }
    @media screen and (min-width: 768px) {
      section.introTwoColumns h2 {
        font-size: 1.675em;
        margin-bottom: 25px; } }
    @media screen and (min-width: 1024px) {
      section.introTwoColumns h2 {
        font-size: 1.906em;
        margin-bottom: 37px; } }

section.moreInfoBlocks h2 {
  font-size: 1.306em;
  margin-bottom: 17px; }
  @media screen and (min-width: 768px) {
    section.moreInfoBlocks h2 {
      font-size: 1.675em;
      margin-bottom: 27px; } }
  @media screen and (min-width: 1024px) {
    section.moreInfoBlocks h2 {
      font-size: 1.906em;
      margin-bottom: 42px; } }

section.moreInfoBlocks .row {
  padding: 20px 0 25px;
  position: relative; }
  @media screen and (min-width: 768px) {
    section.moreInfoBlocks .row {
      padding: 40px 0 55px; } }
  section.moreInfoBlocks .row:first-child {
    padding: 0; }
    @media screen and (min-width: 768px) {
      section.moreInfoBlocks .row:first-child .column:last-child {
        padding: 0; } }
    section.moreInfoBlocks .row:first-child .column:last-child h2 {
      margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      section.moreInfoBlocks .row:first-child .column:first-child {
        padding: 0; } }
    section.moreInfoBlocks .row:first-child .column:first-child h2 {
      margin-bottom: 0; }
  @media screen and (min-width: 768px) {
    section.moreInfoBlocks .row.left .column:first-child {
      padding-left: 20px;
      padding-right: 0; } }
  @media screen and (min-width: 768px) {
    section.moreInfoBlocks .row.left .column:last-child {
      float: left;
      margin-right: 0;
      padding-right: 20px;
      padding-left: 0; } }
  section.moreInfoBlocks .row:first-child:before {
    display: none; }
  section.moreInfoBlocks .row:before {
    background: #ebebeb;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0; }

section.moreInfoBlocks .column h3 {
  font-size: 1.106em;
  font-weight: 400; }
  @media screen and (min-width: 768px) {
    section.moreInfoBlocks .column h3 {
      font-size: 1.506em; } }
  @media screen and (min-width: 1024px) {
    section.moreInfoBlocks .column h3 {
      font-size: 1.906em; } }

section.moreInfoBlocks .column p {
  font-size: 0.875em;
  line-height: 1.78em; }

@media screen and (min-width: 768px) {
  section.moreInfoBlocks .column:first-child {
    padding-right: 20px;
    padding-left: 0; } }

section.moreInfoBlocks .column:last-child {
  margin-top: 19px; }
  @media screen and (min-width: 768px) {
    section.moreInfoBlocks .column:last-child {
      margin-top: 0;
      padding-left: 20px;
      padding-right: 0; } }

section.moreInfoBlocks .column img {
  border-radius: 4px;
  margin-top: 8px; }

section.loginContent {
  margin-bottom: 30px; }
  section.loginContent div.row {
    border-top: 1px solid #ebebeb;
    padding-bottom: 14px;
    padding-top: 19px; }
    @media screen and (min-width: 768px) {
      section.loginContent div.row {
        border: 1px solid #ebebeb;
        border-radius: 4px;
        padding-bottom: 34px;
        padding-top: 49px; } }
    section.loginContent div.row .formRow {
      max-width: 352px; }
  section.loginContent div.leftColHolder {
    padding: 0; }
    @media screen and (min-width: 768px) {
      section.loginContent div.leftColHolder {
        padding: 0 35px 0 0; } }
    @media screen and (min-width: 1024px) {
      section.loginContent div.leftColHolder {
        padding: 0 135px 0 57px; } }

.formRow {
  margin: 5px 0 15px; }
  .formRow.right {
    text-align: right; }
  .formRow a {
    margin: 2px 0 0 0;
    display: inline-block;
    text-decoration: none; }
    .formRow a span {
      color: #4a8dc0;
      display: inline-block;
      margin-left: 7px;
      font-weight: 700;
      vertical-align: top; }
    .formRow a svg {
      display: inline-block;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      fill: #4a8dc0;
      margin-top: 4px;
      vertical-align: top;
      width: 18px; }
    .formRow a:hover svg {
      -moz-transform: translateX(6px);
      -o-transform: translateX(6px);
      -ms-transform: translateX(6px);
      -webkit-transform: translateX(6px);
      transform: translateX(6px); }
  .formRow label {
    color: #5b5b5b;
    display: block;
    font-weight: 700;
    margin-bottom: 7px;
    margin-top: 20px;
   }
  .formRow input[type="text"],
  .formRow input[type="tel"],
  .formRow input[type="password"],
  .formRow textarea {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    color: #414141;
    font-size: 1em;
    font-family: "Avenir", sans-serif;
    line-height: 1.3em;
    padding: 16px 47px 15px 18px;
    width: 100%; }
    .formRow input[type="text"]:focus,
    .formRow input[type="password"]:focus,
    .formRow textarea:focus {
      outline: 0; }
    .formRow input[type="text"]::placeholder,
    .formRow input[type="password"]::placeholder,
    .formRow textarea::placeholder {
      color: #bfbfbf; }
    .formRow input[type="text"]::-webkit-search-decoration, .formRow input[type="text"]::-webkit-search-cancel-button, .formRow input[type="text"]::-webkit-search-results-button, .formRow input[type="text"]::-webkit-search-results-decoration,
    .formRow input[type="password"]::-webkit-search-decoration,
    .formRow input[type="password"]::-webkit-search-cancel-button,
    .formRow input[type="password"]::-webkit-search-results-button,
    .formRow input[type="password"]::-webkit-search-results-decoration,
    .formRow textarea::-webkit-search-decoration,
    .formRow textarea::-webkit-search-cancel-button,
    .formRow textarea::-webkit-search-results-button,
    .formRow textarea::-webkit-search-results-decoration {
      display: none; }
  .formRow textarea {
    min-height: 300px; }
  .formRow span.field-validation-error {
    color: #ff0000;
    display: block;
    padding: 4px 10px 0px; }
  .formRow button.arrow {
    margin-top: 34px; }
  .formRow.tiny button.arrow {
    margin-top: 5px; }

footer {
  background: #72c192;
  color: #ffffff;
  padding: 36px 0 34px; }
  @media screen and (min-width: 768px) {
    footer {
      padding: 96px 0 84px; } }
  footer span.title {
    font-weight: 900;
    font-size: 1.675em;
    display: block;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      footer span.title {
        margin-bottom: 18px;
        font-size: 1.875em; } }
  footer p {
    font-size: 1.15em;
    line-height: 1.35em;
    margin-bottom: 1px; }
    @media screen and (min-width: 768px) {
      footer p {
        font-size: 1.25em;
        line-height: 1.5em; } }
    footer p strong {
      font-weight: 900; }
  footer ul.navigation {
    list-style: none;
    margin: 0 0 35px;
    padding: 0; }
    footer ul.navigation li {
      display: block; }
      footer ul.navigation li a {
        color: #ffffff;
        font-size: 1.15em;
        line-height: 1.35em;
        text-decoration: none;
        -webkit-transition: color 300ms;
        -moz-transition: color 300ms;
        -ms-transition: color 300ms;
        -o-transition: color 300ms;
        transition: color 300ms; }
        @media screen and (min-width: 768px) {
          footer ul.navigation li a {
            font-size: 1.25em;
            line-height: 1.5em; } }
        footer ul.navigation li a svg {
          display: inline-block;
          fill: #ffffff;
          margin-right: 16px;
          margin-left: 0;
          width: 18px;
          vertical-align: middle;
          -webkit-transition: margin 300ms, fill 300ms;
          -moz-transition: margin 300ms, fill 300ms;
          -ms-transition: margin 300ms, fill 300ms;
          -o-transition: margin 300ms, fill 300ms;
          transition: margin 300ms, fill 300ms; }
        footer ul.navigation li a span {
          display: inline-block;
          max-width: calc(100% - 40px);
          vertical-align: middle; }
        footer ul.navigation li a:hover {
          color: #5b5b5b; }
          footer ul.navigation li a:hover svg {
            margin-right: 10px;
            margin-left: 6px;
            fill: #5b5b5b; }
  footer ul.socials {
    list-style: none;
    padding: 10px 0 0;
    margin: 0 0 35px; }
    @media screen and (min-width: 768px) {
      footer ul.socials {
        margin: 0; } }
    footer ul.socials li {
      display: inline-block;
      margin-right: 6px; }
      footer ul.socials li a svg {
        fill: #ffffff;
        vertical-align: top;
        width: 40px;
        -webkit-transition: fill 300ms;
        -moz-transition: fill 300ms;
        -ms-transition: fill 300ms;
        -o-transition: fill 300ms;
        transition: fill 300ms; }
      footer ul.socials li a:hover svg {
        fill: #5b5b5b; }
  footer div.address {
    display: inline-block;
    max-width: 236px;
    width: 100%; }
  footer div.contactInfo {
    display: inline-block;
    max-width: 360px;
    width: 100%; }
    footer div.contactInfo a {
      color: #ffffff;
      font-weight: 900;
      text-decoration: none;
      -webkit-transition: color 300ms;
      -moz-transition: color 300ms;
      -ms-transition: color 300ms;
      -o-transition: color 300ms;
      transition: color 300ms; }
      footer div.contactInfo a span {
        opacity: 1;
        filter: alpha(opacity=100); }
      footer div.contactInfo a svg {
        fill: #ffffff;
        -webkit-transition: fill 300ms;
        -moz-transition: fill 300ms;
        -ms-transition: fill 300ms;
        -o-transition: fill 300ms;
        transition: fill 300ms; }
      footer div.contactInfo a:hover {
        color: #5b5b5b; }
        footer div.contactInfo a:hover svg {
          fill: #5b5b5b; }
    footer div.contactInfo a.tel svg {
      margin-left: 3px;
      width: 16px; }
    footer div.contactInfo a.mail svg {
      width: 20px; }
    footer div.contactInfo a.mail span {
      text-decoration: underline; }

section.contact {
  padding: 60px 0 37px; }
  @media screen and (min-width: 768px) {
    section.contact .column.form {
      padding-right: 120px; } }
  section.contact div.address {
    display: inline-block;
    margin-bottom: 20px;
    max-width: 236px;
    width: 100%; }
    section.contact div.address p {
      font-size: 1.15em;
      line-height: 1.35em;
      margin-bottom: 1px; }
      @media screen and (min-width: 768px) {
        section.contact div.address p {
          font-size: 1.25em;
          line-height: 1.5em; } }
      section.contact div.address p strong {
        font-weight: 900; }
  section.contact div.contactInfo {
    display: inline-block;
    max-width: 360px;
    width: 100%; }
    section.contact div.contactInfo p {
      font-size: 1.15em;
      line-height: 1.35em;
      margin-bottom: 1px; }
      @media screen and (min-width: 768px) {
        section.contact div.contactInfo p {
          font-size: 1.25em;
          line-height: 1.5em; } }
      section.contact div.contactInfo p strong {
        font-weight: 900; }
    section.contact div.contactInfo a {
      color: #5b5b5b;
      font-weight: 900;
      text-decoration: none;
      -webkit-transition: color 300ms;
      -moz-transition: color 300ms;
      -ms-transition: color 300ms;
      -o-transition: color 300ms;
      transition: color 300ms; }
      section.contact div.contactInfo a span {
        opacity: 1;
        filter: alpha(opacity=100); }
      section.contact div.contactInfo a svg {
        fill: #5b5b5b;
        -webkit-transition: fill 300ms;
        -moz-transition: fill 300ms;
        -ms-transition: fill 300ms;
        -o-transition: fill 300ms;
        transition: fill 300ms; }
      section.contact div.contactInfo a:hover {
        opacity: 0.8;
        filter: alpha(opacity=80); }
    section.contact div.contactInfo a.tel svg {
      margin-left: 3px;
      width: 16px; }
    section.contact div.contactInfo a.mail svg {
      width: 20px; }
    section.contact div.contactInfo a.mail span {
      color: #4a8dc0;
      text-decoration: underline; }

section.agendaBlocks {
  padding: 32px 0 24px; }
  @media screen and (min-width: 768px) {
    section.agendaBlocks {
      padding: 82px 0 54px; } }

.agendaBlocks {
  margin-top: 68px; }
  .agendaBlocks .column {
    margin-bottom: 25px; }
    .agendaBlocks .column.readMoreHidden {
      display: none; }
    .agendaBlocks .column.hide {
      display: none; }
  .agendaBlocks a {
    text-decoration: none; }
    .agendaBlocks a .image {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      border-radius: 5px;
      overflow: hidden; }
      @media screen and (min-width: 1024px) {
        .agendaBlocks a .image {
          width: 225px;
          margin-right: -4px; } }
    .agendaBlocks a .content {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin-top: 25px; }
      @media screen and (min-width: 1024px) {
        .agendaBlocks a .content {
          width: calc(100% - 225px);
          margin-right: -4px;
          padding-left: 40px;
          margin-top: 3px; } }
      .agendaBlocks a .content .date {
        font-weight: 700;
        background-color: #f5f5f5;
        display: inline-block;
        padding: 7px 10px;
        margin-bottom: 15px; }
      .agendaBlocks a .content .location {
        font-size: 14px;
        display: block;
        margin-top: -12px;
        margin-bottom: 10px; }
      .agendaBlocks a .content .title {
        display: block;
        font-weight: 700;
        font-size: 1.1em;
        margin-bottom: 10px; }
      .agendaBlocks a .content .summary {
        display: block;
        margin-bottom: 10px; }
    .agendaBlocks a:hover .readMore svg {
      -moz-transform: translateX(6px);
      -o-transform: translateX(6px);
      -ms-transform: translateX(6px);
      -webkit-transform: translateX(6px);
      transform: translateX(6px); }
  .agendaBlocks .readMoreButton {
    display: none;
    text-align: center;
    margin-top: 30px; }
    .agendaBlocks .readMoreButton.visible {
      display: block; }
    .agendaBlocks .readMoreButton button {
      display: inline-block;
      padding: 17px;
      cursor: pointer;
      background-color: #630460;
      color: #fff;
      border: 0;
      outline: 0;
      font-family: "Avenir", sans-serif;
      border-radius: 5px; }
      .agendaBlocks .readMoreButton button span {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
        font-weight: 700;
        font-size: 1.5em; }
      .agendaBlocks .readMoreButton button svg {
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        fill: #fff; }

.contour .contourPage {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px; }
  .contour .contourPage:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -60px;
    height: 100%;
    width: 100%;
    width: calc(100% + 120px);
    background: #F5F5F5;
    z-index: 0; }
  .contour .contourPage fieldset {
    padding: 0;
    margin: 0; }
  .contour .contourPage .contourPageName {
    position: relative;
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
    color: #630460; }
  .contour .contourPage .contourField {
    position: relative;
    margin-bottom: 20px;
    /*padding-right: 50px;*/ }
    .contour .contourPage .contourField label {
      color: #5b5b5b;
      display: block;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 7px; }
      .contour .contourPage .contourField label:hover {
        cursor: text; }
      .contour .contourPage .contourField label + span.help-block + div {
        position: relative; }
    .contour .contourPage .contourField span.field-validation-error {
      display: block;
      color: red; }
    .contour .contourPage .contourField span.field-validation-error {
      display: block;
      color: red; }
    .contour .contourPage .contourField span.help {
      background: #ffffff;
      border: 1px solid #CBCBCB;
      border-radius: 50%;
      color: #630460;
      content: '?';
      display: block;
      cursor: pointer;
      text-align: center;
      right: 0;
      top: -10px;
      -moz-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      height: 29px;
      padding-top: 4px;
      position: absolute;
      width: 29px;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms; }
      @media screen and (min-width: 768px) {
        .contour .contourPage .contourField span.help {
          right: -44px;
          top: 50%;
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%); } }
      .contour .contourPage .contourField span.help:hover, .contour .contourPage .contourField span.help.active {
        background: #CBCBCB;
        color: #000000; }
    .contour .contourPage .contourField .help-block {
      background: #CBCBCB;
      padding: 15px 20px 13px;
      position: absolute;
      width: 100%;
      display: none;
      top: -17px;
      right: 0;
      -moz-transform: translateY(47px);
      -o-transform: translateY(47px);
      -ms-transform: translateY(47px);
      -webkit-transform: translateY(47px);
      transform: translateY(47px);
      z-index: 1; }
      @media screen and (min-width: 768px) {
        .contour .contourPage .contourField .help-block {
          top: 50%;
          right: -45px;
          -moz-transform: translateY(47px);
          -o-transform: translateY(47px);
          -ms-transform: translateY(47px);
          -webkit-transform: translateY(47px);
          transform: translateY(47px); } }
      .contour .contourPage .contourField .help-block:after {
        background: transparent;
        width: 9px;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-bottom: 9px solid #CBCBCB;
        content: '';
        padding: 0;
        position: absolute;
        top: -9px;
        right: 6px; }
      .contour .contourPage .contourField .help-block span {
        color: #000000;
        font-size: 1em;
        line-height: 1.6em; }
      .contour .contourPage .contourField .help-block.show {
        display: block; }
    .contour .contourPage .contourField input[type=text], .contour .contourPage .contourField input[type=email], .contour .contourPage .contourField input[type=password], .contour .contourPage .contourField input[type=number], .contour .contourPage .contourField textarea, .contour .contourPage .contourField select {
      border: 1px solid #CBCBCB;
      border-radius: 4px;
      padding: 7px 12px;
      color: #5b5b5b;
      font-family: "Avenir", sans-serif;
      font-weight: 500;
      font-size: 1.1em;
      max-width: 100% !important;
      width: 100%; }
      @media screen and (min-width: 768px) {
        .contour .contourPage .contourField input[type=text], .contour .contourPage .contourField input[type=email], .contour .contourPage .contourField input[type=password], .contour .contourPage .contourField input[type=number], .contour .contourPage .contourField textarea, .contour .contourPage .contourField select {
          padding: 15px 20px; } }
      .contour .contourPage .contourField input[type=text]::placeholder, .contour .contourPage .contourField input[type=email]::placeholder, .contour .contourPage .contourField input[type=password]::placeholder, .contour .contourPage .contourField input[type=number]::placeholder, .contour .contourPage .contourField textarea::placeholder, .contour .contourPage .contourField select::placeholder {
        color: #bfbfbf; }
      .contour .contourPage .contourField input[type=text]:focus, .contour .contourPage .contourField input[type=email]:focus, .contour .contourPage .contourField input[type=password]:focus, .contour .contourPage .contourField input[type=number]:focus, .contour .contourPage .contourField textarea:focus, .contour .contourPage .contourField select:focus {
        outline: 0;
        border: 1px solid #aaaaaa; }
    .contour .contourPage .contourField label .contourIndicator {
      color: #cc0000; }
    .contour .contourPage .contourField.fileupload div input[type=file], .contour .contourPage .contourField.perplexfileupload div input[type=file], .contour .contourPage .contourField.perpleximageupload div input[type=file] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1; }
    .contour .contourPage .contourField.fileupload label, .contour .contourPage .contourField.perplexfileupload label, .contour .contourPage .contourField.perpleximageupload label {
      cursor: pointer;
      display: flex; }
      .contour .contourPage .contourField.fileupload label .text, .contour .contourPage .contourField.perplexfileupload label .text, .contour .contourPage .contourField.perpleximageupload label .text {
        display: inline-block;
        align-self: center; }
      .contour .contourPage .contourField.fileupload label .button, .contour .contourPage .contourField.perplexfileupload label .button, .contour .contourPage .contourField.perpleximageupload label .button {
        display: inline-block;
        vertical-align: middle;
        color: #4a8dc0;
        border: 1px solid #CBCBCB;
        padding: 18px 22px 18px 50px;
        background: #ffffff;
        border-radius: 3px;
        position: relative;
        margin-left: auto; }
        .contour .contourPage .contourField.fileupload label .button:before, .contour .contourPage .contourField.perplexfileupload label .button:before, .contour .contourPage .contourField.perpleximageupload label .button:before {
          content: "";
          background: url("/img/arrow-fileupload.png") no-repeat center center;
          background-size: 100% 100%;
          width: 18px;
          height: 12px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 22px;
          margin: auto; }
    .contour .contourPage .contourField.fileupload .help, .contour .contourPage .contourField.perplexfileupload .help, .contour .contourPage .contourField.perpleximageupload .help {
      top: -40px; }
    .contour .contourPage .contourField.multiplechoice label, .contour .contourPage .contourField.radiobutton label, .contour .contourPage .contourField.singlechoice label {
      display: inline-block;
      vertical-align: top;
      margin-right: -4px;
      width: 100%; }
      @media screen and (min-width: 568px) {
        .contour .contourPage .contourField.multiplechoice label, .contour .contourPage .contourField.radiobutton label, .contour .contourPage .contourField.singlechoice label {
          width: 145px; } }
    .contour .contourPage .contourField.multiplechoice div, .contour .contourPage .contourField.radiobutton div, .contour .contourPage .contourField.singlechoice div {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin-right: -4px; }
      @media screen and (min-width: 568px) {
        .contour .contourPage .contourField.multiplechoice div, .contour .contourPage .contourField.radiobutton div, .contour .contourPage .contourField.singlechoice div {
          width: calc(100% - 145px); } }
      .contour .contourPage .contourField.multiplechoice div span.checkboxlist, .contour .contourPage .contourField.multiplechoice div span.radiobuttonlist, .contour .contourPage .contourField.radiobutton div span.checkboxlist, .contour .contourPage .contourField.radiobutton div span.radiobuttonlist, .contour .contourPage .contourField.singlechoice div span.checkboxlist, .contour .contourPage .contourField.singlechoice div span.radiobuttonlist {
        padding: 0; }
        .contour .contourPage .contourField.multiplechoice div span.checkboxlist input, .contour .contourPage .contourField.multiplechoice div span.radiobuttonlist input, .contour .contourPage .contourField.radiobutton div span.checkboxlist input, .contour .contourPage .contourField.radiobutton div span.radiobuttonlist input, .contour .contourPage .contourField.singlechoice div span.checkboxlist input, .contour .contourPage .contourField.singlechoice div span.radiobuttonlist input {
          margin-bottom: 10px; }
    .contour .contourPage .contourField textarea {
      height: 150px; }
  .contour .contourPage .contourNavigation {
    position: relative;
    margin-top: 30px; }
    .contour .contourPage .contourNavigation input[type=submit], .contour .contourPage .contourNavigation button {
      display: inline-block;
      padding: 16px 50px 16px 20px;
      background: url("/img/arrow-formsubmit.png") no-repeat #630460;
      background-position: calc(100% - 20px) center;
      background-size: 18px 12px;
      color: #fff;
      border: 0;
      border-radius: 5px;
      font-family: "Avenir", sans-serif;
      font-weight: 700;
      font-size: 1.2em;
      cursor: pointer; }

.contour .contourMessageOnSubmit {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #5b5b5b;
  font-size: 18px;
  font-weight: 700;
  display: block;
  z-index: 0; }
  .contour .contourMessageOnSubmit:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -60px;
    height: 100%;
    width: 100%;
    width: calc(100% + 120px);
    background: #F5F5F5;
    z-index: -1; }

.checkboxButton {
  margin-top: 30px;
  margin-bottom: 15px;
  margin-left: 30px;
  display: inline-block; }
  @media screen and (max-width: 767px) {
    .checkboxButton {
      margin-left: 0px; } }
  .checkboxButton label {
    display: block;
    margin-bottom: 10px; }
    .checkboxButton label span {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 13px; }
      .checkboxButton label span a {
        color: #4a8dc0;
        font-weight: 700; }
    .checkboxButton label .icon_checkbox {
      position: relative;
      width: 20px;
      fill: #630460; }
    .checkboxButton label .icon_checkmark {
      width: 16px;
      stroke: #630460;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      stroke-dasharray: 130px 130px;
      stroke-dashoffset: 130px;
      -webkit-transition: stroke-dashoffset 4.1s linear 0s;
      transition: stroke-dashoffset 500ms ease-in-out; }
    .checkboxButton label input {
      display: none; }
      .checkboxButton label input:checked {
        background-color: red; }
        .checkboxButton label input:checked ~ .icon_checkbox .icon_checkmark {
          stroke-dashoffset: 0; }
      .checkboxButton label input:disabled ~ .icon_checkbox {
        fill: gray; }
        .checkboxButton label input:disabled ~ .icon_checkbox .icon_checkmark {
          stroke: gray; }
  .checkboxButton.invalid .icon_checkbox {
    fill: red; }

.favorites ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 550px; }
  .favorites ul li {
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    margin: 0 0 12px;
    padding: 13px 37px 13px 19px;
    position: relative;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms; }
    .favorites ul li a.deleteFavorite {
      padding: 5px;
      position: absolute;
      right: 12px;
      top: 12px; }
      .favorites ul li a.deleteFavorite svg {
        fill: #bfbfbf;
        width: 12px;
        vertical-align: top;
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms; }
      .favorites ul li a.deleteFavorite:hover svg {
        fill: #414141; }
    .favorites ul li.removed {
      height: 0;
      margin: 0;
      overflow: hidden;
      padding: 0;
      opacity: 0;
      filter: alpha(opacity=0); }

.groups ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 550px; }
  .groups ul li {
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    margin: 0 0 12px;
    padding: 13px 37px 13px 19px;
    position: relative;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms; }
    .groups ul li a.editLink {
      display: block; }
      .groups ul li a.editLink span {
        color: #4a8dc0;
        display: inline-block;
        margin-right: 7px;
        font-weight: 700;
        vertical-align: top; }
      .groups ul li a.editLink svg {
        display: inline-block;
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms;
        fill: #bfbfbf;
        margin-top: 4px;
        vertical-align: top;
        position: absolute;
        right: 6px;
        top: 12px;
        width: 12px; }
      .groups ul li a.editLink:hover svg {
        fill: #414141; }
    .groups ul li.removed {
      height: 0;
      margin: 0;
      overflow: hidden;
      padding: 0;
      opacity: 0;
      filter: alpha(opacity=0); }
    .groups ul li a.deleteLink {
      position: absolute;
      right: -40px;
      top: 7px; }
      .groups ul li a.deleteLink svg {
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms;
        width: 30px;
        fill: #bfbfbf; }
      .groups ul li a.deleteLink:hover svg {
        fill: #414141; }

section.partners {
  margin-bottom: 30px; }
  @media screen and (min-width: 568px) {
    section.partners {
      margin-bottom: 50px; } }
  section.partners .row .column {
    margin-bottom: 15px; }
    @media screen and (min-width: 568px) {
      section.partners .row .column {
        margin-bottom: 0;
        height: 80px;
        text-align: center; } }
    section.partners .row .column img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      max-height: 100%; }

.productDetail {
  margin-top: 50px;
  margin-bottom: 80px; }
  @media screen and (min-width: 1024px) {
    .productDetail {
      margin-top: 10px; } }
  .productDetail h1 {
    margin-bottom: 25px;
    font-size: 1.7em; }
    @media screen and (min-width: 1024px) {
      .productDetail h1 {
        font-size: 2.5em; } }
  .productDetail .productDetailImgContainer {
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (min-width: 768px) {
      .productDetail .productDetailImgContainer {
        margin-bottom: 0; } }
    @media screen and (min-width: 768px) {
      .productDetail .productDetailImgContainer img {
        width: 50%; } }
  .productDetail-actions {
    /*max-width: 182px;*/
    /*@extend .clear;

		> .inner {
			@extend .clear;
			margin-bottom: 25px;
		}

		.addToCart {
			float: left;
			width: 100%;

			@include min-screen($desktopBreakpoint) {
				width: 196px;
			}

			&.hidden {
				display: none;
			}

			.stock {
				float: left;
				margin-top: 5px;
			}

			.field-validation-error {
				color: #ff0000;
				display: block;
				float: left;
			}

			> .inner {
				@extend .clear;
				display: table;
				width: 100%;
				margin: 0 auto;

				.addToCart-seperator {
					float: left;
					width: calc(100% - 30px);

					@include min-screen($tabletBreakpoint) {
						width: calc(100% - 40px);
					}

					@include min-screen($desktopBreakpoint) {
						width: calc(100% - 50px);
					}
				}

				.button {
					@include transition(.5s background-color ease);
					background-color: #ecbf2f;

					&:hover {
						background-color: #bd9411;
					}

					&.addToCart-minus {
						margin-left: 0;
					}

					&.addToCart-plus {
						margin-right: 0;
					}
				}
			}

			.button-addtocart {
				margin-right: 0;
				margin-left: 0;
			}
		}

		button.button {
			@include transition(.5s background-color ease);
			float: right;
			line-height: 1.2;
			//padding: 6px 10px 7px 9px;
			border: 0;
			border-radius: 4px;
			background-color: #ecbf2f;
			font-weight: 900;
			color: #fff;
			cursor: pointer;*/
    /* @include min-screen($tabletBreakpoint) {
				padding: 8px 15px 9px 14px;
			}

			@include min-screen($desktopBreakpoint) {
				padding: 5px 20px 6px 19px;
			} */
    /* &:after {
				content: "+";
				margin-left: 5px;
			} */
    /*&:hover,
			&:focus,
			&:active {
				background-color: darken(#ecbf2f, 15%);
			}
		}*/ }
    .productDetail-actions input {
      /*height: 45px !important;*/ }
    .productDetail-actions .button {
      background-color: #ecbf2f; }

.productTabs {
  margin-bottom: 50px; }
  .productTabs .show {
    display: block; }
  .productTabs .hide {
    display: none; }
  .productTabs .productTabs-menu {
    margin-bottom: 50px; }
    .productTabs .productTabs-menu li {
      font-weight: 700; }
  .productTabs .productTabs-content p {
    font-size: 14px; }
  .productTabs .productTab-content-link {
    margin-bottom: 30px;
    text-decoration: none; }
    @media screen and (min-width: 768px) {
      .productTabs .productTab-content-link {
        margin-bottom: 0; } }
    .productTabs .productTab-content-link .image {
      display: block;
      overflow: hidden;
      border-radius: 4px; }
    .productTabs .productTab-content-link .title {
      display: block;
      width: 100%;
      padding-right: 40px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 700; }
    .productTabs .productTab-content-link .text {
      padding-right: 40px;
      display: block;
      font-size: 14px; }
    .productTabs .productTab-content-link .readMore {
      display: block;
      margin-top: 20px;
      margin-bottom: 10px; }

.orderStep {
  margin-bottom: 40px;
  border-bottom: 1px solid #dedede; }
  .orderStep-number {
    display: block;
    padding: 20px 0;
    margin-bottom: -1px;
    color: #dedede;
    line-height: 1.9;
    font-size: 18px;
    font-weight: 900;
    border-bottom: 2px solid transparent; }
    .orderStep-number span {
      display: block;
      float: left;
      width: 32px;
      height: 32px;
      line-height: 2;
      margin-right: 15px;
      background-color: #dedede;
      border-radius: 50%;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      text-align: center; }
    .orderStep-number.clickable {
      cursor: pointer;
      transition: ease .5s; }
      .orderStep-number.clickable span {
        transition: ease .5s; }
      .orderStep-number.clickable:hover {
        color: #5b5b5b; }
        .orderStep-number.clickable:hover span {
          background-color: #5b5b5b; }
    .orderStep-number.active {
      color: #72c192;
      border-bottom-color: #72c192; }
      .orderStep-number.active span {
        background-color: #72c192; }
      .orderStep-number.active:hover {
        color: #72c192;
        border-bottom-color: #72c192; }
        .orderStep-number.active:hover span {
          background-color: #72c192; }
    .orderStep-number.done {
      color: #5b5b5b;
      border-bottom-color: #5b5b5b; }
      .orderStep-number.done span {
        position: relative;
        background-color: #5b5b5b;
        text-indent: 200%;
        overflow: hidden; }
        .orderStep-number.done span:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 10px;
          height: 18px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          transform-origin: 16px -1px; }

section.orderConfirmation {
  margin-bottom: 60px; }

.pageSectionCategory-top,
.pageSectionCategory-items,
.pageSectionCategory-bottom {
  margin-bottom: 30px; }

.pageSection.selectDate .button {
  margin-top: 18px; }

.pageSectionCategory-top .pageSectionCategoryTop-dates span {
  display: block;
  margin-bottom: 5px; }

@media screen and (max-width: 567px) {
  .pageSectionCategory-top .pageSectionCategoryTop-dates {
    margin-bottom: 10px; } }

.pageSectionCategory-top .pageSectionCategoryTop-buttonSet {
  float: left; }
  @media screen and (min-width: 768px) {
    .pageSectionCategory-top .pageSectionCategoryTop-buttonSet {
      float: right; } }
  .pageSectionCategory-top .pageSectionCategoryTop-buttonSet span.dropDown {
    /*float: left;*/ }
    .pageSectionCategory-top .pageSectionCategoryTop-buttonSet span.dropDown select {
      padding: 16px 45px 16px 20px; }
    .pageSectionCategory-top .pageSectionCategoryTop-buttonSet span.dropDown svg {
      top: 25px; }

.pageSectionCategory-top .button {
  display: table;
  margin: 0 auto !important;
  font-size: 16px;
  -webkit-transition: background-color, 300ms;
  -moz-transition: background-color, 300ms;
  -ms-transition: background-color, 300ms;
  -o-transition: background-color, 300ms;
  transition: background-color, 300ms; }
  .pageSectionCategory-top .button:hover {
    background-color: #590456; }
  @media screen and (min-width: 768px) {
    .pageSectionCategory-top .button {
      display: block;
      float: right; } }
  .pageSectionCategory-top .button.orderConfirmation-update {
    background-color: #ecbf2f; }
  @media screen and (max-width: 567px) {
    .pageSectionCategory-top .button:first-child {
      margin-left: 0; } }
  @media screen and (max-width: 767px) {
    .pageSectionCategory-top .button {
      margin-bottom: 10px; } }

.pageSectionCategory-items {
  margin-bottom: 60px; }
  .pageSectionCategory-items .inner {
    margin-top: 10px; }
  @media screen and (max-width: 567px) {
    .pageSectionCategory-items .column {
      margin-bottom: 15px; } }
  .pageSectionCategory-items .button {
    background-color: #ecbf2f;
    display: table;
    font-size: 16px;
    -webkit-transition: background-color, 300ms;
    -moz-transition: background-color, 300ms;
    -ms-transition: background-color, 300ms;
    -o-transition: background-color, 300ms;
    transition: background-color, 300ms; }
    @media screen and (min-width: 568px) {
      .pageSectionCategory-items .button {
        display: block;
        float: left; } }
    .pageSectionCategory-items .button:hover {
      background-color: #bd9411; }
    .pageSectionCategory-items .button.addToCart-remove {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 30px;
      background-color: transparent;
      cursor: pointer; }
      @media screen and (min-width: 768px) {
        .pageSectionCategory-items .button.addToCart-remove {
          float: right; } }
      .pageSectionCategory-items .button.addToCart-remove:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        width: 26px;
        height: 26px;
        background-image: url("../img/icon_cart_bin.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transform: translateY(-50%);
        vertical-align: top; }
      .pageSectionCategory-items .button.addToCart-remove span {
        line-height: 1.7;
        font-size: 16px !important;
        font-weight: normal;
        color: #5b5b5b; }

.pageSectionCategory-bottom .dropDown {
  margin-bottom: 5px;
  /*float: left;*/ }
  .pageSectionCategory-bottom .dropDown select {
    padding-top: 15px;
    padding-bottom: 15px; }
  .pageSectionCategory-bottom .dropDown svg {
    top: 23px; }

.pageSectionCategory-bottom .locationChoice {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 30px; }
  .pageSectionCategory-bottom .locationChoice .locationItem {
    padding: 15px;
    border: 1px solid #ebebeb;
    margin-bottom: 15px; }
    .pageSectionCategory-bottom .locationChoice .locationItem.active .locationItem--title {
      margin-bottom: 10px; }
      .pageSectionCategory-bottom .locationChoice .locationItem.active .locationItem--title:after {
        content: ' ';
        position: absolute;
        left: 4px;
        top: 4px;
        height: 12px;
        width: 12px;
        background: #630460;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%; }
    .pageSectionCategory-bottom .locationChoice .locationItem.active .locationItemContents {
      display: block; }
    .pageSectionCategory-bottom .locationChoice .locationItem .locationItem--title {
      position: relative;
      padding-left: 30px;
      font-weight: 700; }
      .pageSectionCategory-bottom .locationChoice .locationItem .locationItem--title:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        height: 20px;
        width: 20px;
        border: 2px solid #630460;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%; }
    .pageSectionCategory-bottom .locationChoice .locationItem input[type="radio"] {
      display: none; }
    .pageSectionCategory-bottom .locationChoice .locationItem .locationItemContents {
      display: none; }
      .pageSectionCategory-bottom .locationChoice .locationItem .locationItemContents span {
        display: block;
        margin-left: 30px;
        color: #bfbfbf; }
    .pageSectionCategory-bottom .locationChoice .locationItem.groupAddress .locationItemContents .volunteerGroupDetail {
      display: none; }
      .pageSectionCategory-bottom .locationChoice .locationItem.groupAddress .locationItemContents .volunteerGroupDetail.show {
        display: block; }
    .pageSectionCategory-bottom .locationChoice .locationItem.otherAddress .locationItemContents {
      padding: 0 25px 0 25px;
      margin-top: 35px; }
      .pageSectionCategory-bottom .locationChoice .locationItem.otherAddress .locationItemContents .formRow .text {
        margin-top: 15px; }

.pageSectionCategory-bottom .bottomCartForm {
  display: flex;
  align-items: flex-end; }
  .pageSectionCategory-bottom .bottomCartForm .additionalMessage {
    width: 60%; }
    .pageSectionCategory-bottom .bottomCartForm .additionalMessage textarea {
      min-height: 200px; }
  .pageSectionCategory-bottom .bottomCartForm .ConfirmRow {
    width: 40%;
    margin-top: 30px; }
    .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .checkboxButton {
      margin-bottom: 0px; }
    .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow {
      /*float: left;*/ }
      @media screen and (max-width: 767px) {
        .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow {
          float: none; } }
      .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow .dropDown {
        width: 350px; }
        @media screen and (max-width: 767px) {
          .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow .dropDown {
            width: 100%; } }
      .pageSectionCategory-bottom .bottomCartForm .ConfirmRow .formRow.ConfirmRow {
        max-width: 400px; }
        .pageSectionCategory-bottom .bottomCartForm .additionalMessage > label.phone, .pageSectionCategory-bottom .bottomCartForm .additionalMessage > label.name{
          margin: 20px 0;
        } 

.pageSectionCategory-bottom .button {
  float: left;
  font-size: 16px;
  -webkit-transition: background-color, 300ms;
  -moz-transition: background-color, 300ms;
  -ms-transition: background-color, 300ms;
  -o-transition: background-color, 300ms;
  transition: background-color, 300ms; }
  .pageSectionCategory-bottom .button:hover {
    background-color: #590456; }
  @media screen and (max-width: 767px) {
    .pageSectionCategory-bottom .button {
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .pageSectionCategory-bottom .button {
      padding: 17px 20px 16px 19px;
      margin-left: 30px; } }
  .pageSectionCategory-bottom .button.orderConfirmation-button {
    margin-top: 0px; }
    @media screen and (max-width: 767px) {
      .pageSectionCategory-bottom .button.orderConfirmation-button {
        padding: 13px 10px 13px 9px; } }

.orderConfirmation-summary-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dedede; }
  a .orderConfirmation-summary-item:last-of-type {
    border-bottom: 0; }

@media screen and (min-width: 768px) {
  .orderConfirmation-summary .row {
    display: table;
    width: 100%; } }

.orderConfirmation-summary .row form.deleteCartItem {
  padding: 25px 0; }

@media screen and (min-width: 768px) {
  .orderConfirmation-summary .row .column {
    display: table-cell;
    vertical-align: middle; } }

.orderConfirmation-summary .row .column:first-of-type, .orderConfirmation-summary .row .column:last-of-type {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .orderConfirmation-summary .row .column:first-of-type, .orderConfirmation-summary .row .column:last-of-type {
      text-align: right; } }

@media screen and (min-width: 768px) {
  .orderConfirmation-summary .row .column:first-of-type {
    text-align: left; } }

.orderConfirmation-summary .row .column strong {
  display: inline-block;
  margin-bottom: 10px; }

.orderConfirmation-summary .addToCart {
  margin-bottom: 15px; }
  @media screen and (min-width: 768px) {
    .orderConfirmation-summary .addToCart {
      margin-bottom: 0; } }

section.selectDate {
  margin-bottom: 60px; }
  @media screen and (min-width: 1024px) {
    section.selectDate {
      margin-bottom: 250px; } }
  section.selectDate .selectDateLabel {
    position: relative;
    display: block;
    cursor: pointer; }
    section.selectDate .selectDateLabel:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 4px 0 4px;
      border-color: #9c9c9d transparent transparent transparent;
      line-height: 0px;
      _border-color: #9c9c9d #000000 #000000 #000000;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
      transform: translateY(-50%); }
    section.selectDate .selectDateLabel.active:after {
      border-width: 0 4px 8px 4px;
      border-color: transparent transparent #9c9c9d transparent;
      _border-color: #000000 #000000 #9c9c9d #000000; }
    section.selectDate .selectDateLabel .selectDateLabelIcon {
      position: absolute;
      top: 50%;
      left: 15px;
      pointer-events: none;
      width: 20px;
      transform: translateY(-50%);
      color: #b1b1b1; }
      section.selectDate .selectDateLabel .selectDateLabelIcon svg {
        fill: #b1b1b1; }
    section.selectDate .selectDateLabel .selectDateInput {
      padding-left: 50px; }
  section.selectDate .column {
    float: left; }

section.volunteerVacanciesOverview h2 {
  font-weight: 400;
  font-size: 1.35em;
  margin-bottom: 19px;
  margin-top: 19px; }
  @media screen and (min-width: 768px) {
    section.volunteerVacanciesOverview h2 {
      font-size: 1.7em;
      margin-bottom: 29px;
      margin-top: 29px; } }
  @media screen and (min-width: 1024px) {
    section.volunteerVacanciesOverview h2 {
      font-size: 2.5em;
      margin-bottom: 39px;
      margin-top: 39px; } }

section.volunteerVacanciesOverview .searchField div.field {
  width: calc(100% - 141px); }

section.volunteerVacanciesOverview .searchField .reset {
  display: none; }

section.volunteerVacanciesOverview .searchField span.dropDown {
  width: 123px; }

section.volunteerVacanciesOverview .searchField .column {
  position: relative; }

section.volunteerVacanciesOverview .searchField div.suggest {
  width: 85%; }

section.volunteerVacanciesOverview div.tabsNavigation, section.volunteerVacanciesOverview .productTabs div.productTabs-menu, .productTabs section.volunteerVacanciesOverview div.productTabs-menu {
  margin-bottom: 0px;
  display: block; }
  section.volunteerVacanciesOverview div.tabsNavigation ul, section.volunteerVacanciesOverview .productTabs div.productTabs-menu ul, .productTabs section.volunteerVacanciesOverview div.productTabs-menu ul {
    display: block;
    display: inline-block; }

div.resultsCount {
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  margin: 30px 0; }

div.vacanciesOverview ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none; }
  @media screen and (min-width: 768px) {
    div.vacanciesOverview ul {
      flex-direction: row; } }
  div.vacanciesOverview ul li {
    margin-bottom: 50px;
    margin-right: 3px;
    align-self: stretch; }
    @media screen and (min-width: 768px) {
      div.vacanciesOverview ul li {
        width: 49%; } }
    @media screen and (min-width: 1024px) {
      div.vacanciesOverview ul li {
        width: 33%; } }
    div.vacanciesOverview ul li.hide {
      display: none; }
    div.vacanciesOverview ul li.readMoreHidden {
      display: none; }
    div.vacanciesOverview ul li a.vacancy {
      /*margin-bottom: 46px;*/
      text-decoration: none;
      display: block;
      height: 100%;
      display: flex;
      flex-direction: column; }
      div.vacanciesOverview ul li a.vacancy span.image {
        display: block;
        margin-bottom: 27px;
        width: 340px; }
        div.vacanciesOverview ul li a.vacancy span.image img {
          border-radius: 4px;
          vertical-align: top; }
      div.vacanciesOverview ul li a.vacancy span.title {
        display: block;
        font-size: 1.125em;
        font-weight: 900;
        margin-bottom: 5px;
        padding: 0 5.55556%; }
        @media screen and (min-width: 1024px) {
          div.vacanciesOverview ul li a.vacancy span.title {
            padding: 0; } }
      div.vacanciesOverview ul li a.vacancy span.summary {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 2px;
        padding: 0 5.55556%; }
        @media screen and (min-width: 1024px) {
          div.vacanciesOverview ul li a.vacancy span.summary {
            padding: 0; } }
      div.vacanciesOverview ul li a.vacancy span.city {
        display: block;
        color: #bfbfbf;
        font-size: 0.875em;
        line-height: 1.75em;
        margin-bottom: 17px;
        padding: 0 5.55556%; }
        @media screen and (min-width: 1024px) {
          div.vacanciesOverview ul li a.vacancy span.city {
            padding: 0; } }
      div.vacanciesOverview ul li a.vacancy span.readMore {
        margin: 0 5.55556%;
        align-self: flex-start; }
        @media screen and (min-width: 1024px) {
          div.vacanciesOverview ul li a.vacancy span.readMore {
            margin: 0;
            margin-top: auto; } }
        div.vacanciesOverview ul li a.vacancy span.readMore span {
          color: #4a8dc0;
          display: inline-block;
          margin-right: 7px;
          font-weight: 700;
          vertical-align: top; }
        div.vacanciesOverview ul li a.vacancy span.readMore svg {
          display: inline-block;
          -webkit-transition: all 300ms;
          -moz-transition: all 300ms;
          -ms-transition: all 300ms;
          -o-transition: all 300ms;
          transition: all 300ms;
          fill: #4a8dc0;
          margin-top: 4px;
          vertical-align: top;
          width: 18px; }

div.vacanciesOverview .readMore {
  text-align: center;
  margin-bottom: 90px; }
  div.vacanciesOverview .readMore .readMoreButton {
    display: none; }
    div.vacanciesOverview .readMore .readMoreButton.visible {
      display: inline-block; }
    div.vacanciesOverview .readMore .readMoreButton button {
      border: 1px solid #4a8dc0;
      border-radius: 5px;
      padding: 15px 20px;
      background: #fff; }
      div.vacanciesOverview .readMore .readMoreButton button:hover {
        cursor: pointer;
        background: #f5f5f5; }

.pac-container {
  border: 1px solid #ebebeb;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }
  .pac-container .pac-item {
    background: #ffffff;
    border-top: 1px solid #ebebeb;
    padding: 26px 73px 24px 15px;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    font-weight: 900;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .pac-container .pac-item:hover {
      background: #630460;
      color: #ffffff; }
      .pac-container .pac-item:hover .pac-item-query {
        color: #ffffff; }
    .pac-container .pac-item .pac-icon {
      display: none; }
    .pac-container .pac-item .pac-item-query {
      font-family: "Avenir", sans-serif;
      font-size: 16px;
      font-weight: 900;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }

.ui-datepicker {
  width: 100%;
  border-color: #ebebeb !important;
  border-top: 0 !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -5px; }
  .ui-datepicker .ui-datepicker-calendar {
    width: calc(100% - 20px);
    margin: 0 10px 10px; }
    .ui-datepicker .ui-datepicker-calendar td,
    .ui-datepicker .ui-datepicker-calendar td a,
    .ui-datepicker .ui-datepicker-calendar td span {
      text-align: center; }
    .ui-datepicker .ui-datepicker-calendar td {
      background-color: #fff;
      border: 0; }
  .ui-datepicker .ui-datepicker-header {
    background-color: #fff;
    border: 0; }
  .ui-datepicker thead th {
    color: #9c9c9d; }
  .ui-datepicker .ui-datepicker-next.ui-state-hover,
  .ui-datepicker .ui-datepicker-prev.ui-state-hover {
    background-color: #fff;
    border: 0; }
  .ui-datepicker .ui-icon {
    background-image: none;
    text-indent: 0;
    overflow: visible;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px; }
    .ui-datepicker .ui-icon svg {
      fill: #72c192; }
    .ui-datepicker .ui-icon.ui-icon-circle-triangle-w svg {
      transform: rotate(180deg); }
  .ui-datepicker .ui-datepicker-title {
    font-family: 'Avenir', sans-serif;
    color: #9c9c9d; }
  .ui-datepicker .ui-state-default {
    background-color: #fff;
    border: 0;
    color: #408fd5; }
  .ui-datepicker .ui-datepicker-week-end .ui-state-default {
    color: #3b607b !important; }
  .ui-datepicker .ui-state-active,
  .ui-datepicker .ui-state-highlight {
    background-color: #fff;
    border: 0;
    color: #408fd5;
    font-weight: bold; }
  .ui-datepicker .ui-state-active {
    text-decoration: underline; }
  .ui-datepicker .ui-state-disabled:not(.ui-datepicker-other-month) {
    text-decoration: line-through; }
    .ui-datepicker .ui-state-disabled:not(.ui-datepicker-other-month) .ui-state-default {
      color: #9c9c9d; }

.cartList {
  margin-top: 50px;
  margin-bottom: 80px; }
  .cartList .cartListHeader {
    display: none;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebebeb; }
    @media screen and (min-width: 1024px) {
      .cartList .cartListHeader {
        display: block; } }
    .cartList .cartListHeaderInner {
      margin: 0 -10px; }
    .cartList .cartListHeaderProduct, .cartList .cartListHeaderQty {
      float: left;
      padding: 0 10px; }
    .cartList .cartListHeaderProduct {
      width: 80%; }
    .cartList .cartListHeaderQty {
      width: 20%; }
  .cartList .cartListItem {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebebeb; }
    .cartList .cartListItem .cartListItemInner {
      margin: 0 -10px; }
    .cartList .cartListItemImage, .cartList .cartListItemProduct, .cartList .cartListItemName, .cartList .cartListItemQty {
      float: left;
      padding: 0 10px;
      width: 100%; }
    .cartList .cartListItemProductInner {
      margin: 0 -10px; }
    .cartList .cartListItemImage {
      max-width: 40%; }
      @media screen and (min-width: 768px) {
        .cartList .cartListItemImage {
          max-width: 30%; } }
      @media screen and (min-width: 1024px) {
        .cartList .cartListItemImage {
          max-width: 25%; } }
    .cartList .cartListItemProduct {
      max-width: 60%; }
      @media screen and (min-width: 768px) {
        .cartList .cartListItemProduct {
          max-width: 70%; } }
      @media screen and (min-width: 1024px) {
        .cartList .cartListItemProduct {
          max-width: 75%; } }
    @media screen and (min-width: 1024px) {
      .cartList .cartListItemName {
        max-width: 75%;
        padding-top: 20px;
        padding-bottom: 20px; } }
    .cartList .cartListItemName h2 {
      font-size: 0.875em; }
      @media screen and (min-width: 768px) {
        .cartList .cartListItemName h2 {
          font-size: 1.2em; } }
      @media screen and (min-width: 1024px) {
        .cartList .cartListItemName h2 {
          font-size: 1.4em; } }
    @media screen and (min-width: 1024px) {
      .cartList .cartListItemQty {
        max-width: 25%;
        padding-top: 20px;
        padding-bottom: 20px; } }
  .cartList .buttons .button {
    background-color: #ecbf2f; }
  .cartList .buttons .right {
    float: right; }

.addToCart {
  /*@extend .clear;*/
  /*width: 100%;

	.addToCart-title {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}

	.hidden + .addToCart-plus {
		float: right;
		margin-left: 0 !important;
	}

	.field-validation-error {
		color: #ff0000;
		display: block;
	}

	.show,
	.addToCart-seperator {
		@extend .clear;
		float: left;
		width: calc(100% - 30px);

		@include min-screen($tabletBreakpoint) {
			width: calc(100% - 40px);
		}

		@include min-screen($desktopBreakpoint) {
			width: calc(100% - 50px);
		}

		& + .addToCart-plus {
			float: right;
		}
	}*/ }

div.history .historyItem {
  position: relative;
  cursor: pointer;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin: 0 0 12px;
  padding: 13px 37px 13px 19px;
  position: relative; }
  div.history .historyItem:after {
    background: transparent;
    width: 10px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid #5b5b5b;
    content: '';
    padding: 0;
    position: absolute;
    top: 25px;
    right: 25px; }
  div.history .historyItem.show:after {
    transform: rotate(180deg); }
  div.history .historyItem.show .historyItemContent {
    display: block; }
  div.history .historyItem .orderDate {
    display: inline-block;
    margin-bottom: 5px; }
  @media screen and (max-width: 767px) {
    div.history .historyItem .orderInstitution {
      display: block;
      margin-top: 15px; } }
  div.history .historyItem .historyItemContent {
    display: none;
    margin-top: 15px; }
    div.history .historyItem .historyItemContent ul {
      display: block;
      list-style: none; }
      div.history .historyItem .historyItemContent ul li {
        display: block;
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 5px; }
    div.history .historyItem .historyItemContent strong {
      display: block;
      width: 100%;
      max-width: 400px;
      float: left; }
    div.history .historyItem .historyItemContent span {
      display: block;
      float: left; }

.pageSection.hide {
  display: none; }

.pageSection.show {
  display: block; }

input[type="search"] {
  -webkit-appearance: textfield; }

.lity {
  z-index: 9999 !important; }

.home {
  /*@import '_print.scss';*/ }

section.title img {
  width: 100%; }
