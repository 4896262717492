section.textUSPs {
    margin: 70px 0;

    @media screen and (min-width:768px) {
        margin: 110px 0;
    }

    h2 {
        @media screen and (min-width: 768px) {
            font-size: 30px;
        }
    }

    p {
        font-size: 18px;
    }

    a.button {
        padding: 20px 35px;

        span {
            font-size: 18px;
        }
    }

    ul.checkmarks {
        list-style: none;

        li {
            font-weight: 900;
            display: flex;
            align-items: center;
            margin: 10px 0;

            &:before {
                flex: none;
                display: inline-block;
                width: 30px;
                height: 30px;
                content: '';
                background-image: url('/img/icon_check.svg');
                margin-right: 15px;
            }
        }
    }
}