/* Responsive Breakpoints */
$mobileBreakpoint: 568px;
$tabletBreakpoint: 768px;
$desktopBreakpoint: 1024px;

$homeSliderBreakpoint: 1260px;
$menuBreakpoint: 1100px;
$menuBreakpointRes: 1000px;
$breadcrumBreakpoint: 767px;
$footerBreakpoint: 700px;

/* Border-Radius */
$borderRadius: 4px;

/* Colors */
$colorText: #5b5b5b;
$colorLink: #4a8dc0;
$colorHeaderBackground: #d0e5ca;
$colorFooterBackground: #72c192;
$colorLogo: #004f34;
$colorMetaBackground: #f8f8f8;
$colorMetaLinks: #989898;
$colorMetaDivider: #e1e0e0;
$colorHeaderLine: #ebebeb;
$colorActionPurple: #630460;
$colorHamburger: #005034;
$colorStepBlock: #79b8b5;
$colorStepBlockIcon: #4b928e;
$colorInputText: #414141;
$colorInputPlaceholder: #bfbfbf;
$colorTabLine: #f2f2f2;
$colorDateBackground: #f5f5f5;
$colorInputBorder: #CBCBCB;
$colorFormGrey: #aaaaaa;
$colorFileBlue: #4a8dc0;
$colorErrorRed: #cc0000;
$colorSearchField: #cbcbcb;
$colorSearchFieldPlaceholder: #848484;

/* Zelf doen Rainbow Colors */
$rainbowOrange: #ecbf2f;
$rainbowYellow: #ecd42f;
$rainbowYellowGreen: #d4ec2f;
$rainbowLightGreen: #b5d926;
$rainbowGreen: #a4c172;
$rainbowDarkerGreen: #a7ce94;
$rainbowBlueGreen: #72c192;

/* Cookie colors */
$cookieBackground: #ffffff;
$cookieBorder: #000000;
$cookieTextColor: #000000;

/* Fonts */
$bodyFontSize: 16px;
$mainFont: 'Avenir', sans-serif;

/* Z-Indexes */
$overlayZIndex: 99999;